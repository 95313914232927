import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import { Form } from "react-bootstrap";
import { Col, Row, FormGroup } from "reactstrap";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import * as apiMethods from "../res/apiMethods.js";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { useLocation, useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useFormik } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import jwtDecode from "jwt-decode";
import history from "../services/history.service.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import eachDayOfInterval from "date-fns/eachDayOfInterval/index.js";
import CurrencyFormat from "react-currency-format";
import * as yup from "yup";
import Swal from "sweetalert2";

import Sidebar from "../components/Sidebar";

const Clients = () => {
  const [props, setProps] = React.useState({
    exists: false,
    clientCredits: [],
    user: "",
  });

  useEffect(() => {
    (async () => {
      if (getLastItem(window.location.href) !== "clients") {
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        let creditsResponse = await axios.get(
          apiMethods.GET_CREDITO_CLIENTE_ID + getLastItem(window.location.href)
        );
        let clientResponse = await axios.get(
          apiMethods.GET_CLIENTE_DOCUMENTO + getLastItem(window.location.href)
        );

        formik.resetForm({ values: clientResponse.data.client });
        setState({ clientCredits: creditsResponse.data.credits, exists: true });
        Swal.close();
      }
      setState({
        user: JSON.parse(
          atob(window.sessionStorage.getItem("localToken").split(".")[1])
        ),
      });
    })();
  }, []);

  const validationSchema = yup.object().shape({
    telefono: yup
      .string()
      .min(11, "El teléfono debe ser por lo menos de 7 digitos")
      .max(14, "El teléfono debe ser de máximo 10 digitos"),
    email: yup.string().email(),
    birthDate: yup.date(),
  });

  const formik = useFormik({
    initialValues: {
      tipoDocumento: "",
      documento: "",
      nombre: "",
      telefono: "",
      direccion: "",
      email: "",
      birthDate: null,
      notas: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const onError = (e) => {
    e.preventDefault();
    console.log(formik);
    for (let error in formik.errors) {
      toast.error(formik.errors[error]);
    }
  };

  const setState = (value) => {
    setProps((prevState) => ({ ...prevState, ...value }));
  };

  const getLastItem = (thePath) =>
    thePath.substring(thePath.lastIndexOf("/") + 1);

  const createCredit = () => {
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let search = window.sessionStorage.getItem("localDocument");

    axios
      .get(apiMethods.GET_CLIENTE_DOCUMENTO + getLastItem(window.location.href))
      .then((response) => {
        var auxClient = [];
        auxClient.push(response.data.client);
        window.sessionStorage.setItem(
          "clientStored",
          getLastItem(window.location.href)
        );
        history.push("/credits");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeClient = () => {
    let search = window.sessionStorage.getItem("localDocument");
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    var localId = decodedPayload.usrId;

    axios
      .get(
        apiMethods.GET_CLIENTE_DOCUMENTO + getLastItem(window.location.href),
        {
          headers: {
            Authorization: actUsrToken,
          },
        }
      )
      .then((response) => {
        var reachedCredits = response.data.client.creditos;
        if (reachedCredits.length == 0) {
          axios
            .post(
              apiMethods.DELETE_CLIENTE_DOCUMENTO +
                getLastItem(window.location.href),
              null
            )
            .then((response) => {
              axios
                .get(apiMethods.GET_USUARIO_ID + localId)
                .then((response) => {
                  var tempArrayUsr = response.data.users.clientes;
                  tempArrayUsr.splice(
                    tempArrayUsr.indexOf(getLastItem(window.location.href)),
                    1
                  );
                  axios
                    .patch(apiMethods.PATCH_USUARIO_ID + localId, {
                      clientes: tempArrayUsr,
                    })
                    .then((response) => {
                      axios
                        .get(
                          apiMethods.GET_USUARIO_USERNAME +
                            getLastItem(window.location.href)
                        )
                        .then((response) => {
                          var toDeleteUsr = response.data.users.usrId;
                          axios
                            .post(
                              apiMethods.DELETE_USUARIO_ID + toDeleteUsr,
                              null
                            )
                            .then((response) => {
                              history.push("/home");
                              window.location.reload();
                            });
                        });
                    });
                });
            });
        } else {
          toast.error("No se puede eliminar el cliente porque tiene créditos");
        }
      })
      .catch((error) => {
        toast.error("Error al eliminar");
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;
    if (props.exists) {
      let actUsrToken = window.sessionStorage.getItem("localToken");
      let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
      let localId = decodedPayload.usrId;
      axios
        .patch(
          apiMethods.PATCH_CLIENTE_DOCUMENTO +
            getLastItem(window.location.href),
          formik.values,
          {
            headers: {
              Authorization: actUsrToken,
            },
          }
        )
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          document.getElementById("warnalert").innerHTML =
            "Error al actualizar";
          $("#warnalert").show();
          $("#warnalert").fadeOut(5000);
        });
    } else {
      axios
        .get(apiMethods.GET_CLIENTE_DOCUMENTO + formik.values.documento)
        .then((response) => {
          if (
            response.data.client &&
            response.data.client.tipoDocumento === formik.values.tipoDocumento
          ) {
            toast.error("El documento ya se encuentra registrado");
          } else {
            axios
              .post(apiMethods.CREATE_CLIENTE, formik.values)
              .then((response) => {
                axios
                  .get(apiMethods.GET_USUARIO_ID + localId)
                  .then((response) => {
                    var localClients = response.data.users.clientes;
                    localClients.push(formik.values.documento);
                    axios
                      .patch(apiMethods.PATCH_USUARIO_ID + localId, {
                        clientes: localClients,
                      })
                      .then((response) => {
                        axios
                          .post(apiMethods.SIGNUP, {
                            username: formik.values.documento,
                            password: formik.values.documento,
                          })
                          .then((response) => {
                            toast.success(`Cliente registrado con éxito`);
                            setTimeout(() => {
                              history.push(
                                `/clients/${formik.values.documento}`
                              );
                              window.location.reload();
                            }, 1500);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    }
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "70%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const handleChange = (prop) => (event) => {
    setProps({ ...props, [prop]: event.target.value });
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="red"
          size="small"
          onClick={() => {
            window.sessionStorage.setItem("localDocument", params.row.id);
            //history.push('/detailCreditos');
            history.push(`/credits/${formik.values.documento}`);
            window.location.reload();
          }}
        >
          Abrir
        </Button>
      </strong>
    );
  };

  const creditsColumns = [
    { field: "id", headerName: "Documento", width: "250" },
    { field: "vlrCapital", headerName: "Capital", width: "250" },
    { field: "plazo", headerName: "Plazo", width: "250" },
    {
      field: "accion",
      headerName: "Acción",
      width: "150",
      renderCell: renderDetailsButton,
    },
  ];

  return window.sessionStorage.getItem("localToken") ? (
    <React.Fragment>
      <ToastContainer />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
          <Sidebar />
          <div>
            <div className="card" style={{ width: "100%" }}>
              <div
                style={{
                  fontSize: "15pt",
                  fontWeight: "500",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                {props.exists ? "Detalle Cliente" : "Registro Cliente"}
              </div>
              <form
                onSubmit={(e) => {
                  formik.isValid ? submitHandler(e) : onError(e);
                }}
              >
                <div
                  style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                >
                  <div className="create-credit-input-row">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Tipo de documento</InputLabel>
                      <Select
                        defaultValue=""
                        disabled={props.user?.roleId == 1 ? false : true}
                        required
                        id="tipoDocumento"
                        name="tipoDocumento"
                        style={{ textAlign: "left" }}
                        value={formik.values.tipoDocumento}
                        onChange={formik.handleChange}
                      >
                        <MenuItem value="C.C.">Cédula de Ciudadanía</MenuItem>
                        <MenuItem value="C.E.">Cédula de Extranjería</MenuItem>
                        <MenuItem value="N.I.T.">N.I.T.</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      required
                      className="create-credit-input"
                      id="documento"
                      disabled={props.user?.roleId == 1 ? false : true}
                      label="Número de documento"
                      value={formik.values.documento}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      required
                      className="create-credit-input"
                      label="Nombre"
                      disabled={props.user?.roleId == 1 ? false : true}
                      id="nombre"
                      value={formik.values.nombre}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <CurrencyFormat
                      customInput={TextField}
                      label="Teléfono / Celular"
                      className="create-credit-input"
                      required
                      id="telefono"
                      disabled={props.user?.roleId == 1 ? false : true}
                      format="(###) ###-####"
                      value={formik.values.telefono}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      required
                      className="create-credit-input"
                      label="Dirección"
                      id="direccion"
                      disabled={props.user?.roleId == 1 ? false : true}
                      value={formik.values.direccion}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      required
                      type="email"
                      className="create-credit-input"
                      label="Email"
                      id="email"
                      disabled={props.user?.roleId == 1 ? false : true}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      required
                      disabled={props.user?.roleId == 1 ? false : true}
                      id="birthDate"
                      value={formik.values.birthDate}
                      margin="normal"
                      onChange={(value) =>
                        formik.setFieldValue("birthDate", value)
                      }
                      label="Fecha de nacimiento"
                      style={{ width: "70%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <TextField
                    style={{ width: "85%" }}
                    multiline
                    rows={2}
                    disabled={props.user?.roleId == 1 ? false : true}
                    rowsMax={4}
                    id="notas"
                    className="create-credit-input"
                    label="Notas"
                    value={formik.values.notas}
                    onChange={formik.handleChange}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    marginTop: "2.2rem",
                    marginBottom: "1.3rem",
                  }}
                >
                  {props.user?.roleId == 1 && (
                    <>
                      <Button
                        onClick={submitHandler}
                        variant="contained"
                        color="red"
                        type="submit"
                      >
                        Guardar
                      </Button>
                      {props.exists && (
                        <>
                          <Button
                            variant="contained"
                            color="red"
                            style={{ marginLeft: "1rem" }}
                            onClick={createCredit}
                          >
                            Crear Crédito
                          </Button>
                          <Button
                            variant="contained"
                            color="red"
                            style={{ marginLeft: "1rem" }}
                            onClick={removeClient}
                          >
                            Eliminar
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </form>
            </div>
          
          {props.exists && (
            <div className="card" style={{ padding: "2rem" }}>
              <div style={{ height: 300, width: "100%" }}>
                <DataGrid
                  rows={props.clientCredits}
                  columns={creditsColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10]}
                  disableSelectionOnClick
                />
              </div>
            </div>
          )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  ) : (
    <div>Acceso denegado</div>
  );
};

export default Clients;
