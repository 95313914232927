import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import { Form } from "react-bootstrap";
import { Col, Row, FormGroup } from "reactstrap";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import * as apiMethods from "../res/apiMethods.js";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useFormik } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import jwtDecode from "jwt-decode";
import history from "../services/history.service.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import eachDayOfInterval from "date-fns/eachDayOfInterval/index.js";
import CurrencyFormat from "react-currency-format";
import * as yup from "yup";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Sidebar from "../components/Sidebar";

const Users = () => {
  const [props, setProps] = React.useState({
    usersData: [],
    createDialog: false,
    update: false,
    userId: "",
  });

  const validationSchema = yup.object().shape({
    username: yup.string().required("El nombre de usuario es requerido"),
    contraseña: yup.string().required("La contraseña es requerida"),
    roleId: yup.string().required("El rol es requerido"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
    initialValues: {
      username: "",
      password: "",
      roleId: "",
    },
  });

  const setState = (value) => {
    setProps((prevState) => ({ ...prevState, ...value }));
  };

  const loadUsers = async () => {
    let usersResponse = await axios.get(apiMethods.GET_USUARIO_ALL);
    usersResponse = usersResponse.data.clients.map((user) => {
      user.roleId = roles[user.roleId - 1];
      return user;
    });
    setState({ usersData: usersResponse });
  };

  useEffect(() => {
    (async () => {
      document.title = "Usuarios";
      try {
        await loadUsers();
      } catch (e) {
        console.error(e);
        toast.error("Error al obtener los usuarios");
      }
    })();
  }, []);

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <IconButton
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            console.log(params);
            setState({ update: true, userId: params.row.usrId });
            openCreateDialog({
              username: params.row.username,
              roleId: String(
                parseFloat(
                  roles.findIndex((element) => element == params.row.roleId) + 1
                )
              ),
            });
          }}>
          <VisibilityIcon />
        </IconButton>
        <IconButton
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro?",
              text: "¿Deseas eliminar este usuario?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si",
              cancelButtonText: "No",
              allowOutsideClick: false,
            }).then(async (result) => {
              console.log(result)
              if (result.isConfirmed) {
                let deleteResponse = await axios.delete(apiMethods.DELETE_USUARIO_ID + params.row.usrId);
                if (deleteResponse.data.code == 0) {
                  toast.success("Usuario eliminado correctamente");
                  loadUsers();
                }
              }
            });
          }}>
          <DeleteIcon />
        </IconButton>
      </strong>
    );
  };

  const roles = ["Administrador", "Básico", "Girador", "Cliente"];
  const creditsColumns = [
    { field: "username", headerName: "Usuario", width: "300" },
    { field: "roleId", headerName: "Rol", width: "300" },
    {
      field: "action",
      headerName: "Acción",
      width: "200",
      renderCell: renderDetailsButton,
    },
  ];

  const createUser = async (e) => {
    e.preventDefault();
    let valid = true;
    for (let val in formik.values) {
      if (formik.values[val] === "") {
        valid = false;
        break;
      }
    }
    if (valid) {
      let usrResponse = await axios.get(
        apiMethods.GET_USUARIO_USERNAME +
        document.getElementById("username").value
      );
      if (usrResponse.data.code == 500) {
        let signUpResponse = await axios.post(apiMethods.SIGNUP, formik.values);
        if (signUpResponse.data.code == 0) {
          toast.success("Usuario creado con éxito");
          closeCreateDialog();
          loadUsers();
        } else {
          toast.error("Error al crear el usuario");
        }
      } else {
        toast.error("Usuario con nombre ingresado ya existe");
      }
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    let valid = true;
    let userData = formik.values;
    delete userData.password;
    for (let val in userData) {
      if (userData[val] === "") {
        valid = false;
        break;
      }
    }
    if (valid) {
      let usernameResponse = await axios.get(
        apiMethods.GET_USUARIO_USERNAME + userData.username
      );
      if (usernameResponse.data.code == 0 && usernameResponse.data.users.usrId == props.userId) {
        let patchResponse = await axios.patch(
          apiMethods.PATCH_USUARIO_ID + props.userId,
          userData
        );
        if (patchResponse.data.code == 0) {
          toast.success("Usuario actualizado con éxito");
          closeCreateDialog();
          loadUsers();
        } else {
          toast.error("Error al actualizar el usuario");
        }
      } else {
        toast.error("Usuario con nombre ingresado ya existe");
      }
    } else {
      toast.error("Por favor complete todos los campos");
    }
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "70%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const onError = (e) => {
    e.preventDefault();
    for (let error in formik.errors) {
      toast.error(formik.errors[error]);
    }
  };

  const classes = useStyles();

  const openCreateDialog = (formData) => {
    if (formData) {
      for (let val in formData) {
        formik.setFieldValue(val, formData[val]);
      }
    }
    setState({ createDialog: true });
  };

  const closeCreateDialog = () => {
    formik.resetForm();
    setState({ createDialog: false, update: false });
  };

  return window.sessionStorage.getItem("localToken") ? (
    <React.Fragment>
      <ToastContainer />
      <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
              <Sidebar />
      <div className="card" style={{ padding: "2rem" }}>
        <div
          style={{
            fontSize: "15pt",
            fontWeight: "500",
            marginBottom: "2rem",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}>
          Usuarios
        </div>
        <div style={{ height: 630, width: "100%", marginBottom: "1rem" }}>
          {props.usersData.length > 0 && (
            <DataGrid
              getRowId={(row) => row._id}
              rows={props.usersData}
              columns={creditsColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
            />
          )}
        </div>
        <Button
          onClick={openCreateDialog}
          variant="contained"
          color="red"
          style={{ width: "40%",marginLeft:"30%" }}
          type="submit">
          Crear Usuario
        </Button>
      </div>
      </div>
      <Dialog
        open={props.createDialog}
        onClose={closeCreateDialog}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {props.update ? "Actualizar usuario" : "Crear usuario"}
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              formik.isValid ? createUser(e) : onError(e);
            }}>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div className="create-credit-input-row">
                <TextField
                  required
                  className="create-credit-input"
                  label="Nombre de usuario"
                  id="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </div>{" "}
              {!props.update && (
                <div className="create-credit-input-row">
                  <TextField
                    required
                    className="create-credit-input"
                    label="Contraseña"
                    id="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
              <div className="create-credit-input-row">
                <FormControl className={classes.formControl}>
                  <InputLabel>Rol</InputLabel>
                  <Select
                    required
                    name="roleId"
                    id="roleId"
                    style={{ textAlign: "left" }}
                    value={formik.values.roleId}
                    onChange={formik.handleChange}>
                    <MenuItem value="1">Administrador</MenuItem>
                    <MenuItem value="2">Básico</MenuItem>
                    <MenuItem value="3">Girador</MenuItem>
                    <MenuItem value="4">Cliente</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.update ? updateUser : createUser}>
          {props.update ? "Actualizar" : "Crear"}
          </Button>
          <Button onClick={closeCreateDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) : (
    <div>Acceso denegado</div>
  );
};

export default Users;
