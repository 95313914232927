import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import { Container, Row, Col, Navbar, Nav, NavDropdown } from "react-bootstrap";
import history from "./services/history.service.js";
import clients from "./pages/clients";
import Login from "./pages/login";
import Users from "./pages/users";
import Home from "./pages/home";
import "bootstrap/dist/css/bootstrap.min.css";
import detailCreditos from "./pages/detailCreditos";
import uvr from "./pages/uvr"; 
import documents from "./pages/documents";
import Search from "./pages/search";
import Credits from "./pages/credits";
import ClosedPeriods from "./pages/closedPeriods";

import Sidebar from '../src/components/Sidebar';
import Divider from '@mui/material/Divider';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      roleId: "",
    };
  }

  componentDidMount = () => {
    let loggedIn = window.sessionStorage.getItem("loggedIn");
    if (loggedIn == "true") {
      this.setState({
        roleId: JSON.parse(
          atob(window.sessionStorage.getItem("localToken").split(".")[1])
        ).roleId,
      });
      console.log(document.getElementById("content-scrollbar"));
      document
        .getElementById("content-scrollbar")
        .addEventListener("scroll", () => {
          const contentDiv = document.getElementById("content-scrollbar");
          if (contentDiv.scrollTop > 0) {
            document.getElementById("nav-bar").classList.add("scrolled");
          } else {
            document.getElementById("nav-bar").classList.remove("scrolled");
          }
        });
    } else {
      if (false) {
        history.push("/login");
        window.location.reload();
      }
    }
  };

  logOut = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    //setState({ loggedIn: false })
    history.push('/login');
    window.location.reload();
  }

  //<div><hr style={{borderColor : '#cb1016', height: .5}}/></div>

  render = () => {
    let username = window.sessionStorage.getItem("username");
    return (
      <Router>
        <div className="body">
        
          {window.sessionStorage.getItem("localToken") && (
      
            <div className="nav-bar" id="nav-bar" style={{borderColor:'#cb1016'}}>
              <Navbar style={{height:"6rem",borderColor : '#cb1016'}} bg="white" expand="lg">
                <div className="title">
                  <Navbar.Brand href={this.state.roleId == 1 ? '/home' : '/clients/search/' + username}>
                    <img
                      src={require("./res/logo-mobilia.jpg")}
                      height="90"

                      style={{marginLeft:"2.5rem"}}
                      
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                  </Navbar.Brand>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                  {window.sessionStorage.getItem("localToken") ? (
                    <Navbar.Text>
                      <Nav.Link href="/login" >
                          <ListItem className="navbar-element" button key={""}>
                              <ListItemIcon>
                                <a style={{ color:"#cb1016" ,fontSize:"13pt"}} > Perfil  <AccountBoxIcon color="#cb1016"/> </a>
                              </ListItemIcon>
                          </ListItem>
                      </Nav.Link>
                      <Nav.Link onClick={this.logOut} >
                          <ListItem className="navbar-element-2" button key={""}>
                              <ListItemIcon>
                                <a style={{ color:"#cb1016" ,fontSize:"13pt"}} > Cerrar Sesión</a>
                              </ListItemIcon>
                          </ListItem>
                      </Nav.Link>
                    </Navbar.Text>
                  ) : (
                    <Navbar.Text>
                      <a href="/login">Iniciar sesión</a>
                    </Navbar.Text>
                  )}
                </Navbar.Collapse>
          
              </Navbar>
              
            </div>
            
                  
          )}
          <Divider style={{color:"#cb1016", textDecorationThickness:"5rem"}}/>
          <div className="content" id="content-scrollbar">
            <Route path="/home" exact component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/clients" component={clients} />
            <Route path="/credits" component={Credits} />
            <Route path="/users" component={Users} />
            <Route path="/detailCreditos" component={detailCreditos} />
            <Route path="/search" component={Search} />
            <Route path="/uvr" component={uvr} />
            <Route path="/documents" component={documents} />
            <Route path="/closedPeriods" component={ClosedPeriods} />
          </div>
          
          

        </div> 
      </Router>
    );
  };
}

export default App;
