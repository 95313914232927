import React, { Component } from "react";
import axios from "axios";
import {} from "react-router-dom";
import {} from "react-bootstrap";
import { Form, Button, FormLabel, Col } from "react-bootstrap";
import * as apiMethods from "../res/apiMethods.js";

import Sidebar from "../components/Sidebar";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      creditos: [],
      transacciones: [],
      clientesData: [],
      creditosData: [],
      transaccionesData: [],
    };
  }

  componentDidMount() {
    document.title = "Inicio";
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;

    console.log(decodedPayload);
    axios
      .get(apiMethods.GET_USUARIO_ID + localId, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        console.log(response);
        var usr = response.data.users.username;

        this.setState({
          clientes: response.data.users.clientes,
          transaccionesData: response.data.users.transacciones,
          creditos: response.data.users.creditos,
        });

        var auxCreditos = [];
        var auxClientes = [];

        const months = [
          "ENE",
          "FEB",
          "MAR",
          "ABR",
          "MAY",
          "JUN",
          "JUL",
          "AGO",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];

        var transaccionesData = this.state.transaccionesData;
        for (let i = 0; i < transaccionesData.length; i++) {
          var local = transaccionesData[i];
          const formatter = new Intl.NumberFormat("en-US", {
            currency: "USD",
          });
          let current_datetime = new Date(local.fechaTx);
          let formatted_date =
            current_datetime.getDate() +
            "-" +
            months[current_datetime.getMonth()] +
            "-" +
            current_datetime.getFullYear();
          local.fechaTx = formatted_date;
          local.vlrTx = formatter.format(local.vlrTx);
          local.vlrAplicadoCapital = formatter.format(local.vlrAplicadoCapital);
          local.vlrAplicadoIntCte = formatter.format(local.vlrAplicadoIntCte);
          local.vlrAplicadoIntMora = formatter.format(local.vlrAplicadoIntMora);
          local.vlrAplicadoSegVida = formatter.format(local.vlrAplicadoSegVida);
          local.vlrAplicadoSegTodoRiesgo = formatter.format(
            local.vlrAplicadoSegTodoRiesgo
          );
          transaccionesData[i] = local;
        }

        for (let i = 0; i < this.state.clientes.length; i++) {
          axios
            .get(apiMethods.GET_CLIENTE_DOCUMENTO + this.state.clientes[i], {
              headers: {
                Authorization: actUsrToken,
              },
            })
            .then((response) => {
              var auxArray = this.state.clientesData;
              auxArray.push(response.data.client);
              this.setState({ clientesData: auxArray });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        for (let i = 0; i < this.state.creditos.length; i++) {
          axios
            .get(apiMethods.GET_CREDITO_ID + this.state.creditos[i], {
              headers: {
                Authorization: actUsrToken,
              },
            })
            .then((response) => {
              var auxArray = this.state.creditosData;
              var auxMonto = response.data.credits.vlrCapital;
              var tempArray = response.data.credits;
              const formatter = new Intl.NumberFormat("en-US", {
                currency: "USD",
              });
              auxMonto = formatter.format(auxMonto);
              tempArray.vlrCapital = auxMonto;
              auxArray.push(tempArray);
              this.setState({ creditosData: auxArray });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.setState({
          clientesData: auxClientes,
          creditosData: auxCreditos,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidUpdate = () => {};

  login = () => {
    this.props.history.push("/login");
  };

  renderTableDataClientes() {
    return this.state.clientesData.map((clientesData, index) => {
      const { tipoDocumento, nombre, documento } = clientesData;
      return (
        <tr key={documento}>
          <td>{tipoDocumento}</td>
          <td>{nombre}</td>
          <td>{documento}</td>
        </tr>
      );
    });
  }

  renderTableHeaderClientes() {
    let header = ["Tipo Documento", "Nombre", "Documento"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableHeaderCreditos() {
    let header = ["Saldo", "Plazo"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  //new Date(element.fechaTx).getMonth() < (new Date().getMonth()-2)
  renderTableDataCreditos() {
    return this.state.creditosData
      .filter((element) => {})
      .map((creditosData, index) => {
        const { id, vlrCapital, plazo } = creditosData;
        return (
          <tr key={id}>
            <td>{vlrCapital}</td>
            <td>{plazo}</td>
          </tr>
        );
      });
  }

  renderTableDataPagos() {
    return this.state.transaccionesData.map((transaccionesData, index) => {
      const { id, credito, vlrTx, fechaTx } = transaccionesData;
      return (
        <tr key={id}>
          <td>{credito}</td>
          <td>{vlrTx}</td>
          <td>{fechaTx}</td>
        </tr>
      );
    });
  }

  renderTableHeaderPagos() {
    let header = ["Credito", "Monto", "Fecha Pago"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  render = () => {
    let actUsrToken = window.sessionStorage.getItem("localToken");
    if (actUsrToken != undefined) {
      let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
      if (decodedPayload.roleId == 1 || decodedPayload.roleId == 2 || decodedPayload.roleId == 5) {
        return (
          <React.Fragment>
            <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
              <Sidebar />
              <div className="cardDashboard" style={{width: "100%", marginLeft:"16%"}}>
                <Form>
                  <Form.Row>
                    <Col>
                      <Form.Label className="headDashboard">
                        Créditos
                      </Form.Label>{" "}
                      <br></br>
                      <div>
                        <table id="table">
                          <tbody>
                            <tr>{this.renderTableHeaderCreditos()}</tr>
                            {this.renderTableDataCreditos()}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col>
                      <Form.Label className="headDashboard">Pagos</Form.Label>{" "}
                      <br></br>
                      <div>
                        <table id="table">
                          <tbody>
                            <tr>{this.renderTableHeaderPagos()}</tr>
                            {this.renderTableDataPagos()}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col></Col>
                  </Form.Row>
                </Form>
              </div>
            </div>
          </React.Fragment>
        );
      } else if (decodedPayload.roleId == 3 || decodedPayload.roleId == 4) {
        return (
          <React.Fragment>
            <div className="cardDashboard">
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Label className="headDashboard">Clientes</Form.Label>{" "}
                    <br></br>
                    <div>
                      <table id="table">
                        <tbody>
                          <tr>{this.renderTableHeaderClientes()}</tr>
                          {this.renderTableDataClientes()}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col>
                    <Form.Label className="headDashboard">Créditos</Form.Label>{" "}
                    <br></br>
                    <div>
                      <table id="table">
                        <tbody>
                          <tr>{this.renderTableHeaderCreditos()}</tr>
                          {this.renderTableDataCreditos()}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col>
                    <Form.Label className="headDashboard">Pagos</Form.Label>{" "}
                    <br></br>
                    <div>
                      <table id="table">
                        <tbody>
                          <tr>{this.renderTableHeaderPagos()}</tr>
                          {this.renderTableDataPagos()}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Form.Row>
              </Form>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <p className="cover">Bienvenido</p>
            <p id="userLogged"></p>
          </React.Fragment>
        );
      }
    }
  };
}

export default Home;
