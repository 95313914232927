import React, { Component } from "react";
import axios from "axios";
import logo from "../res/logoBase64.js";
import footer from "../res/footerBase64.js";

import { Form, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";

import { Col, Row, FormGroup } from "reactstrap";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as apiMethods from "../res/apiMethods.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { saveAs } from "file-saver";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class detailCreditos extends Component {
  toFixed(num, fixed) {
    if (num != null) {
      var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    } else {
      return 0;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      deudores: [],
      vlrCapital: "",
      plazo: "",
      estado: "",
      moneda: "",
      fechaAprobacion: new Date(),
      fechaDesembolso: new Date(),
      fechaDesembolsoReal: new Date(),
      transacciones: [],
      vlrInicial: "",
      transaccionesData: [],
      clientesData: [],
      id: "",
      creditos: [],
      fechaPago: new Date(),
      originalId: "",
      montoPago: "",
      cntTransacciones: "",
      pagosUsuario: [],
      garantia: "",
      amortizacion: "",
      auxProxCorte: null,
      auxProxCorteSave: null,
      interes: "",
      interesMoratorio: "",
      seguroVida: "",
      seguroTodoRiesgo: "",
      fechaPagoReciente: null,
      showPaymentsAplicationDialog: false,
      creditos: [],
      fechaPagoProximo: null,
      vlrSegVida: "",
      vlrSegTodoRiesgo: "",
      vlrInteres: "",
      vlrInteresMora: "",
      saldoSegVida: "",
      saldoSegTodoRiesgo: "",
      liquidacionesData: [],
      liquidacionesResp: [],
      proyeccionData: [],
      proyeccion: [],
      estadoReal: "",
      pagosPorCreditos: [],
      detalleData: [],
      labelsDetails: [
        "Saldo Inicial",
        "Movimientos",
        "Aplicacion del pago",
        "Nuevo saldo",
      ],
      resPeriodo: [],
      iteradorPagos: [],
      pasoEdicionLocal: "",
      liquidacionesDataUVR: [],
      detalleDataUVR: [],
      auxTransacciones: [],
      showModal: false,
      showModalDownload: false,
      showModalLiq: false,
      fechaRef: "",
      proyState: 0,
      auxFechaPagoReciente: "",
      auxFechaPago: "",
      lastUVR: "",
      emptyPayment: false,
      initialPDF: false,
      files: [],
      financialCode: "",
      showModalDeleteCredito: false,
      showModalDeletePayment: false,
      expiryDate: new Date(),
      mortgageDate: new Date(),
      landRegistry: "",
      deedNumber: "",
      notary: "",
      exists: false,
      user: "",
    };
  }

  setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  definirSegurosModal = (e) => {
    let actUsrToken = window.sessionStorage.getItem("localToken");

    var auxFinFecha = new Date(
      this.state.proyeccion[this.state.proyeccionData.length - 1].fechaFin
    );
    var auxIniFecha = new Date(
      this.state.proyeccion[this.state.proyeccionData.length - 1].fechaIni
    );
    var auxFinFechaProy = new Date(
      this.state.proyeccion[this.state.proyeccionData.length - 1].fechaFin
    );
    var auxIniFechaProy = new Date(
      this.state.proyeccion[this.state.proyeccionData.length - 1].fechaIni
    );
    auxFinFechaProy.setMonth(auxFinFechaProy.getMonth() + 1);
    auxIniFechaProy.setMonth(auxIniFechaProy.getMonth() + 1);
    var auxCuota =
      this.state.proyeccion[this.state.proyeccion.length - 1].noCuota;
    auxCuota = auxCuota + 1;
    var auxPago = new Date(this.state.fechaPago);
    var newProys = [];
    auxPago.setHours(0, 0, 0, 0);
    var mesesAplazados = 0;

    while (true) {
      if (auxPago > auxIniFecha && auxPago <= auxFinFecha) {
        break;
      }
      mesesAplazados++;
      auxIniFecha.setMonth(auxIniFecha.getMonth() + 1);
      auxFinFecha.setMonth(auxFinFecha.getMonth() + 1);
    }

    for (let i = 0; i < mesesAplazados; i++) {
      var auxIniL = new Date(auxIniFechaProy);
      var auxFinL = new Date(auxFinFechaProy);
      var auxProy = {
        liquidado: false,
        presentaPagos: false,
        fechaIni: auxIniL,
        fechaFin: auxFinL,
        noCuota: auxCuota,
        capital: 0,
        interes: 0,
        vlrCuota: 0,
        segVida: 0,
        segTodoRiesgo: 0,
      };
      newProys.push(auxProy);
      auxFinFechaProy.setMonth(auxFinFechaProy.getMonth() + 1);
      auxIniFechaProy.setMonth(auxIniFechaProy.getMonth() + 1);
      auxCuota++;
    }

    var auxProyeccion = this.state.proyeccion;

    for (let i = 0; i < newProys.length; i++) {
      auxProyeccion.push(newProys[i]);
    }

    axios
      .patch(
        apiMethods.PATCH_CREDITO_ID + this.state.id,
        { proyeccion: auxProyeccion },
        {
          headers: {
            Authorization: actUsrToken,
          },
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  enviarPagoModal = (e) => {
    this.submitHandlerPagos(e);
  };

  handleClose = (e) => {
    this.setState({ showModal: false });
  };

  handleCloseDownload = (e) => {
    this.setState({ showModalDownload: false });
  };

  handleShowDownload = (e) => {
    e.preventDefault();
    this.setState({ showModalDownload: true });
  };

  handleCloseDeleteCredit = (e) => {
    this.setState({ showModalDeleteCredito: false });
  };

  handleShowDeleteCredit = (e) => {
    e.preventDefault();
    this.setState({ showModalDeleteCredito: true });
  };

  handleCloseDeletePayment = (e) => {
    this.setState({ showModalDeletePayment: false });
  };

  handleShowDeletePayment = (e) => {
    e.preventDefault();
    this.setState({ showModalDeletePayment: true });
  };

  handleShowLiq = (e) => {
    e.preventDefault();
    this.setState({ showModalLiq: true });
  };

  handleCloseLiq = (e) => {
    this.setState({ showModalLiq: false });
  };

  handleShow = (e) => {
    e.preventDefault();
    let actUsrToken = window.sessionStorage.getItem("localToken");
    if (
      this.state.liquidaciones.length == 1 &&
      this.state.estado == "aprobado" &&
      (this.state.liquidaciones[0].interesCorriente != 0 ||
        this.state.liquidaciones[0].interesMoratorio != 0 ||
        this.state.liquidaciones[0].segVida != 0 ||
        this.state.liquidaciones[0].segTodoRiesgo != 0)
    ) {
      var auxInit = new Date(this.state.fechaInicial);
    } else {
      var auxInit = new Date(this.state.fechaDesembolso);
    }

    var year = auxInit.getFullYear();
    var month = auxInit.getMonth() + 1;
    var dt = auxInit.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var parsedDate = dt + "-" + month + "-" + year;

    axios
      .get(apiMethods.GET_UVR_FECHA + parsedDate, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        if (this.state.moneda == "uvr") {
          var vlrUVRInitAux = response.data.uvr[0].valor;
          var vlrUVRInit = vlrUVRInitAux;
        }

        let search = window.sessionStorage.getItem("localDocument");

        if (
          this.state.liquidaciones.length == 1 &&
          this.state.estado == "aprobado" &&
          (this.state.liquidaciones[0].interesCorriente != 0 ||
            this.state.liquidaciones[0].interesMoratorio != 0 ||
            this.state.liquidaciones[0].segVida != 0 ||
            this.state.liquidaciones[0].segTodoRiesgo != 0)
        ) {
          var interesAnual = this.state.interes;
          var interesMensualP = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
          var interesMensual = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
          interesMensual = interesMensual * 100;
          var pv = this.state.vlrInicial.replace(/,/g, "");
          var np = this.state.plazo;
          var fv = 0;
          var type = 0;
          var ir = interesMensualP;

          var pmt, pvif;
          fv || (fv = 0);
          type || (type = 0);

          if (ir === 0) return -(pv + fv) / np;

          pvif = Math.pow(1 + ir, np);

          pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

          var proyeccion = [];
          var cap = pv;
          var auxInit = new Date(this.state.fechaInicial);

          for (let i = 0; i < np; i++) {
            var noCuota = i;
            var fechaIni = new Date(auxInit);
            auxInit.setMonth(auxInit.getMonth() + 1);
            var fechaFin = new Date(auxInit);
            var corte = {
              liquidado: false,
              presentaPagos: false,
              fechaIni: fechaIni,
              fechaFin: fechaFin,
              noCuota: noCuota,
              capital: cap,
              interes: int,
              vlrCuota: pmt,
              segVida: 0,
              segTodoRiesgo: 0,
              vlrCuotaUVR: parseFloat(this.state.vlrCuota) / vlrUVRInit,
              vlrInteresUVR: parseFloat(int) / vlrUVRInit,
              vlrCapitalUVR: parseFloat(cap) / vlrUVRInit,
            };
            var int = cap * interesMensualP;
            cap = cap - (pmt - int);
            proyeccion.push(corte);
          }
          this.setState(
            {
              proyeccion: proyeccion,
            },
            function () {
              var auxPago = new Date(this.state.fechaPago);
              var auxProy = new Date(
                this.state.proyeccion[this.state.proyeccion.length - 1].fechaFin
              );
              auxPago.setHours(0, 0, 0, 0);
              if (auxPago.getTime() > auxProy.getTime()) {
                this.setState({ showModal: true });
              } else {
                this.submitHandlerPagos(e);
              }
            }
          );
        } else {
          var auxPago = new Date(this.state.fechaPago);
          var auxProy = new Date(
            this.state.proyeccion[this.state.proyeccion.length - 1].fechaFin
          );
          auxPago.setHours(0, 0, 0, 0);
          if (auxPago.getTime() > auxProy.getTime()) {
            this.setState({ showModal: true });
          } else {
            this.submitHandlerPagos(e);
          }
        }
      });
  };

  componentDidMount = (e) => {
    document.title = "Créditos";
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;
    console.log(JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])))

    this.setState({user: JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1]))})

    axios
      .get(apiMethods.GET_USUARIO_ID + localId, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var usr = response.data.users.username;
      })
      .catch((error) => {
        console.error(error);
      });

    var success = window.sessionStorage.getItem("success");
    var type = window.sessionStorage.getItem("type");
    if (success == "true" && type == "C") {
      document.getElementById("alertCli").innerHTML =
        "Credito creado exitosamente";
      $("#alertCli").show();
      $("#alertCli").fadeOut(7000);
      window.sessionStorage.setItem("success", "false");
    } else {
      $("#alertCli").hide();
    }
    if (window.sessionStorage.getItem("successPayment") == "true") {
      document.getElementById("alertCli").innerHTML =
        "Pago realizado exitosamente";
      $("#alertCli").show();
      $("#alertCli").fadeOut(7000);
      window.sessionStorage.setItem("successPayment", "false");
    }

    let search = window.sessionStorage.getItem("localDocument");
    var self = this;

    $(document).ready(function () {
      $("#warnalert").hide();
      $('[data-toggle="tooltip"]').tooltip();

      $("#table tbody").on("click", ".btnCL", function (e) {
        e.preventDefault();
        var tbl = $(this).closest("tr");
        var doc = tbl.find("td:eq(2)").html();
        axios
          .get(apiMethods.GET_CREDITO_ID + self.state.id, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            var localClients = response.data.credits.deudores;
            localClients.splice(localClients.indexOf(doc), 1);
            self.setState({ deudores: localClients }, function () {
              axios
                .patch(
                  apiMethods.PATCH_CREDITO_ID + self.state.id,
                  { deudores: this.state.deudores },
                  {
                    headers: {
                      Authorization: actUsrToken,
                    },
                  }
                )
                .then((response) => {
                  axios
                    .get(apiMethods.GET_CLIENTE_DOCUMENTO + doc, {
                      headers: {
                        Authorization: actUsrToken,
                      },
                    })
                    .then((response) => {
                      var localCredits = response.data.client.creditos;
                      localCredits.splice(
                        localCredits.indexOf(self.state.id),
                        1
                      );
                      axios
                        .patch(
                          apiMethods.PATCH_CLIENTE_DOCUMENTO + doc,
                          { creditos: localCredits },
                          {
                            headers: {
                              Authorization: actUsrToken,
                            },
                          }
                        )
                        .then((response) => {
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                  console.error(error);
                });
            });
          })
          .catch((error) => {
            console.error(error);
          });
      });
    });

    axios
      .get(apiMethods.GET_CREDITO_ID + search, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var auxAprobacion = new Date();
        var auxGiro = new Date();
        var auxDesembolsoReal = new Date();

        if (response.data.credits.fechaAprobacion == null) {
          auxAprobacion = new Date();
        } else {
          auxAprobacion = new Date(response.data.credits.fechaAprobacion);
        }

        if (response.data.credits.fechaDesembolsoReal == null) {
          auxDesembolsoReal = new Date();
        } else {
          auxDesembolsoReal = this.fixTimeZone(
            new Date(response.data.credits.fechaDesembolsoReal)
          );
        }

        if (response.data.credits.fechaDesembolso == null) {
          auxGiro = null;
        } else {
          auxGiro = new Date(response.data.credits.fechaDesembolso);
          auxGiro = new Date(
            auxGiro.getTime() + Math.abs(auxGiro.getTimezoneOffset() * 60000)
          );
        }

        var vlrInicialAux = response.data.credits.vlrInicial;
        const formatter = new Intl.NumberFormat("en-US", {
          currency: "USD",
        });
        vlrInicialAux = formatter.format(vlrInicialAux);
        var vlrCapitalAux = response.data.credits.vlrCapital;
        var vlrInteresAux = response.data.credits.vlrInteres;
        var vlrInteresMoraAux = response.data.credits.vlrInteresMora;
        var vlrSegVidaAux = response.data.credits.saldoSegVida;
        var vlrSegTodoRiesgoAux = response.data.credits.saldoSegTodoRiesgo;

        vlrCapitalAux = formatter.format(vlrCapitalAux);
        vlrInteresAux = formatter.format(vlrInteresAux);
        vlrInteresMoraAux = formatter.format(vlrInteresMoraAux);
        vlrSegVidaAux = formatter.format(vlrSegVidaAux);
        vlrSegTodoRiesgoAux = formatter.format(vlrSegTodoRiesgoAux);

        if (response.data.credits.fechaInicial) {
          var auxFechaInicial = response.data.credits.fechaInicial.replace(
            /-/g,
            "/"
          );
        }

        this.setState({
          deudores: response.data.credits.deudores,
          vlrCapital: vlrCapitalAux,
          plazo: response.data.credits.plazo,
          moneda: response.data.credits.moneda,
          estado: response.data.credits.estado,
          financialCode: response.data.credits.financialCode,
          fechaInicial: auxFechaInicial,
          fechaAprobacion: response.data.credits.fechaAprobacion
            ? auxAprobacion
            : null,
          fechaDesembolso: auxGiro,
          fechaDesembolsoReal: auxDesembolsoReal,
          transaccionesData: response.data.credits.transacciones,
          transacciones: response.data.credits.transacciones,
          vlrInicial: vlrInicialAux,
          id: response.data.credits.id,
          originalId: response.data.credits._id,
          garantia: response.data.credits.garantia,
          amortizacion: response.data.credits.amortizacion,
          interes: response.data.credits.interes,
          auxProxCorteSave: response.data.credits.fechaProxCorte,
          auxProxCorte: response.data.credits.fechaProxCorte,
          interesMoratorio: response.data.credits.interesMoratorio,
          vlrInteres: vlrInteresAux,
          vlrInteresMora: vlrInteresMoraAux,
          liquidacionesResp: response.data.credits.liquidaciones,
          files: response.data.credits.files,
          saldoSegVida: vlrSegVidaAux,
          saldoSegTodoRiesgo: vlrSegTodoRiesgoAux,
          seguroVida: response.data.credits.seguroVida,
          seguroTodoRiesgo: response.data.credits.seguroTodoRiesgo,
          vlrSegVida: response.data.credits.vlrSegVida,
          vlrSegTodoRiesgo: response.data.credits.vlrSegTodoRiesgo,
          proyeccionData: response.data.credits.proyeccion,
          proyeccion: response.data.credits.proyeccion,
          liquidacionesData: response.data.credits.liquidaciones,
          liquidaciones: response.data.credits.liquidaciones,
          estadoReal: response.data.credits.estado,
          vlrCuota: response.data.credits.vlrCuota,
          pasoEdicionLocal: response.data.credits.pasoEdicion,
          vlrInicialUVR: response.data.credits.vlrInicialUVR,
          auxTransacciones: response.data.credits.transacciones,
          auxFechaPagoReciente: response.data.credits.fechaPagoReciente,
          mortgageDate: response.data.credits.mortgageDate ? new Date(response.data.credits.mortgageDate) : null,
          deedNumber: response.data.credits.deedNumber,
          notary: response.data.credits.notary,
          expiryDate: response.data.credits.expiryDate ? new Date(response.data.credits.expiryDate) : null,
          landRegistry: response.data.credits.landRegistry,
        });

        for (let i = 0; i < this.state.deudores.length; i++) {
          axios
            .get(apiMethods.GET_CLIENTE_DOCUMENTO + this.state.deudores[i], {
              headers: {
                Authorization: actUsrToken,
              },
            })
            .then((response) => {
              var auxArray = this.state.clientesData;
              auxArray.push(response.data.client);
              this.setState({ clientesData: auxArray });
            })
            .catch((error) => {
              console.error(error);
            });
        }

        const months = [
          "ENE",
          "FEB",
          "MAR",
          "ABR",
          "MAY",
          "JUN",
          "JUL",
          "AGO",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];

        var detallesData = [];
        var detallesDataUVR = [];

        var transaccionesData = this.state.transaccionesData;
        for (let i = 0; i < transaccionesData.length; i++) {
          var local = transaccionesData[i];
          const formatter = new Intl.NumberFormat("en-US", {
            currency: "USD",
          });
          let current_datetime = new Date(
            local.fechaTx.substring(0, 10).replace(/-/g, "/")
          );
          let formatted_date =
            current_datetime.getDate() +
            "-" +
            months[current_datetime.getMonth()] +
            "-" +
            current_datetime.getFullYear();
          var localData = {
            siCapital: 0,
            siInteresCorriente: 0,
            siInteresMoratorio: 0,
            siSegVida: 0,
            siSegTodoRiesgo: 0,
            moInteresCorriente: 0,
            moInteresMoratorio: 0,
            moSegVida: 0,
            moSegTodoRiesgo: 0,
            apCapital: 0,
            apInteresCorriente: 0,
            apInteresMoratorio: 0,
            apSegVida: 0,
            apSegTodoRiesgo: 0,
            nvCapital: 0,
            nvInteresCorriente: 0,
            nvInteresMoratorio: 0,
            nvSegVida: 0,
            nvSegTodoRiesgo: 0,
          };

          local.fechaTx = formatted_date;
          local.vlrTx = formatter.format(local.vlrTx);
          local.vlrAplicadoCapital = formatter.format(
            this.toFixed(local.vlrAplicadoCapital, 0)
          );
          local.vlrAplicadoIntCte = formatter.format(
            this.toFixed(local.vlrAplicadoIntCte, 0)
          );
          local.vlrAplicadoIntMora = formatter.format(
            this.toFixed(local.vlrAplicadoIntMora, 0)
          );
          local.vlrAplicadoSegVida = formatter.format(
            this.toFixed(local.vlrAplicadoSegVida, 0)
          );
          local.vlrAplicadoSegTodoRiesgo = formatter.format(
            this.toFixed(local.vlrAplicadoSegTodoRiesgo, 0)
          );

          localData.siCapital = formatter.format(
            this.state.liquidacionesData[i].capital
          );

          localData.moInteresCorriente = formatter.format(
            this.toFixed(this.state.transaccionesData[i].genInteresCorriente, 0)
          );
          localData.moInteresMoratorio = formatter.format(
            this.toFixed(this.state.transaccionesData[i].genInteresMoratorio, 0)
          );
          localData.moSegVida = formatter.format(
            this.toFixed(this.state.transaccionesData[i].genSeguroVida, 0)
          );
          localData.moSegTodoRiesgo = formatter.format(
            this.toFixed(this.state.transaccionesData[i].genSeguroTodoRiesgo, 0)
          );

          localData.apCapital =
            this.state.transaccionesData[i].vlrAplicadoCapital;
          localData.apInteresCorriente =
            this.state.transaccionesData[i].vlrAplicadoIntCte;
          localData.apInteresMoratorio =
            this.state.transaccionesData[i].vlrAplicadoIntMora;
          localData.apSegVida =
            this.state.transaccionesData[i].vlrAplicadoSegVida;
          localData.apSegTodoRiesgo =
            this.state.transaccionesData[i].vlrAplicadoSegTodoRiesgo;

          localData.nvCapital = formatter.format(
            this.toFixed(this.state.liquidacionesData[i + 1].capital, 0)
          );
          localData.nvInteresCorriente = formatter.format(
            this.toFixed(
              this.state.liquidacionesData[i + 1].interesCorriente,
              0
            )
          );
          localData.nvInteresMoratorio = formatter.format(
            this.toFixed(
              this.state.liquidacionesData[i + 1].interesMoratorio,
              0
            )
          );
          localData.nvSegVida = formatter.format(
            this.toFixed(this.state.liquidacionesData[i + 1].segVida, 0)
          );
          localData.nvSegTodoRiesgo = formatter.format(
            this.toFixed(this.state.liquidacionesData[i + 1].segTodoRiesgo, 0)
          );

          if (this.state.transaccionesData[i].mesesEnMora != 0) {
            localData.siInteresCorriente = formatter.format(
              this.state.transaccionesData[i].dataMesesEnMora[
                this.state.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvInteresCorriente
            );
            localData.siInteresMoratorio = formatter.format(
              this.state.transaccionesData[i].dataMesesEnMora[
                this.state.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvInteresMoratorio
            );
            localData.siSegVida = formatter.format(
              this.state.transaccionesData[i].dataMesesEnMora[
                this.state.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvSegVida
            );
            localData.siSegTodoRiesgo = formatter.format(
              this.state.transaccionesData[i].dataMesesEnMora[
                this.state.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvSegTodoRiesgo
            );
          } else {
            localData.siInteresCorriente = formatter.format(
              this.state.liquidacionesData[i].interesCorriente
            );
            localData.siInteresMoratorio = formatter.format(
              this.state.liquidacionesData[i].interesMoratorio
            );
            localData.siSegVida = formatter.format(
              this.state.liquidacionesData[i].segVida
            );
            localData.siSegTodoRiesgo = formatter.format(
              this.state.liquidacionesData[i].segTodoRiesgo
            );
          }

          if (this.state.moneda == "uvr") {
            var localDataUVR = {
              siCapital: 0,
              siInteresCorriente: 0,
              siInteresMoratorio: 0,
              siSegVida: 0,
              siSegTodoRiesgo: 0,
              moInteresCorriente: 0,
              moInteresMoratorio: 0,
              moSegVida: 0,
              moSegTodoRiesgo: 0,
              apCapital: 0,
              apInteresCorriente: 0,
              apInteresMoratorio: 0,
              apSegVida: 0,
              apSegTodoRiesgo: 0,
              nvCapital: 0,
              nvInteresCorriente: 0,
              nvInteresMoratorio: 0,
              nvSegVida: 0,
              nvSegTodoRiesgo: 0,
            };

            localDataUVR.siCapital = formatter.format(
              this.toFixed(this.state.liquidaciones[i].capitalUVR, 0)
            );
            localDataUVR.moInteresCorriente = formatter.format(
              this.toFixed(
                this.state.transaccionesData[i].genInteresCorrienteUVR,
                0
              )
            );
            localDataUVR.moInteresMoratorio = formatter.format(
              this.toFixed(
                this.state.transaccionesData[i].genInteresMoratorioUVR,
                0
              )
            );
            localDataUVR.moSegVida = formatter.format(
              this.state.transaccionesData[i].genSeguroVida
            );
            localDataUVR.moSegTodoRiesgo = formatter.format(
              this.state.transaccionesData[i].genSeguroTodoRiesgo
            );

            localDataUVR.apCapital = formatter.format(
              this.toFixed(
                this.state.transaccionesData[i].vlrAplicadoCapitalUVR,
                0
              )
            );
            localDataUVR.apInteresCorriente = formatter.format(
              this.toFixed(
                this.state.transaccionesData[i].vlrAplicadoIntCteUVR,
                0
              )
            );
            localDataUVR.apInteresMoratorio = formatter.format(
              this.toFixed(
                this.state.transaccionesData[i].vlrAplicadoIntMoraUVR,
                0
              )
            );
            localDataUVR.apSegVida =
              this.state.transaccionesData[i].vlrAplicadoSegVida;
            localDataUVR.apSegTodoRiesgo =
              this.state.transaccionesData[i].vlrAplicadoSegTodoRiesgo;
            localDataUVR.nvCapital = formatter.format(
              this.toFixed(this.state.liquidacionesData[i + 1].capitalUVR, 0)
            );
            localDataUVR.nvInteresCorriente = formatter.format(
              this.toFixed(
                this.state.liquidacionesData[i + 1].interesCorrienteUVR,
                0
              )
            );
            localDataUVR.nvInteresMoratorio = formatter.format(
              this.toFixed(
                this.state.liquidacionesData[i + 1].interesMoratorioUVR,
                0
              )
            );
            localDataUVR.nvSegVida = formatter.format(
              this.state.liquidacionesData[i + 1].segVida
            );
            localDataUVR.nvSegTodoRiesgo = formatter.format(
              this.state.liquidacionesData[i + 1].segTodoRiesgo
            );

            if (this.state.transaccionesData[i].mesesEnMora != 0) {
              localDataUVR.siInteresCorriente = formatter.format(
                this.toFixed(
                  this.state.transaccionesData[i].dataMesesEnMora[
                    this.state.transaccionesData[i].dataMesesEnMora.length - 1
                  ].nvInteresCorrienteUVR,
                  0
                )
              );
              localDataUVR.siInteresMoratorio = formatter.format(
                this.toFixed(
                  this.state.transaccionesData[i].dataMesesEnMora[
                    this.state.transaccionesData[i].dataMesesEnMora.length - 1
                  ].nvInteresMoratorioUVR,
                  0
                )
              );
              localDataUVR.siSegVida = formatter.format(
                this.state.transaccionesData[i].dataMesesEnMora[
                  this.state.transaccionesData[i].dataMesesEnMora.length - 1
                ].nvSegVida
              );
              localDataUVR.siSegTodoRiesgo = formatter.format(
                this.state.transaccionesData[i].dataMesesEnMora[
                  this.state.transaccionesData[i].dataMesesEnMora.length - 1
                ].nvSegTodoRiesgo
              );
            } else {
              localDataUVR.siInteresCorriente = formatter.format(
                this.toFixed(
                  this.state.liquidacionesData[i].interesCorrienteUVR,
                  0
                )
              );
              localDataUVR.siInteresMoratorio = formatter.format(
                this.toFixed(
                  this.state.liquidacionesData[i].interesMoratorioUVR,
                  0
                )
              );
              localDataUVR.siSegVida = formatter.format(
                this.state.liquidacionesData[i].segVida
              );
              localDataUVR.siSegTodoRiesgo = formatter.format(
                this.state.liquidacionesData[i].segTodoRiesgo
              );
            }
            detallesDataUVR.push(localDataUVR);
          }

          detallesData.push(localData);
          transaccionesData[i] = local;
        }

        var totalTx = [];
        var totalTxUVR = [];

        for (let i = 0; i < this.state.transaccionesData.length; i++) {
          var localTxAbs = [];
          var localOp = [];
          var localTxAbsUVR = [];
          var localOpUVR = [];

          localOp.push(detallesData[i].siCapital);
          localOp.push(detallesData[i].siInteresCorriente);
          localOp.push(detallesData[i].siInteresMoratorio);
          localOp.push(detallesData[i].siSegVida);
          localOp.push(detallesData[i].siSegTodoRiesgo);
          localTxAbs.push(localOp);

          var localOp = [];
          localOp.push(detallesData[i].moInteresCorriente);
          localOp.push(detallesData[i].moInteresMoratorio);
          localOp.push(detallesData[i].moSegVida);
          localOp.push(detallesData[i].moSegTodoRiesgo);
          localTxAbs.push(localOp);

          var localOp = [];
          localOp.push(detallesData[i].apCapital);
          localOp.push(detallesData[i].apInteresCorriente);
          localOp.push(detallesData[i].apInteresMoratorio);
          localOp.push(detallesData[i].apSegVida);
          localOp.push(detallesData[i].apSegTodoRiesgo);
          localTxAbs.push(localOp);

          var localOp = [];
          localOp.push(detallesData[i].nvCapital);
          localOp.push(detallesData[i].nvInteresCorriente);
          localOp.push(detallesData[i].nvInteresMoratorio);
          localOp.push(detallesData[i].nvSegVida);
          localOp.push(detallesData[i].nvSegTodoRiesgo);
          localTxAbs.push(localOp);

          if (this.state.moneda == "uvr") {
            localOpUVR.push(detallesDataUVR[i].siCapital);
            localOpUVR.push(detallesDataUVR[i].siInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].siInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].siSegVida);
            localOpUVR.push(detallesDataUVR[i].siSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            var localOpUVR = [];
            localOpUVR.push(detallesDataUVR[i].moInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].moInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].moSegVida);
            localOpUVR.push(detallesDataUVR[i].moSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            var localOpUVR = [];
            localOpUVR.push(detallesDataUVR[i].apCapital);
            localOpUVR.push(detallesDataUVR[i].apInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].apInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].apSegVida);
            localOpUVR.push(detallesDataUVR[i].apSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            var localOpUVR = [];
            localOpUVR.push(detallesDataUVR[i].nvCapital);
            localOpUVR.push(detallesDataUVR[i].nvInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].nvInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].nvSegVida);
            localOpUVR.push(detallesDataUVR[i].nvSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            totalTxUVR.push(localTxAbsUVR);
          }

          totalTx.push(localTxAbs);
        }

        var resTx = [];
        var resTxUVR = [];

        for (let b = 1; b < this.state.transaccionesData.length + 1; b++) {
          var localTx = [];
          localTx.push(
            formatter.format(this.state.liquidacionesData[b].capital)
          );
          localTx.push(
            formatter.format(this.state.liquidacionesData[b].interesCorriente)
          );
          localTx.push(
            formatter.format(this.state.liquidacionesData[b].interesMoratorio)
          );
          localTx.push(
            formatter.format(this.state.liquidacionesData[b].segVida)
          );
          localTx.push(
            formatter.format(this.state.liquidacionesData[b].segTodoRiesgo)
          );

          if (this.state.moneda == "uvr") {
            var localTxUVR = [];
            localTxUVR.push(
              formatter.format(
                this.toFixed(this.state.liquidacionesData[b].capitalUVR, 2)
              )
            );
            localTxUVR.push(
              formatter.format(
                this.toFixed(
                  this.state.liquidacionesData[b].interesCorrienteUVR,
                  2
                )
              )
            );
            localTxUVR.push(
              formatter.format(
                this.toFixed(
                  this.state.liquidacionesData[b].interesMoratorioUVR,
                  2
                )
              )
            );
            localTxUVR.push(
              formatter.format(this.state.liquidacionesData[b].segVida)
            );
            localTxUVR.push(
              formatter.format(this.state.liquidacionesData[b].segTodoRiesgo)
            );
            resTxUVR.push(localTxUVR);
          }
          resTx.push(localTx);
        }

        var proyeccionAux = this.state.proyeccionData;
        for (let i = 0; i < proyeccionAux.length; i++) {
          var local = proyeccionAux[i];

          var fechaIni = this.fixTimeZone(new Date(local.fechaIni));
          var fechaFin = this.fixTimeZone(new Date(local.fechaFin));

          local.periodo =
            fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) +
            " " +
            months[fechaIni.getMonth()] +
            " " +
            fechaIni.getFullYear() +
            " al " +
            fechaFin.getDate() +
            " de " +
            months[fechaFin.getMonth()] +
            " " +
            fechaFin.getFullYear();

          const formatter = new Intl.NumberFormat("en-US", {
            currency: "USD",
          });
          local.capital = formatter.format(local.capital);
          local.interes = formatter.format(local.interes);
          local.vlrCuota = formatter.format(local.vlrCuota);
          local.segVida = formatter.format(local.segVida);
          local.segTodoRiesgo = formatter.format(local.segTodoRiesgo);
          if (this.state.moneda == "uvr") {
            local.vlrCapitalUVR = formatter.format(
              this.toFixed(local.vlrCapitalUVR, 2)
            );
            local.vlrInteresUVR = formatter.format(
              this.toFixed(local.vlrInteresUVR, 2)
            );
            local.vlrCuotaUVR = formatter.format(
              this.toFixed(local.vlrCuotaUVR, 2)
            );
          }
          proyeccionAux[i] = local;
        }

        var liquidacionesAux = [];
        var liquidacionesAuxUVR = [];

        var liquidacionesData = this.state.liquidacionesData;
        var mesSalidaMora = [];
        var pagosPorCreditos = [];
        var iteradorPagos = [];

        var sumMoras = 0;
        var sumHasPagos = 0;
        for (let i = 0; i < this.state.transaccionesData.length; i++) {
          sumMoras = sumMoras + this.state.transaccionesData[i].mesesEnMora;
          if (this.state.transaccionesData[i].mesesEnMora != 0) {
            for (
              let j = 0;
              j < this.state.transaccionesData[i].mesesEnMora;
              j++
            ) {
              mesSalidaMora.push(i);
            }
          }
        }

        var moved = false;
        var fechaRef;
        if (
          this.state.liquidaciones[0].interesCorriente != 0 ||
          this.state.liquidaciones[0].interesMoratorio != 0 ||
          this.state.liquidaciones[0].segVida != 0 ||
          this.state.liquidaciones[0].segTodoRiesgo != 0
        ) {
          for (let i = 0; i < this.state.proyeccionData.length; i++) {
            if (
              this.state.fechaDesembolso >=
              new Date(this.state.proyeccionData[i].fechaIni) &&
              this.state.fechaDesembolso <=
              new Date(this.state.proyeccionData[i].fechaFin)
            ) {
              if (this.state.proyeccion[0].noCuota == 0) {
                fechaRef = this.state.proyeccion[i];
              } else {
                fechaRef = this.state.proyeccion[i - 1];
              }
              moved = true;
            }
          }
        }

        if (moved == false) {
          for (let i = 0; i < this.state.proyeccionData.length; i++) {
            var acum = 0;
            for (let j = 0; j < this.state.transaccionesData.length; j++) {
              if (this.state.transaccionesData[j].cuota == i) {
                acum++;
              }
            }
            pagosPorCreditos.push(acum);
          }
        } else {
          for (
            let i = fechaRef.noCuota;
            i < this.state.proyeccionData.length;
            i++
          ) {
            var acum = 0;
            for (let j = 0; j < this.state.transaccionesData.length; j++) {
              if (this.state.transaccionesData[j].cuota == i) {
                acum++;
              }
            }
            pagosPorCreditos.push(acum);
          }
        }

        for (let i = 0; i < this.state.proyeccionData.length; i++) {
          if (this.state.proyeccionData[i].presentaPagos) {
            sumHasPagos++;
          }
        }

        var cntFilas = sumHasPagos + sumMoras;
        var liquidacion = {
          periodo: null,
          pagosTotal: 0,
          capital: formatter.format(this.state.liquidacionesData[0].capital),
          interesCorriente: formatter.format(
            this.state.liquidacionesData[0].interesCorriente
          ),
          interesMoratorio: formatter.format(
            this.state.liquidacionesData[0].interesMoratorio
          ),
          segVida: formatter.format(this.state.liquidacionesData[0].segVida),
          segTodoRiesgo: formatter.format(
            this.state.liquidacionesData[0].segTodoRiesgo
          ),
        };
        liquidacionesAux.push(liquidacion);

        if (this.state.moneda == "uvr") {
          var liquidacionUVR = {
            periodo: null,
            pagosTotal: 0,
            capital: formatter.format(
              this.toFixed(this.state.liquidacionesData[0].capitalUVR, 2)
            ),
            interesCorriente: formatter.format(
              this.toFixed(
                this.state.liquidacionesData[0].interesCorrienteUVR,
                2
              )
            ),
            interesMoratorio: formatter.format(
              this.toFixed(
                this.state.liquidacionesData[0].interesMoratorioUVR,
                2
              )
            ),
            segVida: formatter.format(
              this.toFixed(this.state.liquidacionesData[0].segVida, 2)
            ),
            segTodoRiesgo: formatter.format(
              this.toFixed(this.state.liquidacionesData[0].segTodoRiesgo, 2)
            ),
          };
          liquidacionesAuxUVR.push(liquidacionUVR);
        }

        if (this.state.moneda == "pesos") {
          var cntMora = 0;
          var moraLocal = 0;
          var cntLiquidacion = 0;
          var itPagos = 1;

          for (let i = 0; i < cntFilas; i++) {
            var cuotaLocal = this.state.proyeccionData[i];
            if (moved == true) {
              var cuotaLocal = this.state.proyeccionData[i + fechaRef.noCuota];
            }
            var localSum = 0;
            var localSumUVR = 0;
            var liquidacion = {
              periodo: 0,
              pagosTotal: 0,
              capital: 0,
              interesCorriente: 0,
              interesMoratorio: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };

            let fechaIni = this.fixTimeZone(new Date(cuotaLocal.fechaIni));
            let fechaFin = this.fixTimeZone(new Date(cuotaLocal.fechaFin));

            liquidacion.periodo =
              fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) +
              " " +
              months[fechaIni.getMonth()] +
              " " +
              fechaIni.getFullYear() +
              " al " +
              fechaFin.getDate() +
              " de " +
              months[fechaFin.getMonth()] +
              " " +
              fechaFin.getFullYear();

            const formatter = new Intl.NumberFormat("en-US", {
              currency: "USD",
            });

            if (
              this.state.liquidaciones[0].interesCorriente != 0 ||
              this.state.liquidaciones[0].interesMoratorio != 0 ||
              this.state.liquidaciones[0].segVida != 0 ||
              this.state.liquidaciones[0].segTodoRiesgo != 0
            ) {
              for (let j = 0; j < this.state.transaccionesData.length; j++) {
                if (
                  this.state.transaccionesData[j].cuota ==
                  i + fechaRef.noCuota
                ) {
                  localSum =
                    localSum +
                    parseFloat(
                      this.state.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                }
              }
            } else {
              for (let j = 0; j < this.state.transaccionesData.length; j++) {
                if (this.state.transaccionesData[j].cuota == i) {
                  localSum =
                    localSum +
                    parseFloat(
                      this.state.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                }
              }
            }

            if (!cuotaLocal.presentaPagos) {
              iteradorPagos[i] = 0;
              liquidacion.capital = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvCapital
              );
              liquidacion.interesCorriente = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresMoratorio
              );
              liquidacion.segVida = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegTodoRiesgo
              );
              moraLocal++;
              cntMora++;
            } else {
              iteradorPagos[i] = itPagos++;
              cntLiquidacion = cntLiquidacion + pagosPorCreditos[i];
              liquidacion.capital = formatter.format(
                liquidacionesData[cntLiquidacion].capital
              );
              liquidacion.interesCorriente = formatter.format(
                liquidacionesData[cntLiquidacion].interesCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                liquidacionesData[cntLiquidacion].interesMoratorio
              );
              liquidacion.segVida = formatter.format(
                liquidacionesData[cntLiquidacion].segVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                liquidacionesData[cntLiquidacion].segTodoRiesgo
              );
              moraLocal = 0;
            }

            liquidacion.pagosTotal = formatter.format(localSum);
            liquidacionesAux.push(liquidacion);

            if (this.state.moneda == "uvr") {
              liquidacionUVR.pagosTotal = formatter.format(localSumUVR);
              liquidacionesAuxUVR.push(liquidacionUVR);
            }
          }
        } else {
          var cntMora = 0;
          var moraLocal = 0;
          var cntLiquidacion = 0;
          var itPagos = 1;

          for (let i = 0; i < cntFilas; i++) {
            var cuotaLocal = this.state.proyeccionData[i];
            if (moved == true) {
              var cuotaLocal = this.state.proyeccionData[i + fechaRef.noCuota];
            }
            var localSum = 0;
            var localSumUVR = 0;
            var liquidacion = {
              periodo: 0,
              pagosTotal: 0,
              capital: 0,
              interesCorriente: 0,
              interesMoratorio: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };
            var liquidacionUVR = {
              periodo: 0,
              pagosTotal: 0,
              capital: 0,
              interesCorriente: 0,
              interesMoratorio: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };

            let fechaIni = this.fixTimeZone(new Date(cuotaLocal.fechaIni));
            let fechaFin = this.fixTimeZone(new Date(cuotaLocal.fechaFin));

            liquidacion.periodo =
              fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) +
              " " +
              months[fechaIni.getMonth()] +
              " " +
              fechaIni.getFullYear() +
              " al " +
              fechaFin.getDate() +
              " de " +
              months[fechaFin.getMonth()] +
              " " +
              fechaFin.getFullYear();
            liquidacionUVR.periodo = liquidacion.periodo;

            const formatter = new Intl.NumberFormat("en-US", {
              currency: "USD",
            });

            if (
              this.state.liquidaciones[0].interesCorriente != 0 ||
              this.state.liquidaciones[0].interesMoratorio != 0 ||
              this.state.liquidaciones[0].segVida != 0 ||
              this.state.liquidaciones[0].segTodoRiesgo != 0
            ) {
              for (let j = 0; j < this.state.transaccionesData.length; j++) {
                if (
                  this.state.transaccionesData[j].cuota ==
                  i + fechaRef.noCuota
                ) {
                  localSum =
                    localSum +
                    parseFloat(
                      this.state.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                  localSumUVR =
                    localSumUVR + this.state.transaccionesData[j].vlrTxUVR;
                }
              }
            } else {
              for (let j = 0; j < this.state.transaccionesData.length; j++) {
                if (this.state.transaccionesData[j].cuota == i) {
                  localSum =
                    localSum +
                    parseFloat(
                      this.state.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                  localSumUVR =
                    localSumUVR + this.state.transaccionesData[j].vlrTxUVR;
                }
              }
            }

            if (!cuotaLocal.presentaPagos) {
              iteradorPagos[i] = 0;
              liquidacion.capital = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvCapital
              );
              liquidacion.interesCorriente = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresMoratorio
              );
              liquidacion.segVida = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegTodoRiesgo
              );

              liquidacionUVR.capital = formatter.format(
                this.toFixed(
                  transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                    moraLocal
                  ].nvCapitalUVR,
                  2
                )
              );
              liquidacionUVR.interesCorriente = formatter.format(
                this.toFixed(
                  transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                    moraLocal
                  ].nvInteresCorrienteUVR,
                  2
                )
              );
              liquidacionUVR.interesMoratorio = formatter.format(
                this.toFixed(
                  transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                    moraLocal
                  ].nvInteresMoratorioUVR,
                  2
                )
              );
              liquidacionUVR.segVida = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegVida
              );
              liquidacionUVR.segTodoRiesgo = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegTodoRiesgo
              );

              moraLocal++;
              cntMora++;
            } else {
              iteradorPagos[i] = itPagos++;
              cntLiquidacion = cntLiquidacion + pagosPorCreditos[i];
              liquidacion.capital = formatter.format(
                liquidacionesData[cntLiquidacion].capital
              );
              liquidacion.interesCorriente = formatter.format(
                liquidacionesData[cntLiquidacion].interesCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                liquidacionesData[cntLiquidacion].interesMoratorio
              );
              liquidacion.segVida = formatter.format(
                liquidacionesData[cntLiquidacion].segVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                liquidacionesData[cntLiquidacion].segTodoRiesgo
              );

              liquidacionUVR.capital = formatter.format(
                this.toFixed(liquidacionesData[cntLiquidacion].capitalUVR, 2)
              );
              liquidacionUVR.interesCorriente = formatter.format(
                this.toFixed(
                  liquidacionesData[cntLiquidacion].interesCorrienteUVR,
                  2
                )
              );
              liquidacionUVR.interesMoratorio = formatter.format(
                this.toFixed(
                  liquidacionesData[cntLiquidacion].interesMoratorioUVR,
                  2
                )
              );
              liquidacionUVR.segVida = formatter.format(
                liquidacionesData[cntLiquidacion].segVida
              );
              liquidacionUVR.segTodoRiesgo = formatter.format(
                liquidacionesData[cntLiquidacion].segTodoRiesgo
              );

              moraLocal = 0;
            }

            liquidacion.pagosTotal = formatter.format(localSum);
            liquidacionUVR.pagosTotal = formatter.format(
              this.toFixed(localSumUVR, 2)
            );

            liquidacionesAux.push(liquidacion);
            liquidacionesAuxUVR.push(liquidacionUVR);
          }
        }
        axios
          .get(apiMethods.GET_CREDITO_ID + search, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            this.setState({
              liquidacionesDataUVR: liquidacionesAuxUVR,
              liquidacionesData: liquidacionesAux,
              liquidaciones: response.data.credits.liquidaciones,
              proyeccionData: proyeccionAux,
              proyeccion: response.data.credits.proyeccion,
              transaccionesData: transaccionesData,
              transacciones: response.data.credits.transacciones,
              pagosPorCreditos: pagosPorCreditos,
              detalleData: totalTx,
              detalleDataUVR: totalTxUVR,
              resPeriodo: resTx,
              resPeriodoUVR: resTxUVR,
              iteradorPagos: iteradorPagos,
            });

            if (this.state.estado == "aprobado") {
              $(".selectorEstado").val("aprobado");
              $("#aprobado").show();
              $("#noAprobado").hide();
            } else if (this.state.estado == "noAprobado") {
              $(".selectorEstado").val("noAprobado");
              $("#aprobado").hide();
              $("#noAprobado").show();
            }
            axios
              .get(apiMethods.GET_AUXILIAR_ID, {
                headers: {
                  Authorization: actUsrToken,
                },
              })
              .then((response) => {
                this.setState({
                  cntCargasUVR: response.data.auxiliar.cntCargasUVR,
                });
                axios
                  .get(
                    apiMethods.GET_UVRLOAD_ID +
                    response.data.auxiliar.cntCargasUVR,
                    {
                      headers: {
                        Authorization: actUsrToken,
                      },
                    }
                  )
                  .then((response) => {
                    this.setState({
                      lastUVR: response.data.uvrLoad.fecha
                        .toString()
                        .replace(/-/g, "/"),
                    });
                    if (window.sessionStorage.getItem("initialPDF") == "true") {
                      this.downloadInitPDF(e);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  downloadInitPDF = (a) => {
    var e = document.createEvent("Event");
    this.generateInitialPDF(e);
  };

  componentDidUpdate = () => { };

  handleChangeDatePicker = (date) => {
    this.setState({
      fechaPago: date,
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeHandlerEstado = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value == "aprobado") {
      $("#aprobado").show();
      $("#noAprobado").hide();
    } else if (e.target.value == "noAprobado") {
      $("#aprobado").hide();
      $("#noAprobado").show();
    }
  };

  changeHandlerFormato = (e) => {
    if (e.target.value != "") {
      e.target.value = parseFloat(e.target.value.replace(/,/g, ""))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  downloadPaymentsAplication() {
    var auxThis = this;

    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    })

    var tablePagos = [
      [{ text: 'Fecha del Pago', style: 'tableHeader', alignment: 'center', rowSpan: 2 }, { text: 'Valor del Abono', style: 'tableHeader', alignment: 'center', rowSpan: 2 }, { text: 'APLICACION DE LOS ABONOS A', style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {}, { text: 'Saldo a Capital', style: 'tableHeader', alignment: 'center', rowSpan: 2 }],
      ['', '', { text: 'Seguros', style: 'tableHeader', alignment: 'center', colSpan: 2 }, '', { text: 'Ints. Mora', style: 'tableHeader', alignment: 'center' }, { text: 'Intereses', style: 'tableHeader', alignment: 'center' }, { text: 'Capital', style: 'tableHeader', alignment: 'center' }, {}],
    ];

    this.state.transacciones.forEach(pago => {
      if (pago.vlrTx != 0) {
        tablePagos.push([("0" + this.fixTimeZone(new Date(pago.fechaTx)).getDate()).slice(-2) + "/" + ("0" + (this.fixTimeZone(new Date(pago.fechaTx)).getMonth() + 1)).slice(-2) + "/" + this.fixTimeZone(new Date(pago.fechaTx)).getFullYear(), formatter.format(pago.vlrTx), 'UVR', '', pago.vlrAplicadoIntMoraUVR ? formatter.format(this.toFixed(pago.vlrAplicadoIntMoraUVR, 0)) : 0, pago.vlrAplicadoIntCteUVR ? formatter.format(this.toFixed(pago.vlrAplicadoIntCteUVR, 0)) : 0, pago.vlrAplicadoCapitalUVR ? formatter.format(this.toFixed(pago.vlrAplicadoCapitalUVR, 0)) : 0, this.state.liquidacionesResp[pago.id + 1].capitalUVR ? formatter.format(this.toFixed(this.state.liquidacionesResp[pago.id + 1].capitalUVR, 0)) : 0])
        tablePagos.push(['', '', '$', this.formatNumber(this.toFixed(parseFloat(pago.vlrAplicadoSegVida) + parseFloat(pago.vlrAplicadoSegTodoRiesgo), 0)), formatter.format(this.toFixed(pago.vlrAplicadoIntMora, 0)), this.formatNumber(this.toFixed(pago.vlrAplicadoIntCte, 0)), this.formatNumber(this.toFixed(pago.vlrAplicadoCapital, 0)), this.formatNumber(this.toFixed(this.state.liquidacionesResp[pago.id + 1].capital, 0))])
      }
    })

    var title = "AUXILIAR APLICACIÓN DE PAGOS PARA CONTABILIZAR DESDE";
    var docDefinition = {
      pageMargins: [40, 280, 40, 200],
      header: function () {
        return [
          {
            margin: [0, 0, 0, 10],
            canvas: [
              {
                type: "rect",
                x: -15,
                y: -15,
                w: 610,
                h: 76,
                color: "#e92128",
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            canvas: [
              {
                type: "rect",
                x: -15,
                y: -20,
                w: 610,
                h: 8,
                color: "#B9B9B9",
              },
            ],
          },
          {
            text: "INMOBILIARIA",
            style: "title1",
            absolutePosition: { x: 305, y: 5 },
          },
          {
            text: "MOBILIA",
            style: "title2",
            absolutePosition: { x: 305, y: 27 },
          },
          {
            width: 100,
            image: logo,
            alignment: "center",
            absolutePosition: { y: 6, x: -110 },
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 1.3,
            text: "INFORME HISTÓRICO DE APLICACIÓN DE PAGOS",
            style: 'textBold',
          },
          {
            margin: [40, 0, 0, 5],
            canvas: [
              {

                type: 'line',
                x1: 0, y1: 0,
                x2: 515, y2: 0,
                lineWidth: 3,
                lineColor: 'red'
              },

            ]
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 2.5,
            text: " ",
            style: 'redText',
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 2,
            text: "OBLIGACION HIPOTECARIA NUMERO:",
            style: "textBold",
          },
          {
            absolutePosition: { y: 124, x: 405 },
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 150,
                h: 30,
                color: "#DCDDDF",
              },
            ],
          },
          {
            lineHeight: 2,
            text: auxThis.state.financialCode ? auxThis.state.financialCode : auxThis.state.id,
            style: "textBold",
            alignment: "right ",
            absolutePosition: { y: 134, x: 460 },
          },
          {
            margin: [40, 0, 0, 5],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            margin: [40, 10, 40, 0],

            table: {
              body: [
                [
                  {
                    fillColor: "#DCDDDF",
                    border: [false, false, false, false],
                    columns: [
                      {
                        width: 250,
                        stack: [
                          {
                            lineHeight: 1.5,
                            text: "APRECIADO CLIENTE",
                            style: "text",
                          },
                          {
                            lineHeight: 1.5,
                            text: auxThis.state.clientesData[0].nombre,
                            style: "textBold",
                          },
                          {
                            lineHeight: 1.5,
                            text: auxThis.state.clientesData[0].direccion,
                            style: "text",
                          },
                        ],
                      },
                      {
                        width: "auto",
                        columns: [
                          {
                            stack: [
                              {
                                lineHeight: 1,
                                text: "Valor del crédito:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Fecha de desembolso:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Tasa de interés:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Plazo:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Sistema de Amortización:",
                                style: "textBold",
                              },
                            ],
                          },
                          {
                            stack: [
                              {
                                lineHeight: 1,
                                text: "$" + auxThis.state.vlrInicial,
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text: auxThis.state.fechaDesembolsoReal.toISOString().substring(0, 10),
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text: auxThis.state.interes + " % " + "E.A.",
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text: auxThis.state.plazo + " meses",
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text:
                                  auxThis.state.amortizacion +
                                  " en " +
                                  auxThis.state.moneda,
                                style: "text",
                              },
                            ],
                          },
                        ],
                        columnGap: 10,
                      },
                    ],
                    columnGap: 30,
                  },
                ],
              ],
            },
          },
        ];
      },
      footer: function () {
        return [
          {
            absolutePosition: { y: 60, x: 0 },
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 700,
                h: 78,
                color: "#DCDDDF",
              },
            ],
          },
          {
            lineHeight: 2,
            text: "FORMA DE PAGO",
            style: "textBold",
            alignment: "center",
            absolutePosition: { x: 0, y: 72 },
          },
          {
            absolutePosition: { x: 20, y: 93 },
            margin: [40, 0, 0, 40],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            absolutePosition: { y: 102, x: 0 },
            lineHeight: 1.2,
            text: "Su pago lo puede efectuar en el Banco Caja Social Cuenta convenio No. 11542 \na nombre de Inmobiliaria Mobilia S.A. En cualquier ciudad del país",
            style: "textBold",
            alignment: "center",
          },
          {
            width: 600,
            image: footer,
            alignment: "center",
            absolutePosition: { y: 137, x: 0 },
          },
        ];
      },
      content: [
        {
          style: "tableExample2",
          table: {
            widths: [50, 50, 28, '*', '*', '*', '*', '*'],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: tablePagos,
          },
        },
      ],
      styles: {
        subtitle: {
          fontSize: 10,
          bold: true,
        },
        text: {
          fontSize: 10,
          bold: false,
        },
        textBoldTable: {
          fontSize: 9,
          bold: true,
        },
        headerPago: {
          fontSize: 10,
          bold: false,
        },
        textBold: {
          fontSize: 10,
          bold: true,
        },
        tableHeader: {
          fontSize: 8,
          bold: true,
        },
        tableHeaderColor: {
          fontSize: 8,
          bold: true,
          color: "white",
          fillColor: "#e92128",
        },
        redText: {
          fontSize: 10,
          bold: true,
          color: "#e92128",
        },
        tableExample: {
          fontSize: 8,
          color: "black",
        },
        tableExample2: {
          fontSize: 9,
          color: "black",
        },

        title1: {
          fontSize: 15,
          color: "white",
        },
        title2: {
          fontSize: 20,
          bold: true,
          color: "white",
        },
      },
    };
    console.log(docDefinition);
    pdfMake.createPdf(docDefinition).download("APLICACIÓN PAGOS.pdf");
  }

  buildTableBody(data, columns) {
    var body = [];

    body.push(columns);

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column) {
        dataRow.push(row[column].toString());
      });

      body.push(dataRow);
    });

    return body;
  }

  table(data, columns) {
    return {
      style: "tableExample2",
      margin: [176, 15, 0, 0],
      table: {
        widths: [70, "*", 100, "*"],
        headerRows: 1,
        body: this.buildTableBody(data, columns),
      },
    };
  }

  generateInitialPDF = (e) => {
    this.setState(
      {
        initialPDF: true,
      },
      function () {
        if (this.state.transacciones.length !== 0) {
          this.generatePDF(e);
        } else {
          this.relativePayment(e);
        }
      }
    );
  };

  paymentsAplicationDialog = () => {
    this.setState({
      showPaymentsAplicationDialog: !this.state.showPaymentsAplicationDialog,
    });
  };

  generatePDF = (e) => {
    e.preventDefault();

    let actUsrToken = window.sessionStorage.getItem("localToken");
    var e = document.getElementsByClassName("selectorDownload");
    if (window.sessionStorage.getItem("initialPDF") == "true") {
      var periodo = 0;
    } else {
      var periodo = parseFloat(
        e.estado.selectedOptions[0].id.replace(/\D/g, "")
      );
    }
    var savePeriodo = periodo;

    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    if (window.sessionStorage.getItem("initialPDF") == "true") {
      var auxDia = this.createSelectItemsLiq()[0].props.value.substring(15, 17);
      var auxMesText = this.createSelectItemsLiq()[0].props.value.substring(
        18,
        21
      );
      var auxMes;
      var auxAnio = this.createSelectItemsLiq()[0].props.value.substring(
        22,
        26
      );
    } else {
      var auxDia = e.estado.selectedOptions[0].value.substring(15, 17);
      var auxMesText = e.estado.selectedOptions[0].value.substring(18, 21);
      var auxMes;
      var auxAnio = e.estado.selectedOptions[0].value.substring(22, 26);
    }

    for (let i = 0; i < months.length; i++) {
      if (months[i] == auxMesText) {
        auxMes = i + 1;
      }
    }

    if (window.sessionStorage.getItem("initialPDF") == "true") {
      auxMes--;
    }

    if (auxMes < 10) {
      auxMes = "0" + auxMes;
    }

    let auxIni = this.fixTimeZone(
      new Date(this.state.proyeccion[periodo].fechaIni)
    );
    let auxFin = this.fixTimeZone(
      new Date(this.state.proyeccion[periodo].fechaFin)
    );

    var fechasPeriodo =
      ("0" + (auxIni.getDate() + 1)).slice(-2) +
      " " +
      months[auxIni.getMonth()] +
      " " +
      auxIni.getFullYear() +
      " al " +
      ("0" + auxFin.getDate()).slice(-2) +
      " " +
      months[auxFin.getMonth()] +
      " " +
      auxFin.getFullYear();

    var auxInit = new Date(
      this.state.transacciones[this.state.transacciones.length - 1].fechaTx
    );
    auxInit.setMonth(auxInit.getMonth() + 1);
    auxInit.setDate(this.state.fechaDesembolso.getDate());

    var year = auxInit.getFullYear();
    var month = auxInit.getMonth();
    var dt = auxInit.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var parsedDate = dt + "-" + month + "-" + year;

    axios
      .get(apiMethods.GET_UVR_FECHA + parsedDate, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var auxProxCorte = new Date(this.state.auxProxCorte);

        var year = auxProxCorte.getFullYear();
        var month = auxProxCorte.getMonth() + 1;
        var dt = auxProxCorte.getDate();

        if (dt < 10) {
          dt = "0" + dt;
        }
        if (month < 10) {
          month = "0" + month;
        }
        var parsedDate = auxDia + "-" + auxMes + "-" + auxAnio;
        console.log(parsedDate)

        var fechaPago = this.state.fechaPago;
        if (response.data.valor) {
          var vlrUVRInitAux = response.data.uvr[0].valor;
          var vlrUVRInit = parseFloat(vlrUVRInitAux);
        }

        axios
          .get(apiMethods.GET_UVR_FECHA + parsedDate, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            var fechaPago2 = this.state.fechaPago;
            if (response.data.uvr || this.state.moneda == "pesos") {
              if (this.state.moneda == "uvr") {
                var vlrUVRInitAux2 = response.data.uvr[0].valor;
                var vlrUVRInit2 = parseFloat(vlrUVRInitAux2);
              }
              console.log(response)

              const formatter = new Intl.NumberFormat("en-US", {
                currency: "USD",
              });

              var transacciones = this.state.transaccionesData;
              var auxTxAbs = [];
              var headers = [
                "Periodo",
                "Pagos realizados en el periodo",
                "Capital",
                "Interés Corriente",
                "Interés Moratorio",
                "Seguro de vida",
                "Seguro de incendio y todo riesgo",
              ];
              var auxHeaders = [];

              for (let i = 0; i < 7; i++) {
                var auxHeader = {
                  text: headers[i],
                  style: ["tableHeader"],
                };
                auxHeaders.push(auxHeader);
              }
              auxTxAbs.push(auxHeaders);

              var clientes = [];

              for (let i = 1; i < this.state.clientesData.length; i++) {
                var title = {
                  lineHeight: 1,
                  text: "APRECIADO CLIENTE",
                  style: "text",
                };
                clientes.push(title);

                var nombre = {
                  lineHeight: 1,
                  text: [this.state.clientesData[i].nombre],
                  style: "textBold",
                };

                clientes.push(nombre);

                var direccion = {
                  lineHeight: 2,
                  text: [this.state.clientesData[i].direccion],
                  style: "text",
                };

                clientes.push(direccion);
              }

              var paymentsHeader = [
                {
                  text: "PAGOS RECIBIDOS EN ESTE PERIODO",
                  alignment: "center",
                  colSpan: 2,
                  border: [false, false, false, false],
                },
                {},
              ];

              var paymentsBody = [];

              var neededPayments = [];
              var neededPaymentsData = [];

              var auxPeriodo = periodo;

              var fechaDesembolsoReal;

              if (
                this.state.liquidaciones[0].interesCorriente != 0 ||
                this.state.liquidaciones[0].interesMoratorio != 0 ||
                this.state.liquidaciones[0].segVida != 0 ||
                this.state.liquidaciones[0].segTodoRiesgo != 0
              ) {
                var sumMora = 0;
                var auxFecha = new Date(this.state.fechaInicial);
                fechaDesembolsoReal =
                  new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                    auxFecha
                  ) +
                  " de " +
                  new Intl.DateTimeFormat("es", { month: "long" }).format(
                    auxFecha
                  ) +
                  " " +
                  new Intl.DateTimeFormat("en", { year: "numeric" }).format(
                    auxFecha
                  );

                for (let i = 0; i < this.state.transacciones.length; i++) {
                  sumMora = sumMora + this.state.transacciones[i].mesesEnMora;
                }

                var cntCuotas = 0;

                for (let i = 0; i < this.state.liquidacionesData.length; i++) {
                  if (this.state.liquidacionesData[i].pagosTotal != 0) {
                    cntCuotas++;
                  }
                }

                if (
                  cntCuotas == sumMora &&
                  this.state.transacciones.length > 1
                ) {
                  sumMora--;
                }

                var sum = 0;
              } else {
                fechaDesembolsoReal =
                  new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                    this.state.fechaDesembolso
                  ) +
                  " de " +
                  new Intl.DateTimeFormat("es", { month: "long" }).format(
                    this.state.fechaDesembolso
                  ) +
                  " " +
                  new Intl.DateTimeFormat("en", { year: "numeric" }).format(
                    this.state.fechaDesembolso
                  );
              }

              var neededLiq = [];
              var sumMoraTx = 0;

              for (let i = 0; i <= periodo; i++) {
                if (this.state.proyeccion[i].presentaPagos == false) {
                  sumMoraTx++;
                }
              }

              if (this.state.proyeccion[0].liquidado == false) {
                sumMoraTx--;
              }

              if (sumMoraTx < 0) {
                sumMoraTx = 0;
              }

              var cntCero = 0;

              for (let i = 0; i < this.state.transacciones.length; i++) {
                if (this.state.transacciones[i].vlrTx == 0) {
                  cntCero++;
                }
              }

              periodo = periodo - cntCero;

              if (periodo < 0) {
                periodo = 0;
              }

              var genPago = this.state.transacciones[periodo - sumMoraTx];

              for (let i = 0; i < this.state.transacciones.length; i++) {
                if (this.state.proyeccionData[0].liquidado == true) {
                  if (this.state.transacciones[i].cuota == savePeriodo) {
                    neededPayments.push(this.state.transacciones[i]);
                  }
                } else {
                  if (this.state.transacciones[i].cuota == savePeriodo) {
                    neededPayments.push(this.state.transacciones[i]);
                  }
                }
              }

              var cero = false;
              var normal = false;

              for (let i = 0; i < neededPayments.length; i++) {
                if (neededPayments[i].vlrTx == 0) {
                  cero = true;
                } else {
                  normal = true;
                }
              }

              if (cero && normal) {
                for (let i = 0; i < neededPayments.length; i++) {
                  if (neededPayments[i].vlrTx == 0) {
                    neededPayments.splice(i);
                  }
                }
              }

              for (let i = 0; i < neededPayments.length; i++) {
                neededLiq.push(
                  this.state.liquidaciones[neededPayments[i].id + 1]
                );
              }

              var auxFechaFinPeriodo = new Date(
                this.state.proyeccion[neededPayments[0].cuota].fechaFin
              );
              auxFechaFinPeriodo = this.fixTimeZone(
                new Date(auxFechaFinPeriodo)
              );
              auxFechaFinPeriodo.setMonth(auxFechaFinPeriodo.getMonth() + 1);
              auxFechaFinPeriodo.setHours(0, 0, 0, 0);

              var fechaCompleta =
                ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                "/" +
                ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                "/" +
                auxFechaFinPeriodo.getFullYear();
              var sumIntCteGen = 0;
              var sumIntMoraGen = 0;
              var sumIntCteGenUVR = 0;
              var sumIntMoraGenUVR = 0;
              var sumSegGen = 0;

              var sumIntCteApl = 0;
              var sumIntMoraApl = 0;
              var sumSegApl = 0;
              var sumCapApl = 0;

              var sumIntCteAplUVR = 0;
              var sumIntMoraAplUVR = 0;
              var sumCapAplUVR = 0;

              for (let i = 0; i < neededPaymentsData.length; i++) {
                sumIntCteGen =
                  sumIntCteGen + neededPaymentsData[i].genInteresCorriente;
                sumIntMoraGen =
                  sumIntMoraGen + neededPaymentsData[i].genInteresMoratorio;
                sumSegGen =
                  sumSegGen +
                  neededPaymentsData[i].genSeguroVida +
                  neededPaymentsData[i].genSeguroTodoRiesgo;

                sumCapApl =
                  sumCapApl + neededPaymentsData[i].vlrAplicadoCapital;
                sumIntCteApl =
                  sumIntCteApl + neededPaymentsData[i].vlrAplicadoIntCte;
                sumIntMoraApl =
                  sumIntMoraApl + neededPaymentsData[i].vlrAplicadoIntMora;
                sumSegApl =
                  sumSegApl +
                  neededPaymentsData[i].vlrAplicadoSegVida +
                  neededPaymentsData[i].vlrAplicadoSegTodoRiesgo;

                if (this.state.moneda == "uvr") {
                  sumIntCteGenUVR =
                    sumIntCteGenUVR +
                    neededPaymentsData[i].genInteresCorrienteUVR;
                  sumIntMoraGenUVR =
                    sumIntMoraGenUVR +
                    neededPaymentsData[i].genInteresMoratorioUVR;

                  sumCapAplUVR =
                    sumCapAplUVR + neededPaymentsData[i].vlrAplicadoCapitalUVR;
                  sumIntCteAplUVR =
                    sumIntCteAplUVR +
                    neededPaymentsData[i].vlrAplicadoIntCteUVR;
                  sumIntMoraAplUVR =
                    sumIntMoraAplUVR +
                    neededPaymentsData[i].vlrAplicadoIntMoraUVR;
                }
              }

              var auxLiquidacionPrev;
              var auxLiquidacionPost;

              auxLiquidacionPost =
                this.state.liquidaciones[
                neededPayments[0].id +
                this.state.pagosPorCreditos[auxPeriodo - 1]
                ];

              for (let i = 0; i < neededPayments.length; i++) {
                paymentsBody.push({
                  Fecha: neededPayments[i].fechaTx,
                  Monto: "$" + neededPayments[i].vlrTx,
                });
              }

              if (this.state.moneda == "uvr") {
                var auxVlrCuota =
                  this.state.proyeccion[0].vlrCuotaUVR *
                  parseFloat(vlrUVRInit2);
              } else {
                var auxVlrCuota = this.state.proyeccion[0].vlrCuota;
              }
              console.log(auxVlrCuota);
              console.log(vlrUVRInit2)

              if (
                this.state.proyeccion[
                neededPayments[neededPayments.length - 1].cuota + 1
                ]
              ) {
                auxVlrCuota += parseFloat(
                  this.state.proyeccion[
                    neededPayments[neededPayments.length - 1].cuota + 1
                  ].segVida
                );
                auxVlrCuota += parseFloat(
                  this.state.proyeccion[
                    neededPayments[neededPayments.length - 1].cuota + 1
                  ].segTodoRiesgo
                );
              }

              if (this.state.moneda === "uvr") {
                var auxProy = 0;
                if (
                  this.state.proyeccion[
                  neededPayments[neededPayments.length - 1].cuota + 1
                  ]
                ) {
                  auxProy = parseFloat(
                    this.state.proyeccion[
                      neededPayments[neededPayments.length - 1].cuota + 1
                    ].vlrCapitalUVR
                  );
                }
                var capitalEnMora =
                  parseFloat(neededLiq[neededLiq.length - 1].capitalUVR) -
                  auxProy;

                if (capitalEnMora < 1) {
                  capitalEnMora = 0;
                }
                capitalEnMora = parseFloat(vlrUVRInit2) * capitalEnMora;
                if (capitalEnMora < 0) {
                  capitalEnMora = 0;
                }
              } else {
                var auxProy = 0;
                if (
                  this.state.proyeccion[
                  neededPayments[neededPayments.length - 1].cuota + 1
                  ]
                ) {
                  auxProy = parseFloat(
                    this.state.proyeccion[
                      neededPayments[neededPayments.length - 1].cuota + 1
                    ].capital
                  );
                }
                var capitalEnMora =
                  parseFloat(neededLiq[neededLiq.length - 1].capital) - auxProy;

                if (capitalEnMora < 0) {
                  capitalEnMora = 0;
                }
              }

              if (this.state.moneda == "uvr") {
                var saldoMora =
                  parseFloat(capitalEnMora) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesCorrienteCuotaUVR
                  ) *
                  parseFloat(vlrUVRInit2) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesMoratorioCuotaUVR
                  ) *
                  parseFloat(vlrUVRInit2) +
                  parseFloat(neededLiq[neededLiq.length - 1].segVida) +
                  parseFloat(neededLiq[neededLiq.length - 1].segTodoRiesgo);
              } else {
                var saldoMora =
                  parseFloat(capitalEnMora) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesCorrienteCuota
                  ) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesMoratorioCuota
                  ) +
                  parseFloat(neededLiq[neededLiq.length - 1].segVida) +
                  parseFloat(neededLiq[neededLiq.length - 1].segTodoRiesgo);
              }

              if (this.state.moneda == "uvr") {
                if (neededPayments[0].cuota < parseFloat(this.state.plazo)) {
                  console.log(this)
                  console.log(this.state.plazo)
                  console.log(neededPayments[0].cuota)
                  console.log(saldoMora)
                  if (saldoMora > 0) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "/" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "/" +
                      auxFechaFinPeriodo.getFullYear() +
                      " por un monto aproximado de $" +
                      formatter.format(this.toFixed(auxVlrCuota, 0)) +
                      "\n" +
                      "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                    var textoPaguese = "PAGUESE INMEDIATAMENTE";
                  } else if (saldoMora == 0) {
                    var textoMora =
                      "Su obligación está al día, su próxima cuota es del dia " +
                      fechaCompleta +
                      " por un monto de $" +
                      formatter.format(this.toFixed(auxVlrCuota, 0));
                    var textoPaguese =
                      "PAGUESE EL: " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "-" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "-" +
                      auxFechaFinPeriodo.getFullYear();
                  } else if (saldoMora < 0) {
                    var textoMora = "Su obligación está al día";
                  } else if (
                    this.state.proyeccion[this.state.proyeccion.length - 1].fechaFin <
                    neededPayments[0].fechaTx
                  ) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente por un monto aproximado de $" +
                      formatter.format(this.toFixed(auxVlrCuota, 0)) +
                      "\n" +
                      "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                  }
                } else {
                  var textoMora =
                    "Su obligación se encuentra en mora en $" +
                    formatter.format(saldoMora) +
                    " por favor ponerse al dia inmediatamente.";
                  var textoPaguese = "PAGUESE INMEDIATAMENTE";
                }
                if (this.state.initialPDF) {
                  var auxFecha = this.state.fechaDesembolso;
                  auxFecha.setMonth(auxFecha.getMonth() + 1);
                  var textoMora =
                    "Su obligación se encuentra al día, su próxima cuota programada es el dia " +
                    ("0" + auxFecha.getDate()).slice(-2) +
                    "/" +
                    ("0" + (auxFecha.getMonth() + 1)).slice(-2) +
                    "/" +
                    auxFecha.getFullYear() +
                    " por un monto aproximado de $" +
                    formatter.format(this.toFixed(saldoMora, 0)) +
                    "\n" +
                    "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                  this.setState({ initialPDF: false });
                }
              } else {
                console.log(this.state.plazo)
                console.log(neededPayments[0].cuota)
                console.log(saldoMora)
                if (neededPayments[0].cuota < parseFloat(this.state.plazo)) {
                  if (saldoMora > 0) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "/" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "/" +
                      auxFechaFinPeriodo.getFullYear() +
                      " por un monto de $" +
                      formatter.format(this.toFixed(auxVlrCuota, 0));
                    var textoPaguese = "PAGUESE INMEDIATAMENTE";
                  } else if (saldoMora == 0) {
                    var textoMora =
                      "Su obligación está al día, su próxima cuota es del dia " +
                      fechaCompleta +
                      " por un monto de $" +
                      formatter.format(this.toFixed(auxVlrCuota, 0));
                    var textoPaguese =
                      "PAGUESE EL: " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "-" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "-" +
                      auxFechaFinPeriodo.getFullYear();
                  } else if (saldoMora < 0) {
                    var textoMora = "Su obligación está al día";
                  } else if (
                    this.proyeccion[this.proyeccion.length - 1].fechaFin <
                    neededPayments[0].fechaTx
                  ) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente por un monto de $" +
                      formatter.format(this.toFixed(auxVlrCuota, 0));
                  }
                } else {
                  var textoMora =
                    "Su obligación se encuentra en mora en $" +
                    formatter.format(saldoMora) +
                    " por favor ponerse al dia inmediatamente.";
                  var textoPaguese = "PAGUESE INMEDIATAMENTE";
                }
                if (this.state.initialPDF) {
                  console.log(auxVlrCuota)
                  var auxFecha = this.state.fechaDesembolso;
                  auxFecha.setMonth(auxFecha.getMonth() + 1);
                  var textoMora =
                    "Su obligación se encuentra al día, su próxima cuota programada es el dia " +
                    ("0" + auxFecha.getDate()).slice(-2) +
                    "/" +
                    ("0" + (auxFecha.getMonth() + 1)).slice(-2) +
                    "/" +
                    auxFecha.getFullYear() +
                    " por un monto de $" +
                    formatter.format(this.toFixed(saldoMora, 0));
                  this.setState({ initialPDF: false });
                }
              }
              console.log(auxVlrCuota)
              console.log(textoMora)
              var liqTables = [];
              var cntNeededPayments = neededPayments[0].id;

              for (let i = 0; i < neededPayments.length; i++) {
                var liqGen = [];

                var auxFecha = new Date(neededPayments[i].fechaTx);
                auxFecha = this.fixTimeZone(new Date(auxFecha));

                var textoFecha =
                  ("0" + auxFecha.getDate()).slice(-2) +
                  "-" +
                  months[auxFecha.getMonth()] +
                  "-" +
                  auxFecha.getFullYear();

                var auxNuevoCorriente = formatter.format(
                  parseFloat(
                    this.state.detalleData[cntNeededPayments][3][1].replace(
                      /,/g,
                      ""
                    )
                  )
                );

                var auxSegVida;

                if (
                  i == 0 &&
                  neededPayments[0].id == 0 &&
                  this.state.detalleData[cntNeededPayments][0][3] == "0"
                ) {
                  auxSegVida = formatter.format(
                    this.state.liquidacionesResp[0].segVida
                  );
                } else {
                  auxSegVida = this.state.detalleData[cntNeededPayments][0][3];
                }

                if (
                  i == 0 &&
                  neededPayments[0].id == 0 &&
                  this.state.detalleData[cntNeededPayments][0][4] == "0"
                ) {
                  var auxSegTodoRiesgo = formatter.format(
                    this.state.liquidacionesResp[0].segTodoRiesgo
                  );
                } else {
                  var auxSegTodoRiesgo =
                    this.state.detalleData[cntNeededPayments][0][4];
                }

                if (this.state.moneda == "uvr") {
                  var auxNuevoCorrienteUVR = formatter.format(
                    parseFloat(
                      this.state.detalleDataUVR[
                        cntNeededPayments
                      ][3][1].replace(/,/g, "")
                    )
                  );
                  var saldoInicial = [
                    "Saldo Inicial",
                    this.state.detalleDataUVR[cntNeededPayments][0][0] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][0][0] +
                    ")",
                    this.state.detalleDataUVR[cntNeededPayments][0][1] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][0][1] +
                    ")",
                    this.state.detalleDataUVR[cntNeededPayments][0][2] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][0][2] +
                    ")",
                    "$" + auxSegVida,
                    "$" + auxSegTodoRiesgo,
                  ];
                  var movimientos = [
                    "Movimientos",
                    "",
                    this.state.detalleDataUVR[cntNeededPayments][1][0] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][1][0] +
                    ")",
                    this.state.detalleDataUVR[cntNeededPayments][1][1] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][1][1] +
                    ")",
                    "$" + this.state.detalleData[cntNeededPayments][1][2],
                    "$" + this.state.detalleData[cntNeededPayments][1][3],
                  ];
                  var aplicacion = [
                    "Aplicación del pago",
                    this.state.detalleDataUVR[cntNeededPayments][2][0] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][2][0] +
                    ")",
                    this.state.detalleDataUVR[cntNeededPayments][2][1] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][2][1] +
                    ")",
                    this.state.detalleDataUVR[cntNeededPayments][2][2] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][2][2] +
                    ")",
                    "$" + this.state.detalleData[cntNeededPayments][2][3],
                    "$" + this.state.detalleData[cntNeededPayments][2][4],
                  ];
                  var saldo = [
                    "Nuevo Saldo",
                    this.state.detalleDataUVR[cntNeededPayments][3][0] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][3][0] +
                    ")",
                    auxNuevoCorrienteUVR +
                    " UVR" +
                    " " +
                    "($" +
                    auxNuevoCorriente +
                    ")",
                    this.state.detalleDataUVR[cntNeededPayments][3][2] +
                    " UVR" +
                    " " +
                    "($" +
                    this.state.detalleData[cntNeededPayments][3][2] +
                    ")",
                    "$" + this.state.detalleData[cntNeededPayments][3][3],
                    "$" + this.state.detalleData[cntNeededPayments][3][4],
                  ];
                } else {
                  var saldoInicial = [
                    "Saldo Inicial",
                    "$" + this.state.detalleData[cntNeededPayments][0][0],
                    "$" + this.state.detalleData[cntNeededPayments][0][1],
                    "$" + this.state.detalleData[cntNeededPayments][0][2],
                    "$" + auxSegVida,
                    "$" + auxSegTodoRiesgo,
                  ];
                  var movimientos = [
                    "Movimientos",
                    "",
                    "$" + this.state.detalleData[cntNeededPayments][1][0],
                    "$" + this.state.detalleData[cntNeededPayments][1][1],
                    "$" + this.state.detalleData[cntNeededPayments][1][2],
                    "$" + this.state.detalleData[cntNeededPayments][1][3],
                  ];
                  var aplicacion = [
                    "Aplicación del pago",
                    "$" + this.state.detalleData[cntNeededPayments][2][0],
                    "$" + this.state.detalleData[cntNeededPayments][2][1],
                    "$" + this.state.detalleData[cntNeededPayments][2][2],
                    "$" + this.state.detalleData[cntNeededPayments][2][3],
                    "$" + this.state.detalleData[cntNeededPayments][2][4],
                  ];
                  var saldo = [
                    "Nuevo Saldo",
                    "$" + this.state.detalleData[cntNeededPayments][3][0],
                    "$" + auxNuevoCorriente,
                    "$" + this.state.detalleData[cntNeededPayments][3][2],
                    "$" + this.state.detalleData[cntNeededPayments][3][3],
                    "$" + this.state.detalleData[cntNeededPayments][3][4],
                  ];
                }

                if (this.state.moneda == "uvr") {
                  var movimientosRes = [
                    "Movimientos",
                    "",
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaGenUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaGen,
                        0
                      )
                    ) +
                    ")",
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaGenUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaGen,
                        0
                      )
                    ) +
                    ")",
                    "$0",
                    "$0",
                  ];
                  var saldoRes = [
                    "Nuevo Saldo",
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1].capitalUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1].capitalProxCorte,
                        0
                      )
                    ) +
                    ")",
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1].interesCorrienteCuota,
                        0
                      )
                    ) +
                    ")",
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1].interesMoratorioCuota,
                        0
                      )
                    ) +
                    ")",
                    "$" + this.state.detalleData[cntNeededPayments][3][3],
                    "$" + this.state.detalleData[cntNeededPayments][3][4],
                  ];
                } else {
                  var movimientosRes = [
                    "Movimientos",
                    "",
                    "$" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaGen,
                        0
                      )
                    ),
                    "$" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaGen,
                        0
                      )
                    ),
                    "$0",
                    "$0",
                  ];
                  var saldoRes = [
                    "Nuevo Saldo",
                    "$" +
                    formatter.format(
                      this.toFixed(neededLiq[neededLiq.length - 1].capital, 0)
                    ),
                    "$" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1].interesCorrienteCuota,
                        0
                      )
                    ),
                    "$" +
                    formatter.format(
                      this.toFixed(
                        neededLiq[neededLiq.length - 1].interesMoratorioCuota,
                        0
                      )
                    ),
                    "$" + this.state.detalleData[cntNeededPayments][3][3],
                    "$" + this.state.detalleData[cntNeededPayments][3][4],
                  ];
                }

                if (
                  neededPayments.length > 1 &&
                  i == neededPayments.length - 1
                ) {
                  var auxTable = {
                    style: "tableExample",
                    color: "black",
                    margin: [0, 10, 0, 0],
                    table: {
                      body: [
                        [
                          "",
                          {
                            text: "Capital",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Corriente",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Moratorio",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Vida",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Incendio y Todo Riesgo",
                            style: "textBoldTable",
                          },
                        ],
                        saldoInicial,
                        movimientos,
                        aplicacion,
                        saldo,
                      ],
                    },
                    pageBreak: "after",
                  };
                } else {
                  var auxTable = {
                    style: "tableExample",
                    color: "black",
                    margin: [0, 10, 0, 0],
                    table: {
                      body: [
                        [
                          "",
                          {
                            text: "Capital",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Corriente",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Moratorio",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Vida",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Incendio y Todo Riesgo",
                            style: "textBoldTable",
                          },
                        ],
                        saldoInicial,
                        movimientos,
                        aplicacion,
                        saldo,
                      ],
                    },
                  };
                }

                var auxTableRes = {
                  style: "tableExample",
                  color: "black",
                  margin: [0, 10, 0, 0],
                  table: {
                    body: [
                      [
                        "",
                        { text: "Capital", style: "textBoldTable" },
                        {
                          text: "Interés Corriente",
                          style: "textBoldTable",
                        },
                        {
                          text: "Interés Moratorio",
                          style: "textBoldTable",
                        },
                        {
                          text: "Seguro de Vida",
                          style: "textBoldTable",
                        },
                        {
                          text: "Seguro de Incendio y Todo Riesgo",
                          style: "textBoldTable",
                        },
                      ],
                      movimientosRes,
                      saldoRes,
                    ],
                  },
                };
                if (neededPayments[i].vlrTx != 0) {
                  if (this.state.moneda == "uvr") {
                    var text = {
                      text:
                        "Pago " +
                        (i + 1) +
                        ". " +
                        formatter.format(
                          this.toFixed(neededPayments[i].vlrTxUVR, 2)
                        ) +
                        " UVR " +
                        "($" +
                        formatter.format(
                          this.toFixed(neededPayments[i].vlrTx, 2)
                        ) +
                        ")" +
                        " el " +
                        textoFecha,
                      style: "headerPago",
                      margin: [0, 10, 0, 0],
                    };
                  } else {
                    var text = {
                      text:
                        "Pago " +
                        (i + 1) +
                        ". " +
                        formatter.format(
                          this.toFixed(neededPayments[i].vlrTx, 2)
                        ) +
                        " el " +
                        textoFecha,
                      style: "headerPago",
                      margin: [0, 10, 0, 0],
                    };
                  }
                } else {
                  var text = {
                    text: "",
                    style: "headerPago",
                    margin: [0, 10, 0, 0],
                  };
                }

                cntNeededPayments++;

                liqGen.push(text);
                liqGen.push(auxTable);
                liqTables.push(liqGen);
              }
              var auxThis = this;
              var nombre = this.state.clientesData[0].nombre;
              var direccion = this.state.clientesData[0].direccion;

              var docDefinition = {
                pageMargins: [40, 280, 40, 200],
                header: function () {
                  return [
                    {
                      margin: [0, 0, 0, 10],
                      canvas: [
                        {
                          type: "rect",
                          x: -15,
                          y: -15,
                          w: 610,
                          h: 76,
                          color: "#e92128",
                        },
                      ],
                    },
                    {
                      margin: [0, 0, 0, 10],
                      canvas: [
                        {
                          type: "rect",
                          x: -15,
                          y: -20,
                          w: 610,
                          h: 8,
                          color: "#B9B9B9",
                        },
                      ],
                    },
                    {
                      text: "INMOBILIARIA",
                      style: "title1",
                      absolutePosition: { x: 305, y: 5 },
                    },
                    {
                      text: "MOBILIA",
                      style: "title2",
                      absolutePosition: { x: 305, y: 27 },
                    },
                    {
                      width: 100,
                      image: logo,
                      alignment: "center",
                      absolutePosition: { y: 6, x: -110 },
                    },
                    {
                      margin: [40, 0, 0, 0],
                      lineHeight: 1.3,
                      text: "ESTADO DE CUENTA",
                      style: "textBold",
                    },
                    {
                      margin: [40, 0, 0, 5],
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 515,
                          y2: 0,
                          lineWidth: 3,
                          lineColor: "red",
                        },
                      ],
                    },
                    {
                      margin: [40, 0, 0, 0],
                      lineHeight: 2.5,
                      text: "PERIODO ENTRE " + fechasPeriodo,
                      style: "redText",
                    },
                    {
                      margin: [40, 0, 0, 0],
                      lineHeight: 2,
                      text: "OBLIGACION HIPOTECARIA NUMERO:",
                      style: "textBold",
                    },
                    {
                      absolutePosition: { y: 124, x: 405 },
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 150,
                          h: 30,
                          color: "#DCDDDF",
                        },
                      ],
                    },
                    {
                      lineHeight: 2,
                      text: auxThis.state.financialCode
                        ? auxThis.state.financialCode
                        : auxThis.state.id,
                      style: "textBold",
                      alignment: "right ",
                      absolutePosition: { y: 134, x: 460 },
                    },
                    {
                      margin: [40, 0, 0, 5],
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 515,
                          y2: 0,
                          lineWidth: 3,
                          lineColor: "red",
                        },
                      ],
                    },
                    {
                      margin: [40, 10, 40, 0],

                      table: {
                        body: [
                          [
                            {
                              fillColor: "#DCDDDF",
                              border: [false, false, false, false],
                              columns: [
                                {
                                  width: 250,
                                  stack: [
                                    {
                                      lineHeight: 1.5,
                                      text: "APRECIADO CLIENTE",
                                      style: "text",
                                    },
                                    {
                                      lineHeight: 1.5,
                                      text: auxThis.state.clientesData[0]
                                        .nombre,
                                      style: "textBold",
                                    },
                                    {
                                      lineHeight: 1.5,
                                      text: auxThis.state.clientesData[0]
                                        .direccion,
                                      style: "text",
                                    },
                                  ],
                                },
                                {
                                  width: "auto",
                                  columns: [
                                    {
                                      stack: [
                                        {
                                          lineHeight: 1,
                                          text: "Valor del crédito:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Fecha de desembolso:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Tasa de interés:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Plazo:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Sistema de Amortización:",
                                          style: "textBold",
                                        },
                                      ],
                                    },
                                    {
                                      stack: [
                                        {
                                          lineHeight: 1,
                                          text: "$" + auxThis.state.vlrInicial,
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: fechaDesembolsoReal,
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text:
                                            auxThis.state.interes +
                                            " % " +
                                            "E.A.",
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: auxThis.state.plazo + " meses",
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text:
                                            auxThis.state.amortizacion +
                                            " en " +
                                            auxThis.state.moneda,
                                          style: "text",
                                        },
                                      ],
                                    },
                                  ],
                                  columnGap: 10,
                                },
                              ],
                              columnGap: 30,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      margin: [40, 10, 40, 0],
                      table: {
                        widths: ["*"],
                        body: [
                          [
                            {
                              fontSize: 10,
                              fillColor: "#DCDDDF",
                              border: [false, false, false, false],
                              alignment: "center",
                              margin: [0, 7, 0, 7],
                              columns: [
                                {
                                  text: "PAGOS RECIBIDOS EN ESTE PERIODO",
                                  bold: true,
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    },
                  ];
                },
                footer: function () {
                  return [
                    {
                      absolutePosition: { y: 60, x: 0 },
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 700,
                          h: 78,
                          color: "#DCDDDF",
                        },
                      ],
                    },
                    {
                      lineHeight: 2,
                      text: "FORMA DE PAGO",
                      style: "textBold",
                      alignment: "center",
                      absolutePosition: { x: 0, y: 72 },
                    },
                    {
                      absolutePosition: { x: 20, y: 93 },
                      margin: [40, 0, 0, 40],
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 515,
                          y2: 0,
                          lineWidth: 3,
                          lineColor: "red",
                        },
                      ],
                    },
                    {
                      absolutePosition: { y: 102, x: 0 },
                      lineHeight: 1.2,
                      text: "Su pago lo puede efectuar en el Banco Caja Social Cuenta convenio No. 11542 \na nombre de Inmobiliaria Mobilia S.A. En cualquier ciudad del país",
                      style: "textBold",
                      alignment: "center",
                    },
                    {
                      width: 600,
                      image: footer,
                      alignment: "center",
                      absolutePosition: { y: 137, x: 0 },
                    },
                  ];
                },
                content: [
                  liqTables,
                  {
                    margin: [0, 10, 0, 0],
                    text: "Resultado del periodo:",
                    style: "text",
                  },
                  auxTableRes,
                  {
                    margin: [0, 15, 0, 0],
                    table: {
                      widths: ["*"],
                      body: [
                        [
                          {
                            fontSize: 10,
                            fillColor: "#DCDDDF",
                            border: [false, false, false, false],
                            alignment: "center",
                            margin: [0, 7, 0, 5],
                            columns: [
                              {
                                lineHeight: 1.5,
                                text: textoMora,
                                bold: false,
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                ],
                styles: {
                  subtitle: {
                    fontSize: 10,
                    bold: true,
                  },
                  text: {
                    fontSize: 10,
                    bold: false,
                  },
                  textBoldTable: {
                    fontSize: 9,
                    bold: true,
                  },
                  headerPago: {
                    fontSize: 10,
                    bold: false,
                  },
                  textBold: {
                    fontSize: 10,
                    bold: true,
                  },
                  tableHeader: {
                    fontSize: 8,
                    bold: true,
                  },
                  tableHeaderColor: {
                    fontSize: 8,
                    bold: true,
                    color: "white",
                    fillColor: "#e92128",
                  },
                  redText: {
                    fontSize: 10,
                    bold: true,
                    color: "#e92128",
                  },
                  tableExample: {
                    fontSize: 8,
                    color: "black",
                  },
                  tableExample2: {
                    fontSize: 9,
                    color: "black",
                  },

                  title1: {
                    fontSize: 15,
                    color: "white",
                  },
                  title2: {
                    fontSize: 20,
                    bold: true,
                    color: "white",
                  },
                },
              };

              pdfMake.createPdf(docDefinition).download("prueba.pdf");
              if (window.sessionStorage.getItem("initialPDF") == "true") {
                window.sessionStorage.setItem("initialPDF", "false");
                this.delPago();
              }
            } else {
              document.getElementById("warnalert").innerHTML =
                "No existen valores UVR para el periodo seleccionado";
              $("#warnalert").show();
              $("#warnalert").fadeOut(7000);
            }
          });
      });
  };

  formatter = (e) => {
    if (e.target.value != "" || isNaN(e.target.value)) {
      e.target.value = parseFloat(e.target.value.replace(/,/g, ""))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  changeHandlerProy = (e) => {
    let changeIndex = 1;

    if (this.state.proyeccion[0].noCuota == 0) {
      changeIndex = 0;
    }

    if (e.target.value != "") {
      e.target.value = parseFloat(e.target.value.replace(/,/g, ""))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    var proyeccionAuxBack = this.state.proyeccion;
    var proyeccionAuxD = this.state.proyeccionData;
    var local = proyeccionAuxD[e.target.name - changeIndex];
    if (e.target.id == "segVida") {
      local.segVida = e.target.value;
      proyeccionAuxBack[e.target.name - changeIndex].segVida = e.target.value;
    } else if (e.target.id == "segTodoRiesgo") {
      local.segTodoRiesgo = e.target.value;
      proyeccionAuxBack[e.target.name - changeIndex].segTodoRiesgo =
        e.target.value;
    }
    proyeccionAuxD[e.target.name - changeIndex] = local;
    this.setState({
      proyeccionData: proyeccionAuxD,
      proyeccion: proyeccionAuxBack,
    });
  };

  handleChangeAprobacion = (date) => {
    this.setState({
      fechaAprobacion: date,
    });
  };

  handleChangeExpiryDate = (date) => {
    this.setState({
      expiryDate: this.fixTimeZone(date),
    });
  };

  handleChangeMortgageDate = (date) => {
    this.setState({
      mortgageDate: this.fixTimeZone(date),
    });
  };

  handleChangeFechaDesembolsoReal = (date) => {
    this.setState({
      fechaDesembolso: this.fixTimeZone(date),
      fechaDesembolsoReal: this.fixTimeZone(date),
    });
  };

  delPago = (e) => {
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));

    if (this.state.transacciones.length == 1) {
      var auxTx = [];
      var lastTx =
        this.state.transacciones[this.state.transacciones.length - 1];
      var demoAuxTx = [];
      var auxCorte = new Date(this.state.fechaDesembolso);
      auxCorte.setMonth(auxCorte.getMonth() + 1);
      var auxProxCorte = new Date(auxCorte);
      var localTx = {
        fechaTx: this.state.fechaDesembolso,
        dias: 0,
        fechaProxCorte: auxProxCorte,
        cuota: 0,
        vlrCapProyectado: lastTx.vlrCapProyectado,
        vlrCapProyectadoUVR: lastTx.vlrCapProyectadoUVR,
      };
      var auxLiq =
        this.state.liquidaciones[this.state.liquidaciones.length - 2];

      var delLiq = this.state.liquidaciones;
      var localProy = this.state.proyeccion;
      delLiq.splice(delLiq.length - 1, 1);

      if (
        this.state.pagosPorCreditos[
        this.state.auxTransacciones[this.state.auxTransacciones.length - 1]
          .cuota
        ] == 1
      ) {
        localProy[
          this.state.auxTransacciones[
            this.state.auxTransacciones.length - 1
          ].cuota
        ].presentaPagos = false;
        localProy[
          this.state.auxTransacciones[
            this.state.auxTransacciones.length - 1
          ].cuota
        ].liquidado = false;
      }

      for (let i = 0; i < this.state.proyeccion.length; i++) {
        if (this.state.proyeccionData[i].noCuota == localTx.cuota) {
          cntPagosPerCuota++;
        }
      }
      if (cntPagosPerCuota > 0) {
        localProy.presentaPagos = false;
        localProy.liquidado = false;
      }
    } else {
      var auxTx = this.state.transacciones;
      var auxTxSave = auxTx[auxTx.length - 1];
      auxTx.splice(auxTx.length - 1, 1);

      var demoAuxTx = auxTx;
      var localTx = auxTx[auxTx.length - 1];
      var auxLiq =
        this.state.liquidaciones[this.state.liquidaciones.length - 2];
      var delLiq = this.state.liquidaciones;
      var localProy = this.state.proyeccion;
      delLiq.splice(delLiq.length - 1, 1);

      if (
        this.state.pagosPorCreditos[
        this.state.auxTransacciones[this.state.auxTransacciones.length - 1]
          .cuota
        ] == 1
      ) {
        localProy[
          this.state.auxTransacciones[
            this.state.auxTransacciones.length - 1
          ].cuota
        ].presentaPagos = false;
        localProy[
          this.state.auxTransacciones[
            this.state.auxTransacciones.length - 1
          ].cuota
        ].liquidado = false;
      }
      var cntPagosPerCuota = 0;
      for (let i = 0; i < this.state.proyeccion.length; i++) {
        if (this.state.proyeccionData[i].noCuota == auxTxSave.cuota) {
          cntPagosPerCuota++;
        }
      }
      if (cntPagosPerCuota > 0) {
        localProy[auxTxSave.cuota].presentaPagos = false;
        localProy[auxTxSave.cuota].liquidado = false;
      }
    }

    axios
      .patch(
        apiMethods.PATCH_CREDITO_ID + this.state.id,
        {
          vlrCapital: auxLiq.capital,
          fechaPagoReciente: localTx.fechaTx,
          transacciones: demoAuxTx,
          vlrInteres: auxLiq.interesCorriente,
          vlrInteresMora: auxLiq.interesMoratorio,
          diasAcumulados: localTx.dias,
          fechaProxCorte: localTx.fechaProxCorte,
          liquidaciones: delLiq,
          vlrCapProyectado: localTx.vlrCapProyectado,
          cuota: localTx.cuota + 1,
        },
        {
          headers: {
            Authorization: actUsrToken,
          },
        }
      )
      .then(async (response) => {

        let proyResponse = await axios.patch(apiMethods.PATCH_CREDITO_ID + this.state.id, {proyeccion: localProy})

        axios
          .get(apiMethods.GET_USUARIO_ID + decodedPayload.usrId, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            var localTxUsr = response.data.users.transacciones;
            localTxUsr.pop();
            try {
              //await axios.patch(apiMethods.PATCH_USUARIO_ID + decodedPayload.usrId, { transacciones: localTxUsr, }, { headers: { Authorization: actUsrToken } })
              if (this.state.moneda == "uvr") {
                axios
                  .patch(
                    apiMethods.PATCH_CREDITO_ID + this.state.id,
                    {
                      vlrCapitalUVR: auxLiq.capitalUVR,
                      vlrInteresUVR: auxLiq.interesCorrienteUVR,
                      vlrInteresMoraUVR: auxLiq.interesMoratorioUVR,
                      vlrCapProyectadoUVR: localTx.vlrCapProyectadoUVR,
                    },
                    {
                      headers: {
                        Authorization: actUsrToken,
                      },
                    }
                  )
                  .then((response) => {
                    window.location.reload();
                  });
              } else {
                window.location.reload();
              }
            } catch (error) {
              console.error(error);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  renderTableDataTransacciones() {
    return this.state.transaccionesData.map((transaccionesData, index) => {
      const { id, vlrTx, fechaTx } = transaccionesData;
      if (id == this.state.transaccionesData.length - 1) {
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{vlrTx}</td>
            <td>{fechaTx}</td>
            <td>
              <Button color="primary" disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true} onClick={this.handleShowDeletePayment}>
                Eliminar
              </Button>
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{vlrTx}</td>
            <td>{fechaTx}</td>
            <td></td>
          </tr>
        );
      }
    });
  }

  renderTableHeaderTransacciones() {
    let header = ["ID", "Monto", "Fecha Pago", "Acción"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableDataTransaccionesLess() {
    return this.state.transaccionesData.map((transaccionesData, index) => {
      const { id, vlrTx, fechaTx } = transaccionesData;
      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{vlrTx}</td>
          <td>{fechaTx}</td>
        </tr>
      );
    });
  }

  renderTableHeaderTransaccionesLess() {
    let header = ["ID", "Monto", "Fecha Pago"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableDataClientes() {
    return this.state.clientesData.map((clientesData, index) => {
      const { tipoDocumento, nombre, documento } = clientesData;
      return (
        <tr key={documento}>
          <td>{tipoDocumento}</td>
          <td>{nombre}</td>
          <td>{documento}</td>
          <td>
            <Button color="primary" disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true} variant="contained">
              Eliminar
            </Button>
          </td>
        </tr>
      );
    });
  }

  renderTableHeaderClientes() {
    let header = ["Tipo Documento", "Nombre", "Documento", "Accion"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableDataClientesLess() {
    return this.state.clientesData.map((clientesData, index) => {
      const { tipoDocumento, nombre, documento } = clientesData;
      return (
        <tr key={documento}>
          <td>{tipoDocumento}</td>
          <td>{nombre}</td>
          <td>{documento}</td>
        </tr>
      );
    });
  }

  renderTableHeaderClientesLess() {
    let header = ["Tipo Documento", "Nombre", "Documento"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  auxDetail = (e) => {
    e.preventDefault();
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
      target = target.parentNode;
    }
    if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
        data.push(cells[i].innerHTML);
      }
    }
    const hiddenElement = target.nextSibling;
    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    hiddenElement.className.indexOf("collapse show") > -1
      ? hiddenElement.classList.remove("show")
      : hiddenElement.classList.add("show");
    var indexOf =
      this.state.liquidacionesData.findIndex(
        (i) => i.periodo === target.cells[0].childNodes[0].data
      ) - 1;
    var lookForPago = indexOf;

    while (this.state.transaccionesData[lookForPago] == 0) {
      lookForPago--;
    }

    var cntPagosCuotas = 0;

    var cntCuotaPaga = 0;

    var sumExtra = 0;

    for (let i = 0; i < indexOf; i++) {
      if (this.state.pagosPorCreditos[i] != 0) {
        cntCuotaPaga = cntCuotaPaga + this.state.pagosPorCreditos[i];
      }
    }
    for (let i = 0; i < indexOf; i++) {
      if (this.state.pagosPorCreditos[i] > 1) {
        sumExtra = sumExtra + this.state.pagosPorCreditos[i];
        sumExtra--;
      }
    }

    for (let i = 0; i < indexOf; i++) {
      if (this.state.pagosPorCreditos[i] != 0) {
        cntPagosCuotas++;
      }
    }

    var cntLapMora = 0;

    var paga = false;

    for (let i = 0; i <= indexOf; i++) {
      if (this.state.pagosPorCreditos[i] == 0) {
        paga = false;
      } else {
        if (paga == false) {
          cntLapMora++;
        }
        paga = true;
      }
    }

    var cntPagos = this.state.pagosPorCreditos[lookForPago];
    var cntPago = 0;
    var cntPagData = cntPagos - 1;
    var auxCuota = indexOf - cntPagData;
    var auxLap = indexOf - cntLapMora;
    var btnRefIndex =
      e.currentTarget.id -
      1 +
      (this.state.pagosPorCreditos[indexOf] - 1) +
      sumExtra -
      1000;

    if (cntPagos > 1) {
      for (let i = 0; i < cntPagos * 2; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          this.state.transaccionesData[btnRefIndex - cntPagData].vlrTx +
          " el " +
          this.state.transaccionesData[btnRefIndex - cntPagData].fechaTx;
        cntPago++;
        cntPagData--;
      }
    } else {
      for (let i = 0; i < 1; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          this.state.transaccionesData[cntCuotaPaga].vlrTx +
          " el " +
          this.state.transaccionesData[cntCuotaPaga].fechaTx;
        cntPago++;
        cntPagData++;
      }
    }

    var tables = [];
    cntPagData = cntPagos - 1;

    var dimensions = [];

    for (let i = 2; i < target.cells.length; i++) {
      dimensions.push(target.cells[i].offsetWidth);
    }

    var auxDimensions =
      target.cells[0].offsetWidth + target.cells[1].offsetWidth;

    for (let h = 0; h < this.state.pagosPorCreditos[indexOf]; h++) {
      var HTML = "<table border=1 width=100%>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Saldo Inicial" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j - 1] +
          "px>" +
          this.state.detalleData[btnRefIndex - cntPagData][0][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Movimientos" + "</td>";
      HTML += "<td></td>";
      for (let j = 1; j <= 4; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j] +
          "px>" +
          this.state.detalleData[btnRefIndex - cntPagData][1][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Aplicación del pago" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          this.state.detalleData[btnRefIndex - cntPagData][2][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Nuevo Saldo" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          this.state.detalleData[btnRefIndex - cntPagData][3][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "</table>";
      tables.push(HTML);
      cntPagData--;
    }

    if (this.state.pagosPorCreditos[indexOf] > 1) {
      for (let p = 1; p < this.state.pagosPorCreditos[indexOf]; p++) {
        var auxString = tables[p];
        var part1 = auxString.substring(0, auxString.indexOf("<tr>"));
        var part2 = auxString.substring(
          auxString.indexOf("</tr>"),
          auxString.length
        );
        tables[p] = part1 + part2;
      }
    }

    let b = btnRefIndex + 1;

    var HTML = "<table border=1 width=100%>";
    HTML += "<tr>";
    HTML += "<td>" + "Movimientos" + "</td>";
    HTML += "<td></td>";
    for (let j = 1; j <= 4; j++) {
      HTML += "<td>" + this.state.resPeriodo[b - 1][j] + "</td>";
    }
    HTML += "</tr>";
    HTML += "<tr>";
    HTML +=
      "<td style=width:" + auxDimensions + "px>" + "Nuevo Saldo" + "</td>";
    for (let j = 0; j <= 4; j++) {
      HTML +=
        "<td style=width:" +
        dimensions[j] +
        "px>" +
        this.state.resPeriodo[b - 1][j] +
        "</td>";
    }
    HTML += "</tr>";
    HTML += "</table>";
    tables.push(HTML);

    var tableIndex = 0;
    let p;

    for (p = 1; p < cntPagos * 2; p += 2) {
      target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
        tables[tableIndex];
      cntPago++;
      tableIndex++;
    }

    target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
      tables[tables.length - 1];

    if (
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML == "-"
    ) {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "+";
    } else {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "-";
    }
  };

  fixTimeZone(originalDate) {
    if (originalDate) {
      let dateString = originalDate.toISOString();
      if (dateString.charAt(12) != 0 || dateString.charAt(11) != 5) {
        dateString = this.setCharAt(dateString, 12, "5");
        dateString = this.setCharAt(dateString, 11, "0");
      }
      return new Date(dateString);
    }
  }

  auxDetailUVR = (e) => {
    e.preventDefault();
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
      target = target.parentNode;
    }
    if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
        data.push(cells[i].innerHTML);
      }
    }
    const formatter = new Intl.NumberFormat("en-US", {
      currency: "USD",
    });
    const hiddenElement = target.nextSibling;
    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    hiddenElement.className.indexOf("collapse show") > -1
      ? hiddenElement.classList.remove("show")
      : hiddenElement.classList.add("show");
    var indexOf =
      this.state.liquidacionesData.findIndex(
        (i) => i.periodo === target.cells[0].childNodes[0].data
      ) - 1;
    var lookForPago = indexOf;

    while (this.state.transaccionesData[lookForPago] == 0) {
      lookForPago--;
    }

    var cntPagosCuotas = 0;

    var cntCuotaPaga = 0;

    var sumExtra = 0;

    for (let i = 0; i < indexOf; i++) {
      if (this.state.pagosPorCreditos[i] != 0) {
        cntCuotaPaga = cntCuotaPaga + this.state.pagosPorCreditos[i];
      }
    }
    for (let i = 0; i < indexOf; i++) {
      if (this.state.pagosPorCreditos[i] > 1) {
        sumExtra = sumExtra + this.state.pagosPorCreditos[i];
        sumExtra--;
      }
    }

    for (let i = 0; i < indexOf; i++) {
      if (this.state.pagosPorCreditos[i] != 0) {
        cntPagosCuotas++;
      }
    }

    var cntLapMora = 0;

    var paga = false;

    for (let i = 0; i <= indexOf; i++) {
      if (this.state.pagosPorCreditos[i] == 0) {
        paga = false;
      } else {
        if (paga == false) {
          cntLapMora++;
        }
        paga = true;
      }
    }

    var cntPagos = this.state.pagosPorCreditos[lookForPago];
    var cntPago = 0;
    var cntPagData = cntPagos - 1;
    var auxCuota = indexOf - cntPagData;
    var auxLap = indexOf - cntLapMora;
    var btnRefIndex =
      e.currentTarget.id -
      1 +
      (this.state.pagosPorCreditos[indexOf] - 1) +
      sumExtra -
      2000;
    if (cntPagos > 1) {
      for (let i = 0; i < cntPagos * 2; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          formatter.format(
            this.toFixed(
              this.state.transaccionesData[btnRefIndex - cntPagData].vlrTxUVR,
              2
            )
          ) +
          " UVR (" +
          this.state.transaccionesData[btnRefIndex - cntPagData].vlrTx +
          ") el " +
          this.state.transaccionesData[btnRefIndex - cntPagData].fechaTx;
        cntPago++;
        cntPagData--;
      }
    } else {
      for (let i = 0; i < 1; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          formatter.format(
            this.toFixed(this.state.transaccionesData[cntCuotaPaga].vlrTxUVR, 2)
          ) +
          " UVR (" +
          this.state.transaccionesData[btnRefIndex - cntPagData].vlrTx +
          ") el " +
          this.state.transaccionesData[cntCuotaPaga].fechaTx;
        cntPago++;
        cntPagData++;
      }
    }

    var tables = [];
    cntPagData = cntPagos - 1;

    var dimensions = [];

    for (let i = 2; i < target.cells.length; i++) {
      dimensions.push(target.cells[i].offsetWidth);
    }

    var auxDimensions =
      target.cells[0].offsetWidth + target.cells[1].offsetWidth;

    for (let h = 0; h < this.state.pagosPorCreditos[indexOf]; h++) {
      var HTML = "<table border=1 width=100%>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Saldo Inicial" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j - 1] +
          "px>" +
          this.state.detalleDataUVR[btnRefIndex - cntPagData][0][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          this.state.detalleData[btnRefIndex - cntPagData][0][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Movimientos" + "</td>";
      HTML += "<td></td>";
      for (let j = 1; j <= 4; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j] +
          "px>" +
          this.state.detalleDataUVR[btnRefIndex - cntPagData][1][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          this.state.detalleData[btnRefIndex - cntPagData][1][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Aplicación del pago" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          this.state.detalleDataUVR[btnRefIndex - cntPagData][2][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          this.state.detalleData[btnRefIndex - cntPagData][2][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Nuevo Saldo" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          this.state.detalleDataUVR[btnRefIndex - cntPagData][3][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          this.state.detalleData[btnRefIndex - cntPagData][3][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "</table>";
      tables.push(HTML);
      cntPagData--;
    }

    if (this.state.pagosPorCreditos[indexOf] > 1) {
      for (let p = 1; p < this.state.pagosPorCreditos[indexOf]; p++) {
        var auxString = tables[p];
        var part1 = auxString.substring(0, auxString.indexOf("<tr>"));
        var part2 = auxString.substring(
          auxString.indexOf("</tr>"),
          auxString.length
        );
        tables[p] = part1 + part2;
      }
    }

    let b = btnRefIndex + 1;

    var HTML = "<table border=1 width=100%>";
    HTML += "<tr>";
    HTML += "<td>" + "Movimientos" + "</td>";
    HTML += "<td></td>";
    for (let j = 1; j <= 4; j++) {
      HTML +=
        "<td>" +
        this.state.resPeriodoUVR[b - 1][j] +
        " UVR" +
        "<p style='color: #0059e6 !important'> ($" +
        this.state.resPeriodo[b - 1][j] +
        ")</p> </td>";
    }
    HTML += "</tr>";
    HTML += "<tr>";
    HTML +=
      "<td style=width:" + auxDimensions + "px>" + "Nuevo Saldo" + "</td>";
    for (let j = 0; j <= 4; j++) {
      HTML +=
        "<td style=width:" +
        dimensions[j] +
        "px>" +
        this.state.resPeriodoUVR[b - 1][j] +
        " UVR" +
        "<p style='color: #0059e6 !important'> ($" +
        this.state.resPeriodo[b - 1][j] +
        ")</p> </td>";
    }
    HTML += "</tr>";
    HTML += "</table>";
    tables.push(HTML);

    var tableIndex = 0;
    let p;

    for (p = 1; p < cntPagos * 2; p += 2) {
      target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
        tables[tableIndex];
      cntPago++;
      tableIndex++;
    }

    target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
      tables[tables.length - 1];

    if (
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML == "-"
    ) {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "+";
    } else {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "-";
    }
  };

  renderTableDataLiquidaciones() {
    return this.state.liquidacionesData.map((liquidacionesData, index) => {
      const {
        id,
        periodo,
        pagosTotal,
        capital,
        interesCorriente,
        interesMoratorio,
        segVida,
        segTodoRiesgo,
      } = liquidacionesData;
      if (index == 0 || this.state.pagosPorCreditos[index - 1] == 0) {
        return (
          <tr key={id}>
            <td>{periodo}</td>
            <td>{pagosTotal}</td>
            <td>{capital}</td>
            <td>{interesCorriente}</td>
            <td>{interesMoratorio}</td>
            <td>{segVida}</td>
            <td>{segTodoRiesgo}</td>
          </tr>
        );
      } else {
        return (
          <React.Fragment>
            <tr key={id}>
              <td>
                {periodo}{" "}
                <Button
                  variant="contained"
                  size="small"
                  style={{ maxWidth: "15px", maxHeight: "20px", minWidth: "15px", minHeight: "20px" }}
                  id={this.state.iteradorPagos[index - 1] + 1000}
                  onClick={this.auxDetail}
                >
                  +
                </Button>
              </td>
              <td>{pagosTotal}</td>
              <td>{capital}</td>
              <td>{interesCorriente}</td>
              <td>{interesMoratorio}</td>
              <td>{segVida}</td>
              <td>{segTodoRiesgo}</td>
            </tr>
            <tr className="collapse">
              <td colSpan="7">
                <div className="formTitleDetail">
                  {new Array(this.state.pagosPorCreditos[index - 1]).fill(
                    <React.Fragment>
                      <Form.Label id={index}></Form.Label>
                      <table id="table">
                        <tbody></tbody>
                      </table>
                    </React.Fragment>
                  )}
                  <Form.Label>Resultado del periodo</Form.Label>
                  <table id="table">
                    <tbody></tbody>
                  </table>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      }
    });
  }

  renderTableDataLiquidacionesUVR() {
    return this.state.liquidacionesDataUVR.map(
      (liquidacionesDataUVR, index) => {
        const {
          id,
          periodo,
          pagosTotal,
          capital,
          interesCorriente,
          interesMoratorio,
          segVida,
          segTodoRiesgo,
        } = liquidacionesDataUVR;
        if (index == 0 || this.state.pagosPorCreditos[index - 1] == 0) {
          return (
            <tr key={id}>
              <td>{periodo}</td>
              <td>
                {pagosTotal +
                  " UVR" +
                  " ($" +
                  this.state.liquidacionesData[index].pagosTotal +
                  ")"}
              </td>
              <td>
                {capital +
                  " UVR" +
                  " ($" +
                  this.state.liquidacionesData[index].capital +
                  ")"}
              </td>
              <td>
                {interesCorriente +
                  " UVR" +
                  " ($" +
                  this.state.liquidacionesData[index].interesCorriente +
                  ")"}
              </td>
              <td>
                {interesMoratorio +
                  " UVR" +
                  " ($" +
                  this.state.liquidacionesData[index].interesMoratorio +
                  ")"}
              </td>
              <td>
                {segVida +
                  " UVR" +
                  " ($" +
                  this.state.liquidacionesData[index].segVida +
                  ")"}
              </td>
              <td>
                {segTodoRiesgo +
                  " UVR" +
                  " ($" +
                  this.state.liquidacionesData[index].segTodoRiesgo +
                  ")"}
              </td>
            </tr>
          );
        } else {
          return (
            <React.Fragment>
              <tr key={id}>
                <td>
                  {periodo}{" "}
                  <Button
                    variant="contained"
                    size="small"
                    style={{ maxWidth: "15px", maxHeight: "20px", minWidth: "15px", minHeight: "20px" }}
                    id={this.state.iteradorPagos[index - 1] + 2000}
                    onClick={this.auxDetailUVR}
                  >
                    +
                  </Button>
                </td>
                <td>
                  <p>{pagosTotal + " UVR"}</p>
                  <p style={{ color: "#0059e6" }}>
                    {"($"}
                    {this.state.liquidacionesData[index].pagosTotal}
                    {")"}
                  </p>
                </td>
                <td>
                  <p>{capital + " UVR"}</p>
                  <p style={{ color: "#0059e6" }}>
                    {"($"}
                    {this.state.liquidacionesData[index].capital}
                    {")"}
                  </p>
                </td>
                <td>
                  <p>{interesCorriente + " UVR"}</p>
                  <p style={{ color: "#0059e6" }}>
                    {"($"}
                    {this.state.liquidacionesData[index].interesCorriente}
                    {")"}
                  </p>
                </td>
                <td>
                  <p>{interesMoratorio + " UVR"}</p>
                  <p style={{ color: "#0059e6" }}>
                    {"($"}
                    {this.state.liquidacionesData[index].interesMoratorio}
                    {")"}
                  </p>
                </td>
                <td>
                  <p>{segVida + " UVR"}</p>
                  <p style={{ color: "#0059e6" }}>
                    {"($"}
                    {this.state.liquidacionesData[index].segVida}
                    {")"}
                  </p>
                </td>
                <td>
                  <p>{segTodoRiesgo + " UVR"}</p>
                  <p style={{ color: "#0059e6" }}>
                    {"($"}
                    {this.state.liquidacionesData[index].segTodoRiesgo}
                    {")"}
                  </p>
                </td>
              </tr>
              <tr className="collapse">
                <td colSpan="7">
                  <div className="formTitleDetail">
                    {new Array(this.state.pagosPorCreditos[index - 1]).fill(
                      <React.Fragment>
                        <Form.Label id={index}></Form.Label>
                        <table id="table">
                          <tbody></tbody>
                        </table>
                      </React.Fragment>
                    )}
                    <Form.Label>Resultado del periodo</Form.Label>
                    <table id="table">
                      <tbody></tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          );
        }
      }
    );
  }

  renderTableHeaderLiquidaciones() {
    let header = [
      "Periodo",
      "Pagos realizados en el periodo",
      "Capital",
      "Interes Corriente",
      "Interés Moratorio",
      "Seguro de Vida",
      "Seguro de incendio y todo riesgo",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableDataProyeccion() {
    return this.state.proyeccionData.map((proyeccionData, index) => {
      const {
        id,
        noCuota,
        periodo,
        capital,
        interes,
        vlrCuota,
        segVida,
        segTodoRiesgo,
      } = proyeccionData;
      return (
        <tr key={noCuota}>
          <td>
            {this.state.proyeccion[0].noCuota == 0 ? noCuota + 1 : noCuota}
          </td>
          <td>{periodo}</td>
          <td>{capital}</td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segVida"
              value={segVida}
              onChange={this.changeHandlerProy}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segTodoRiesgo"
              value={segTodoRiesgo}
              onChange={this.changeHandlerProy}
            />
          </td>
        </tr>
      );
    });
  }

  renderTableDataProyeccionUVR() {
    return this.state.proyeccionData.map((proyeccionData, index) => {
      const {
        id,
        noCuota,
        periodo,
        vlrCapitalUVR,
        vlrInteresUVR,
        vlrCuotaUVR,
        segVida,
        segTodoRiesgo,
      } = proyeccionData;
      return (
        <tr key={noCuota}>
          <td>
            {this.state.proyeccion[0].noCuota == 0 ? noCuota + 1 : noCuota}
          </td>
          <td>{periodo}</td>
          <td>{vlrCapitalUVR}</td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segVida"
              value={segVida}
              onChange={this.changeHandlerProy}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segTodoRiesgo"
              value={segTodoRiesgo}
              onChange={this.changeHandlerProy}
            />
          </td>
        </tr>
      );
    });
  }

  renderTableHeaderProyeccion() {
    let header = [
      "No. Cuota",
      "Periodo",
      "Capital",
      "Seguro de vida",
      "Seguro de incendio y todo riesgo",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableDataProyeccionLess() {
    return this.state.proyeccionData.map((proyeccionData, index) => {
      const {
        id,
        noCuota,
        periodo,
        capital,
        interes,
        vlrCuota,
        segVida,
        segTodoRiesgo,
      } = proyeccionData;
      return (
        <tr key={noCuota}>
          <td>
            {this.state.proyeccion[0].noCuota == 0 ? noCuota + 1 : noCuota}
          </td>
          <td>{periodo}</td>
          <td>{capital}</td>
          <td>
            <Form.Control
              type="text"
              disabled
              name={noCuota}
              id="segVida"
              value={segVida}
              onChange={this.changeHandlerProy}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              disabled
              name={noCuota}
              id="segTodoRiesgo"
              value={segTodoRiesgo}
              onChange={this.changeHandlerProy}
            />
          </td>
        </tr>
      );
    });
  }

  renderTableDataProyeccionUVRLess() {
    return this.state.proyeccionData.map((proyeccionData, index) => {
      const {
        id,
        noCuota,
        periodo,
        vlrCapitalUVR,
        vlrInteresUVR,
        vlrCuotaUVR,
        segVida,
        segTodoRiesgo,
      } = proyeccionData;
      return (
        <tr key={noCuota}>
          <td>
            {this.state.proyeccion[0].noCuota == 0 ? noCuota + 1 : noCuota}
          </td>
          <td>{periodo}</td>
          <td>{vlrCapitalUVR}</td>
          <td>
            <Form.Control
              type="text"
              disabled
              name={noCuota}
              id="segVida"
              value={segVida}
              onChange={this.changeHandlerProy}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              disabled
              name={noCuota}
              id="segTodoRiesgo"
              value={segTodoRiesgo}
              onChange={this.changeHandlerProy}
            />
          </td>
        </tr>
      );
    });
  }

  showProy = (e) => {
    e.preventDefault();
    if (this.state.proyState == 1) {
      document.getElementById("proyTable").style.display = "none";
      document.getElementById("showProy").innerHTML = "Mostrar Proyección";
      this.setState({ proyState: 0 });
    } else {
      document.getElementById("proyTable").style.display = "block";
      document.getElementById("showProy").innerHTML = "Ocultar Proyección";
      this.setState({ proyState: 1 });
    }
  };

  showPesosLiq = (e) => {
    document.getElementById("optionUVRLiq").style.display = "none";
    $("#optionPesosLiq").show();
  };

  showUVRLiq = (e) => {
    document.getElementById("optionUVRLiq").style.display = "block";
    $("#optionPesosLiq").hide();
  };

  showPesosProy = (e) => {
    document.getElementById("optionUVRProy").style.display = "none";
    $("#optionPesosProy").show();
  };

  showUVRProy = (e) => {
    document.getElementById("optionUVRProy").style.display = "block";
    $("#optionPesosProy").hide();
  };

  relativePayment = (e) => {
    e.preventDefault();
    if (this.state.initialPDF) {
      var periodo = new Date(this.createSelectItemsLiq()[0].props.id);
      window.sessionStorage.setItem("initialPDF", "true");
    } else {
      var de = document.getElementsByClassName("selectorLiq");
      var periodo = new Date(de.estado.selectedOptions[0].id);
    }
    this.setState(
      {
        fechaPago: periodo,
        auxFechaPago: periodo,
        emptyPayment: true,
      },
      function () {
        this.handleShow(e);
      }
    );
  };

  submitHandlerPagos = (e) => {
    e.preventDefault();
    var day = this.state.lastUVR.substring(0, 2);
    var month = this.state.lastUVR.substring(3, 5);
    var year = this.state.lastUVR.substring(8, 12);
    var auxDateParse = new Date(month + "/" + day + "/" + year);
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    var montoPago = document.getElementById("currency-fieldPago") ?
                    document.getElementById("currency-fieldPago").value:
                    '0';
    montoPago = parseInt(montoPago.replace(/,/g, ""));
    if (
      montoPago == null ||
      (montoPago == 0 && this.state.emptyPayment == false)
    ) {
      document.getElementById("warnalert").innerHTML = "Monto inválido";
      $("#warnalert").show();
      $("#warnalert").fadeOut(7000);
    } else if (
      this.state.moneda == "uvr" &&
      this.state.fechaPago > auxDateParse
    ) {
      document.getElementById("warnalert").innerHTML =
        "Valor UVR inexistente para fecha indicada";
      $("#warnalert").show();
      $("#warnalert").fadeOut(7000);
    } else {
      if (this.state.emptyPayment) {
        montoPago = 0;
      }
      let auxFechaPago = new Date(this.state.fechaPago);
      auxFechaPago.setHours(auxFechaPago.getHours() - 5);
      auxFechaPago = auxFechaPago
        .toISOString()
        .substring(0, 10)
        .replace(/-/g, "/");

      let body = {
        idCredito: this.state.id,
        montoPago: montoPago,
        fechaPago: auxFechaPago,
        userId: decodedPayload.usrId,
        save: true,
      };
      axios
        .post(apiMethods.CREATE_PAYMENT, body, {
          headers: {
            Authorization: actUsrToken,
          },
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          document.getElementById("warnalert").innerHTML = "Error al eliminar";
          $("#warnalert").show();
          $("#warnalert").fadeOut(7000);
        });
    }
  };

  toggleHover() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  addCuota = (e) => {
    e.preventDefault();
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;
    let search = window.sessionStorage.getItem("localDocument");
    axios
      .get(apiMethods.GET_CREDITO_ID + search, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var proyeccion = response.data.credits.proyeccion;
        var auxIni = new Date(proyeccion[proyeccion.length - 1].fechaFin);
        var auxFin = new Date(auxIni);
        auxFin.setMonth(auxFin.getMonth() + 1);
        var nuevaCuota = {
          liquidado: false,
          presentaPagos: false,
          fechaIni: auxIni,
          fechaFin: auxFin,
          noCuota: proyeccion.length + 1,
          capital: 0,
          interes: 0,
          vlrCuota: 0,
          segVida: 0,
          segTodoRiesgo: 0,
        };
        proyeccion.push(nuevaCuota);
        axios
          .patch(
            apiMethods.PATCH_CREDITO_ID + search,
            {
              proyeccion: proyeccion,
            },
            {
              headers: {
                Authorization: actUsrToken,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  submitHandler = (e) => {
    e.preventDefault();

    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;
    let search = window.sessionStorage.getItem("localDocument");

    var valid = true;

    for (let i = 0; i < this.state.proyeccion.length; i++) {
      var local = this.state.proyeccion[i];

      local.segVida = local.segVida.toString().replace(/,/g, "");
      local.segTodoRiesgo = local.segTodoRiesgo.toString().replace(/,/g, "");
      if (isNaN(local.segVida) || isNaN(local.segTodoRiesgo)) {
        valid = false;
        break;
      }
    }

    var plazoAux = document.getElementById("plazo").value;
    var auxInteres = this.state.interes;

    if (valid == true) {
      if (
        isNaN(plazoAux) ||
        plazoAux == "" ||
        plazoAux.includes(",") ||
        plazoAux.includes(".") ||
        parseInt(plazoAux, 10) < 6 ||
        parseInt(plazoAux, 10) > 250
      ) {
        document.getElementById("warnalert").innerHTML = "Plazo inválido";
        $("#warnalert").show();
        $("#warnalert").fadeOut(7000);
      } else if (
        isNaN(auxInteres) ||
        parseFloat(auxInteres, 10) < 1 ||
        auxInteres == ""
      ) {
        document.getElementById("warnalert").innerHTML =
          "Tasa de interés inválida";
        $("#warnalert").show();
        $("#warnalert").fadeOut(7000);
      } else if (
        this.state.estado == "aprobado" &&
        this.state.fechaDesembolso == null
      ) {
        document.getElementById("warnalert").innerHTML =
          "Fecha de desembolso inválida";
        $("#warnalert").show();
        $("#warnalert").fadeOut(7000);
      } else {
        this.setState({
          vlrInicial: parseInt(
            this.state.vlrInicial.toString().replace(/,/g, "")
          ),
          vlrCapital: parseInt(
            this.state.vlrCapital.toString().replace(/,/g, "")
          ),
          vlrInteres: parseInt(
            this.state.vlrInteres.toString().replace(/,/g, "")
          ),
          vlrInteresMora: parseInt(
            this.state.vlrInteresMora.toString().replace(/,/g, "")
          ),
          saldoSegVida: parseInt(
            this.state.saldoSegVida.toString().replace(/,/g, "")
          ),
          saldoSegTodoRiesgo: parseInt(
            this.state.saldoSegTodoRiesgo.toString().replace(/,/g, "")
          ),
        });

        if (this.state.estado == "noAprobado") {
          this.setState(
            {
              fechaAprobacion: null,
              fechaPagoReciente: null,
            },
            function () {
              axios
                .patch(apiMethods.PATCH_CREDITO_ID + search, this.state, {
                  headers: {
                    Authorization: actUsrToken,
                  },
                })
                .then((response) => {
                  window.sessionStorage.setItem("success", "true");
                  window.sessionStorage.setItem("type", "E");
                  this.props.history.push("/search/credits");
                })
                .catch((error) => {
                  console.error(error);
                  document.getElementById("warnalert").innerHTML =
                    "Error al actualizar";
                  $("#warnalert").show();
                  $("#warnalert").fadeOut(7000);
                });
            }
          );
        } else if (
          this.state.liquidaciones.length == 1 &&
          this.state.estado == "aprobado" &&
          (this.state.liquidaciones[0].interesCorriente != 0 ||
            this.state.liquidaciones[0].interesMoratorio != 0 ||
            this.state.liquidaciones[0].segVida != 0 ||
            this.state.liquidaciones[0].segTodoRiesgo != 0)
        ) {
          var interesAnual = this.state.interes;
          var interesMensualP = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
          var interesMensual = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
          interesMensual = interesMensual * 100;
          var pv = this.state.vlrInicial;
          var np = this.state.plazo;
          var fv = 0;
          var type = 0;
          var ir = interesMensualP;

          var pmt, pvif;
          fv || (fv = 0);
          type || (type = 0);

          if (ir === 0) return -(pv + fv) / np;

          pvif = Math.pow(1 + ir, np);

          pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

          var proyeccion = [];
          var cap = pv;
          var auxInit = new Date(this.state.fechaInicial);

          for (let i = 0; i < np; i++) {
            var noCuota = i + 1;
            var int = Math.round(cap * interesMensualP);
            var fechaIni = new Date(auxInit);
            auxInit.setMonth(auxInit.getMonth() + 1);
            var fechaFin = new Date(auxInit);
            var corte = {
              liquidado: false,
              presentaPagos: false,
              fechaIni: fechaIni,
              fechaFin: fechaFin,
              noCuota: noCuota,
              capital: cap,
              interes: int,
              vlrCuota: Math.round(pmt),
              segVida: 0,
              segTodoRiesgo: 0,
              vlrCuotaUVR: 0,
              vlrInteresUVR: 0,
              vlrCapitalUVR: 0,
            };
            cap = Math.round(cap - (pmt - int));
            proyeccion.push(corte);
          }
          this.setState(
            {
              proyeccion: proyeccion,
            },
            function () {
              axios
                .patch(
                  apiMethods.PATCH_CREDITO_ID + search,
                  { proyeccion: proyeccion },
                  {
                    headers: {
                      Authorization: actUsrToken,
                    },
                  }
                )
                .then((response) => {
                  window.sessionStorage.setItem("success", "true");
                  window.sessionStorage.setItem("type", "E");
                  this.props.history.push("/search/credits");
                })
                .catch((error) => {
                  console.error(error);
                  document.getElementById("warnalert").innerHTML =
                    "Error al actualizar";
                  $("#warnalert").show();
                  $("#warnalert").fadeOut(7000);
                });
            }
          );
        } else if (this.state.estado == "aprobado") {
          var auxFecha = new Date(this.state.fechaDesembolso);
          auxFecha.setMonth(auxFecha.getMonth() + 1);

          axios
            .get(apiMethods.GET_CREDITO_ID + search, {
              headers: {
                Authorization: actUsrToken,
              },
            })
            .then((response) => {
              axios
                .patch(
                  apiMethods.PATCH_CREDITO_ID + search,
                  { pasoEdicion: response.data.credits.pasoEdicion + 1 },
                  {
                    headers: {
                      Authorization: actUsrToken,
                    },
                  }
                )
                .then((responseEdit) => {
                  var auxPlazo = response.data.credits.plazo;
                  var auxMonto = response.data.credits.vlrInicial;
                  var auxInt = response.data.credits.interes;
                  var auxMontoLocal = parseInt(
                    this.state.vlrInicial.toString().replace(/,/g, "")
                  );

                  if (this.state.moneda == "pesos") {
                    if (
                      auxInt != this.state.interes ||
                      auxPlazo != this.state.plazo ||
                      auxMonto != auxMontoLocal
                    ) {
                      var interesAnual = this.state.interes;
                      var interesMensualP =
                        Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                      var interesMensual =
                        Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                      interesMensual = interesMensual * 100;
                      var pv = this.state.vlrInicial;
                      var np = this.state.plazo;
                      var fv = 0;
                      var type = 0;
                      var ir = interesMensualP;

                      var pmt, pvif;
                      fv || (fv = 0);
                      type || (type = 0);

                      if (ir === 0) return -(pv + fv) / np;

                      pvif = Math.pow(1 + ir, np);

                      pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

                      var proyeccion = [];
                      var cap = pv;

                      if (
                        this.state.liquidaciones[0].interesCorriente != 0 ||
                        this.state.liquidaciones[0].interesMoratorio != 0 ||
                        this.state.liquidaciones[0].segVida != 0 ||
                        this.state.liquidaciones[0].segTodoRiesgo != 0
                      ) {
                        var auxInit = new Date(this.state.fechaInicial);
                      } else {
                        var auxInit = new Date(this.state.fechaDesembolso);
                      }

                      for (let i = 0; i < np; i++) {
                        var noCuota = i + 1;
                        var int = Math.round(cap * interesMensualP);
                        var fechaIni = new Date(auxInit);
                        auxInit.setMonth(auxInit.getMonth() + 1);
                        var fechaFin = new Date(auxInit);
                        var corte = {
                          liquidado: false,
                          presentaPagos: false,
                          fechaIni: fechaIni,
                          fechaFin: fechaFin,
                          noCuota: noCuota,
                          capital: cap,
                          interes: int,
                          vlrCuota: Math.round(pmt),
                          segVida: 0,
                          segTodoRiesgo: 0,
                        };
                        cap = Math.round(cap - (pmt - int));
                        proyeccion.push(corte);
                      }

                      var auxDes;
                      var auxDes2;

                      if (this.state.transacciones.length > 0) {
                        auxDes = this.state.auxFechaPagoReciente;
                        auxDes2 = this.state.auxProxCorte;
                      } else {
                        auxDes = this.state.fechaDesembolso;
                        auxDes2 = auxFecha;
                      }

                      this.setState(
                        {
                          fechaPagoReciente: new Date(auxDes),
                          fechaProxCorte: new Date(auxDes2),
                        },
                        async () => {
                          try {
                            axios
                              .patch(
                                apiMethods.PATCH_CREDITO_ID + search,
                                { proyeccion: proyeccion },
                                {
                                  headers: {
                                    Authorization: actUsrToken,
                                  },
                                }
                              )
                              .then((response) => {
                                window.sessionStorage.setItem(
                                  "success",
                                  "true"
                                );
                                window.sessionStorage.setItem("type", "E");
                                this.props.history.push("/search/credits");
                              })
                              .catch((error) => {
                                console.error(error);
                                document.getElementById("warnalert").innerHTML =
                                  "Error al actualizar";
                                $("#warnalert").show();
                                $("#warnalert").fadeOut(7000);
                              });
                          } catch (e) {
                            console.error(e);
                            document.getElementById("warnalert").innerHTML =
                              "Error al actualizar";
                            $("#warnalert").show();
                            $("#warnalert").fadeOut(7000);
                          }
                        }
                      );
                    } else {
                      var auxInit = new Date(this.state.fechaDesembolso);
                      var proyeccionLocal = this.state.proyeccion;

                      if (this.state.liquidaciones.length == 1) {
                        for (let i = 0; i < auxPlazo; i++) {
                          var auxLocal = proyeccionLocal[i];
                          auxLocal.fechaIni = new Date(auxInit);
                          auxInit.setMonth(auxInit.getMonth() + 1);
                          auxLocal.fechaFin = new Date(auxInit);
                          proyeccionLocal[i] = auxLocal;
                        }
                      }

                      var auxFecha = new Date(this.state.fechaDesembolso);
                      auxFecha.setMonth(auxFecha.getMonth() + 1);

                      var auxDes;
                      var auxDes2;

                      if (this.state.transacciones.length > 0) {
                        auxDes = this.state.auxFechaPagoReciente;
                        auxDes2 = this.state.auxProxCorte;
                      } else {
                        auxDes = this.state.fechaDesembolso;
                        auxDes2 = auxFecha;
                      }

                      this.setState(
                        {
                          fechaPagoReciente: new Date(auxDes),
                          fechaProxCorte: new Date(auxDes2),
                          proyeccion: [],
                          proyeccionData: [],
                          auxTransacciones: [],
                          detalleData: [],
                          detalleDataUVR: [],
                          iteradorPagos: [],
                          labelsDetails: [],
                          liquidacionesData: [],
                          liquidacionesDataUVR: [],
                          liquidacionesResp: [],
                          resPeriodo: [],
                          resPeriodoUVR: [],
                          transaccionesData: [],

                        },
                        function () {
                          axios
                            .patch(
                              apiMethods.PATCH_CREDITO_ID + search,
                              this.state,
                              {
                                headers: {
                                  Authorization: actUsrToken,
                                },
                              }
                            )
                            .then((response) => {
                              if (response.data.code == 403) {
                                document.getElementById("warnalert").innerHTML =
                                  "Código de entidad financiera ya ingresado";
                                $("#warnalert").show();
                                $("#warnalert").fadeOut(7000);
                              } else {
                                axios
                                  .patch(
                                    apiMethods.PATCH_CREDITO_ID + search,
                                    { proyeccion: proyeccionLocal },
                                    {
                                      headers: {
                                        Authorization: actUsrToken,
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    window.sessionStorage.setItem(
                                      "success",
                                      "true"
                                    );
                                    window.sessionStorage.setItem("type", "E");
                                    this.props.history.push("/search/credits");
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    document.getElementById(
                                      "warnalert"
                                    ).innerHTML = "Error al actualizar";
                                    $("#warnalert").show();
                                    $("#warnalert").fadeOut(7000);
                                  });
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                              document.getElementById("warnalert").innerHTML =
                                "Error al actualizar";
                              $("#warnalert").show();
                              $("#warnalert").fadeOut(7000);
                            });
                        }
                      );
                    }
                  } else if (this.state.moneda == "uvr") {
                    if (
                      this.state.liquidaciones[0].interesCorriente != 0 ||
                      this.state.liquidaciones[0].interesMoratorio != 0 ||
                      this.state.liquidaciones[0].segVida != 0 ||
                      this.state.liquidaciones[0].segTodoRiesgo != 0
                    ) {
                      var auxInit = new Date(this.state.fechaInicial);
                    } else {
                      var auxInit = new Date(this.state.fechaDesembolso);
                    }
                    var year = auxInit.getFullYear();
                    var month = auxInit.getMonth() + 1;
                    var dt = auxInit.getDate();
                    if (dt < 10) {
                      dt = "0" + dt;
                    }
                    if (month < 10) {
                      month = "0" + month;
                    }
                    var parsedDate = dt + "-" + month + "-" + year;

                    axios
                      .get(apiMethods.GET_UVR_FECHA + parsedDate, {
                        headers: {
                          Authorization: actUsrToken,
                        },
                      })
                      .then((response) => {
                        var vlrUVRInitAux = response.data.uvr[0].valor;
                        var vlrUVRInit = vlrUVRInitAux;

                        if (
                          auxInt != this.state.interes ||
                          auxPlazo != this.state.plazo ||
                          auxMonto != auxMontoLocal
                        ) {
                          var interesAnual = this.state.interes;
                          var interesMensualP =
                            Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                          var interesMensual =
                            Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                          interesMensual = interesMensual * 100;
                          var pv = this.state.vlrInicial;
                          var np = this.state.plazo;
                          var fv = 0;
                          var type = 0;
                          var ir = interesMensualP;

                          var pmt, pvif;
                          fv || (fv = 0);
                          type || (type = 0);

                          if (ir === 0) return -(pv + fv) / np;

                          pvif = Math.pow(1 + ir, np);

                          pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

                          var proyeccion = [];
                          var cap = pv;
                          if (
                            this.state.liquidaciones[0].interesCorriente != 0 ||
                            this.state.liquidaciones[0].interesMoratorio != 0 ||
                            this.state.liquidaciones[0].segVida != 0 ||
                            this.state.liquidaciones[0].segTodoRiesgo != 0
                          ) {
                            var auxInit = new Date(this.state.fechaInicial);
                          } else {
                            var auxInit = new Date(this.state.fechaDesembolso);
                          }

                          for (let i = 0; i < np; i++) {
                            var noCuota = i + 1;
                            var int = Math.round(cap * interesMensualP);
                            var fechaIni = new Date(auxInit);
                            auxInit.setMonth(auxInit.getMonth() + 1);
                            var fechaFin = new Date(auxInit);
                            var corte = {
                              liquidado: false,
                              presentaPagos: false,
                              fechaIni: fechaIni,
                              fechaFin: fechaFin,
                              noCuota: noCuota,
                              capital: cap,
                              interes: int,
                              vlrCuota: Math.round(pmt),
                              segVida: 0,
                              segTodoRiesgo: 0,
                              vlrCuotaUVR:
                                parseFloat(this.state.vlrCuota) / vlrUVRInit,
                              vlrInteresUVR: parseFloat(int) / vlrUVRInit,
                              vlrCapitalUVR: parseFloat(cap) / vlrUVRInit,
                            };
                            cap = Math.round(cap - (pmt - int));
                            proyeccion.push(corte);
                          }

                          var localLiquidacion = this.state.liquidaciones;

                          if (
                            !(
                              this.state.liquidaciones[0].interesCorriente !=
                              0 ||
                              this.state.liquidaciones[0].interesMoratorio !=
                              0 ||
                              this.state.liquidaciones[0].segVida != 0 ||
                              this.state.liquidaciones[0].segTodoRiesgo != 0
                            )
                          ) {
                            var localInitLiquidacion = localLiquidacion[0];
                            localInitLiquidacion.capitalUVR =
                              localInitLiquidacion.capital / vlrUVRInit;
                            localInitLiquidacion.interesCorrienteUVR = 0;
                            localInitLiquidacion.interesMoratorioUVR = 0;
                            localLiquidacion[0] = localInitLiquidacion;
                          }

                          var auxDes;
                          var auxDes2;

                          if (this.state.transacciones.length > 0) {
                            auxDes = this.state.auxFechaPagoReciente;
                            auxDes2 = this.state.auxProxCorte;
                          } else {
                            auxDes = this.state.fechaDesembolso;
                            auxDes2 = auxFecha;
                          }

                          if (this.state.liquidaciones.length > 1) {
                            this.setState(
                              {
                                fechaPagoReciente: new Date(auxDes),
                                fechaProxCorte: new Date(auxDes2),
                                liquidaciones: localLiquidacion,
                              },
                              function () {
                                axios
                                  .patch(
                                    apiMethods.PATCH_CREDITO_ID + search,
                                    this.state,
                                    {
                                      headers: {
                                        Authorization: actUsrToken,
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.code == 403) {
                                      document.getElementById(
                                        "warnalert"
                                      ).innerHTML =
                                        "Código de entidad financiera ya ingresado";
                                      $("#warnalert").show();
                                      $("#warnalert").fadeOut(7000);
                                    } else {
                                      axios
                                        .patch(
                                          apiMethods.PATCH_CREDITO_ID + search,
                                          { proyeccion: proyeccion },
                                          {
                                            headers: {
                                              Authorization: actUsrToken,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          window.sessionStorage.setItem(
                                            "success",
                                            "true"
                                          );
                                          window.sessionStorage.setItem(
                                            "type",
                                            "E"
                                          );
                                          this.props.history.push(
                                            "/search/credits"
                                          );
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                          document.getElementById(
                                            "warnalert"
                                          ).innerHTML = "Error al actualizar";
                                          $("#warnalert").show();
                                          $("#warnalert").fadeOut(7000);
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    document.getElementById(
                                      "warnalert"
                                    ).innerHTML = "Error al actualizar";
                                    $("#warnalert").show();
                                    $("#warnalert").fadeOut(7000);
                                  });
                              }
                            );
                          } else {
                            this.setState(
                              {
                                fechaPagoReciente: new Date(auxDes),
                                fechaProxCorte: new Date(auxDes2),
                                vlrCuotaUVR:
                                  parseFloat(this.state.vlrCuota) / vlrUVRInit,
                                vlrInicialUVR:
                                  parseFloat(auxMonto) / vlrUVRInit,
                                vlrCapProyectadoUVR:
                                  parseFloat(auxMonto) / vlrUVRInit,
                                vlrCapitalUVR:
                                  parseFloat(auxMonto) / vlrUVRInit,
                                liquidaciones: localLiquidacion,
                              },
                              function () {
                                axios
                                  .patch(
                                    apiMethods.PATCH_CREDITO_ID + search,
                                    this.state,
                                    {
                                      headers: {
                                        Authorization: actUsrToken,
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.code == 403) {
                                      document.getElementById(
                                        "warnalert"
                                      ).innerHTML =
                                        "Código de entidad financiera ya ingresado";
                                      $("#warnalert").show();
                                      $("#warnalert").fadeOut(7000);
                                    } else {
                                      axios
                                        .patch(
                                          apiMethods.PATCH_CREDITO_ID + search,
                                          { proyeccion: proyeccion },
                                          {
                                            headers: {
                                              Authorization: actUsrToken,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          window.sessionStorage.setItem(
                                            "success",
                                            "true"
                                          );
                                          window.sessionStorage.setItem(
                                            "type",
                                            "E"
                                          );
                                          this.props.history.push(
                                            "/search/credits"
                                          );
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                          document.getElementById(
                                            "warnalert"
                                          ).innerHTML = "Error al actualizar";
                                          $("#warnalert").show();
                                          $("#warnalert").fadeOut(7000);
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    document.getElementById(
                                      "warnalert"
                                    ).innerHTML = "Error al actualizar";
                                    $("#warnalert").show();
                                    $("#warnalert").fadeOut(7000);
                                  });
                              }
                            );
                          }
                        } else {
                          if (
                            this.state.liquidaciones[0].interesCorriente != 0 ||
                            this.state.liquidaciones[0].interesMoratorio != 0 ||
                            this.state.liquidaciones[0].segVida != 0 ||
                            this.state.liquidaciones[0].segTodoRiesgo != 0
                          ) {
                            var auxInit = new Date(this.state.fechaInicial);
                          } else {
                            var auxInit = new Date(this.state.fechaDesembolso);
                          }
                          var proyeccionLocal = this.state.proyeccion;

                          for (let i = 0; i < auxPlazo; i++) {
                            var auxLocal = proyeccionLocal[i];
                            auxLocal.vlrCuotaUVR =
                              parseFloat(this.state.vlrCuota) / vlrUVRInit;
                            auxLocal.vlrInteresUVR =
                              parseFloat(auxLocal.interes) / vlrUVRInit;
                            auxLocal.vlrCapitalUVR =
                              parseFloat(auxLocal.capital) / vlrUVRInit;
                            auxLocal.fechaIni = new Date(auxInit);
                            auxInit.setMonth(auxInit.getMonth() + 1);
                            auxLocal.fechaFin = new Date(auxInit);
                            proyeccionLocal[i] = auxLocal;
                          }

                          var localLiquidacion = this.state.liquidaciones;

                          if (
                            !(
                              this.state.liquidaciones[0].interesCorriente !=
                              0 ||
                              this.state.liquidaciones[0].interesMoratorio !=
                              0 ||
                              this.state.liquidaciones[0].segVida != 0 ||
                              this.state.liquidaciones[0].segTodoRiesgo != 0
                            )
                          ) {
                            var localInitLiquidacion = localLiquidacion[0];
                            localInitLiquidacion.capitalUVR =
                              localInitLiquidacion.capital / vlrUVRInit;
                            localInitLiquidacion.interesCorrienteUVR = 0;
                            localInitLiquidacion.interesMoratorioUVR = 0;
                            localLiquidacion[0] = localInitLiquidacion;
                          }

                          var auxFecha = new Date(this.state.fechaDesembolso);
                          auxFecha.setMonth(auxFecha.getMonth() + 1);

                          var auxDes;
                          var auxDes2;

                          if (this.state.transacciones.length > 0) {
                            auxDes = this.state.auxFechaPagoReciente;
                            auxDes2 = this.state.auxProxCorte;
                          } else {
                            auxDes = this.state.fechaDesembolso;
                            auxDes2 = auxFecha;
                          }

                          if (this.state.liquidaciones.length > 1) {
                            this.setState(
                              {
                                fechaPagoReciente: new Date(auxDes),
                                fechaProxCorte: new Date(auxDes2),
                                liquidaciones: localLiquidacion,
                                proyeccion: [],
                                proyeccionData: [],
                                pagosPorCreditos: [],
                                auxTransacciones: [],
                                detalleData: [],
                                detalleDataUVR: [],
                                iteradorPagos: [],
                                labelsDetails: [],
                                liquidacionesData: [],
                                liquidacionesDataUVR: [],
                                liquidacionesResp: [],
                                resPeriodo: [],
                                resPeriodoUVR: [],
                                transaccionesData: [],
                              },
                              function () {
                                axios
                                  .patch(
                                    apiMethods.PATCH_CREDITO_ID + search,
                                    this.state,
                                    {
                                      headers: {
                                        Authorization: actUsrToken,
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.code == 403) {
                                      document.getElementById(
                                        "warnalert"
                                      ).innerHTML =
                                        "Código de entidad financiera ya ingresado";
                                      $("#warnalert").show();
                                      $("#warnalert").fadeOut(7000);
                                    } else {
                                      axios
                                        .patch(
                                          apiMethods.PATCH_CREDITO_ID + search,
                                          { proyeccion: proyeccionLocal },
                                          {
                                            headers: {
                                              Authorization: actUsrToken,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          window.sessionStorage.setItem(
                                            "success",
                                            "true"
                                          );
                                          window.sessionStorage.setItem(
                                            "type",
                                            "E"
                                          );
                                          this.props.history.push(
                                            "/search/credits"
                                          );
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                          document.getElementById(
                                            "warnalert"
                                          ).innerHTML = "Error al actualizar";
                                          $("#warnalert").show();
                                          $("#warnalert").fadeOut(7000);
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    document.getElementById(
                                      "warnalert"
                                    ).innerHTML = "Error al actualizar";
                                    $("#warnalert").show();
                                    $("#warnalert").fadeOut(7000);
                                  });
                              }
                            );
                          } else {
                            this.setState(
                              {
                                fechaPagoReciente: new Date(auxDes),
                                fechaProxCorte: new Date(auxDes2),
                                vlrCuotaUVR:
                                  parseFloat(this.state.vlrCuota) / vlrUVRInit,
                                vlrInicialUVR:
                                  parseFloat(auxMonto) / vlrUVRInit,
                                vlrCapProyectadoUVR:
                                  parseFloat(auxMonto) / vlrUVRInit,
                                vlrCapitalUVR:
                                  parseFloat(auxMonto) / vlrUVRInit,
                                liquidaciones: localLiquidacion,
                                proyeccion: [],
                                proyeccionData: [],
                                pagosPorCreditos: [],
                                auxTransacciones: [],
                                detalleData: [],
                                detalleDataUVR: [],
                                iteradorPagos: [],
                                labelsDetails: [],
                                liquidacionesData: [],
                                liquidacionesDataUVR: [],
                                liquidacionesResp: [],
                                resPeriodo: [],
                                resPeriodoUVR: [],
                                transaccionesData: [],
                              },
                              function () {
                                axios
                                  .patch(
                                    apiMethods.PATCH_CREDITO_ID + search,
                                    this.state,
                                    {
                                      headers: {
                                        Authorization: actUsrToken,
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.code == 403) {
                                      document.getElementById(
                                        "warnalert"
                                      ).innerHTML =
                                        "Código de entidad financiera ya ingresado";
                                      $("#warnalert").show();
                                      $("#warnalert").fadeOut(7000);
                                    } else {
                                      axios
                                        .patch(
                                          apiMethods.PATCH_CREDITO_ID + search,
                                          { proyeccion: proyeccionLocal },
                                          {
                                            headers: {
                                              Authorization: actUsrToken,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          window.sessionStorage.setItem(
                                            "success",
                                            "true"
                                          );
                                          window.sessionStorage.setItem(
                                            "type",
                                            "E"
                                          );
                                          this.props.history.push(
                                            "/search/credits"
                                          );
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                          document.getElementById(
                                            "warnalert"
                                          ).innerHTML = "Error al actualizar";
                                          $("#warnalert").show();
                                          $("#warnalert").fadeOut(7000);
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    document.getElementById(
                                      "warnalert"
                                    ).innerHTML = "Error al actualizar";
                                    $("#warnalert").show();
                                    $("#warnalert").fadeOut(7000);
                                  });
                              }
                            );
                          }
                        }
                      });
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((error) => {
              console.error(error);
              document.getElementById("warnalert").innerHTML =
                "Error al actualizar";
              $("#warnalert").show();
              $("#warnalert").fadeOut(7000);
            });
        }
      }
    } else {
      document.getElementById("warnalert").innerHTML =
        "Valores de seguros inválidos";
      $("#warnalert").show();
      $("#warnalert").fadeOut(7000);
    }
  };

  removeCredit = (e) => {
    e.preventDefault();
    let search = window.sessionStorage.getItem("localDocument");
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;

    for (let i = 0; i < this.state.deudores.length; i++) {
      axios
        .get(apiMethods.GET_CLIENTE_DOCUMENTO + this.state.deudores[i], {
          headers: {
            Authorization: actUsrToken,
          },
        })
        .then((response) => {
          var tempArray = response.data.client.creditos;
          tempArray.splice(tempArray.indexOf(search), 1);
          this.setState({ creditos: tempArray });
          axios
            .patch(
              apiMethods.PATCH_CLIENTE_DOCUMENTO + this.state.deudores[i],
              this.state,
              {
                headers: {
                  Authorization: actUsrToken,
                },
              }
            )
            .then((response) => { })
            .catch((error) => {
              console.error(error);
              document.getElementById("warnalert").innerHTML =
                "Error al eliminar";
              $("#warnalert").show();
              $("#warnalert").fadeOut(7000);
            });
        })
        .catch((error) => {
          console.error(error);
          document.getElementById("warnalert").innerHTML = "Error al eliminar";
          $("#warnalert").show();
          $("#warnalert").fadeOut(7000);
        });
    }

    axios
      .get(apiMethods.GET_USUARIO_ID + localId, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var tempArrayUsr = response.data.users.creditos;
        tempArrayUsr.splice(tempArrayUsr.indexOf(search), 1);
        axios
          .patch(
            apiMethods.PATCH_USUARIO_ID + localId,
            { creditos: tempArrayUsr },
            {
              headers: {
                Authorization: actUsrToken,
              },
            }
          )
          .then((response) => {
            axios
              .post(apiMethods.DELETE_CREDITO_ID + search, null, {
                headers: {
                  Authorization: actUsrToken,
                },
              })
              .then((response) => {
                window.sessionStorage.setItem("success", "true");
                window.sessionStorage.setItem("type", "D");
                this.props.history.push("/search/credits");
              })
              .catch((error) => {
                console.error(error);
                document.getElementById("warnalert").innerHTML =
                  "Error al eliminar";
                $("#warnalert").show();
                $("#warnalert").fadeOut(7000);
              });
          })
          .catch((error) => {
            console.error(error);
            document.getElementById("warnalert").innerHTML =
              "Error al eliminar";
            $("#warnalert").show();
            $("#warnalert").fadeOut(7000);
          });
      })
      .catch((error) => {
        console.error(error);
        document.getElementById("warnalert").innerHTML = "Error al eliminar";
        $("#warnalert").show();
        $("#warnalert").fadeOut(7000);
      });
  };

  addClientesPush = () => {
    console.log(21983)
    //this.props.history.push("/addClientes");
  };

  createSelectItems() {
    let items = [];
    var hasPayments = [];
    var cntMesesMoraTotal = 0;

    for (let i = 0; i < this.state.transacciones.length; i++) {
      cntMesesMoraTotal =
        cntMesesMoraTotal + this.state.transacciones[i].mesesEnMora;
    }

    var sumMora = 0;

    for (let i = 0; i < this.state.proyeccionData.length; i++) {
      if (this.state.proyeccionData[i].presentaPagos == true) {
        hasPayments.push(i);
      }
    }

    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    for (let i = 0; i < hasPayments.length; i++) {
      var id = "periodo" + hasPayments[i];
      var auxLiq = this.state.proyeccionData[hasPayments[i]];
      var auxIni = new Date(auxLiq.fechaIni);
      var auxFin = new Date(auxLiq.fechaFin);

      auxIni = this.fixTimeZone(auxIni);
      auxFin = this.fixTimeZone(auxFin);

      var auxPeriodo =
        ("0" + auxIni.getDate()).slice(-2) +
        " " +
        months[auxIni.getMonth()] +
        " " +
        auxIni.getFullYear() +
        " al " +
        ("0" + auxFin.getDate()).slice(-2) +
        " " +
        months[auxFin.getMonth()] +
        " " +
        auxFin.getFullYear();
      items.push(
        <option key={auxPeriodo} id={id} value={auxPeriodo}>
          {auxPeriodo}
        </option>
      );
    }
    return items;
  }

  createSelectItemsLiq() {
    let items = [];
    var hasPayments = [];
    if (this.state.transacciones.length > 0) {
      var auxReciente = new Date(
        this.state.transacciones[this.state.transacciones.length - 1].fechaTx
      );
    } else {
      var auxReciente = new Date(this.state.fechaDesembolso);
    }
    auxReciente.setHours(0, 0, 0, 0);

    var auxLiq;
    var auxPresenta;

    if (this.state.transacciones.length > 0) {
      for (let i = this.state.proyeccion.length - 1; i >= 0; i--) {
        if (this.state.proyeccion[i].presentaPagos == true) {
          auxPresenta = i;
          break;
        }
      }

      for (let i = auxPresenta; i < this.state.proyeccion.length; i++) {
        if (this.state.proyeccion[i].liquidado == false) {
          if (
            new Date().getTime() <
            new Date(this.state.proyeccion[i].fechaIni).getTime() ||
            new Date().getTime() >
            new Date(this.state.proyeccion[i].fechaFin).getTime()
          ) {
            auxLiq = this.state.proyeccion[i];
            break;
          } else {
            break;
          }
        } else {
          if (this.state.proyeccion[i + 1]) {
            if (
              new Date().getTime() <
              new Date(this.state.proyeccion[i + 1].fechaIni).getTime() ||
              new Date().getTime() >
              new Date(this.state.proyeccion[i + 1].fechaFin).getTime()
            ) {
              auxLiq = this.state.proyeccion[i + 1];
              break;
            } else {
              break;
            }
          } else {
            var fechaIni = new Date(this.state.proyeccion[i].fechaFin);
            var fechaFin = new Date(fechaIni);
            fechaFin.setMonth(fechaFin.getMonth() + 1);
            var corte = {
              liquidado: false,
              presentaPagos: false,
              fechaIni: fechaIni,
              fechaFin: fechaFin,
              noCuota: 0,
              capital: 0,
              interes: 0,
              vlrCuota: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };

            if (
              new Date().getTime() < fechaIni.getTime() ||
              new Date().getTime() > fechaFin.getTime()
            ) {
              auxLiq = corte;
              break;
            } else {
              break;
            }
          }
        }
      }
    } else {
      if (this.state.proyeccion[0]) {
        auxLiq = this.state.proyeccion[0];
      } else {
        var fechaIni = new Date(this.state.fechaDesembolso);
        var fechaFin = new Date(fechaIni);
        fechaFin.setMonth(fechaFin.getMonth() + 1);
        var corte = {
          liquidado: false,
          presentaPagos: false,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          noCuota: 0,
          capital: 0,
          interes: 0,
          vlrCuota: 0,
          segVida: 0,
          segTodoRiesgo: 0,
        };
        auxLiq = corte;
      }
    }

    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    if (auxLiq) {
      if (auxLiq.fechaIni instanceof Date) {
        var auxIni = new Date(
          auxLiq.fechaIni.toISOString().substring(0, 10).replace(/-/g, "/")
        );
        var auxFin = new Date(
          auxLiq.fechaFin.toISOString().substring(0, 10).replace(/-/g, "/")
        );
      } else {
        var auxIni = new Date(
          auxLiq.fechaIni.substring(0, 10).replace(/-/g, "/")
        );
        var auxFin = new Date(
          auxLiq.fechaFin.substring(0, 10).replace(/-/g, "/")
        );
      }

      auxIni = this.fixTimeZone(auxIni);
      auxFin = this.fixTimeZone(auxFin);

      var auxPeriodo =
        ("0" + auxIni.getDate()).slice(-2) +
        " " +
        months[auxIni.getMonth()] +
        " " +
        auxIni.getFullYear() +
        " al " +
        ("0" + auxFin.getDate()).slice(-2) +
        " " +
        months[auxFin.getMonth()] +
        " " +
        auxFin.getFullYear();
      items.push(
        <option
          key={auxPeriodo}
          id={
            auxFin.getFullYear() +
            "/" +
            ("0" + (auxFin.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + auxFin.getDate()).slice(-2)
          }
          value={auxPeriodo}
        >
          {auxPeriodo}
        </option>
      );
    }
    return items;
  }

  render = () => {
    const {
      deudores,
      vlrCapital,
      vlrInicial,
      plazo,
      moneda,
      estado,
      fechaAprobacion,
      fechaDesembolso,
      fechaDesembolsoReal,
      transacciones,
      id,
      montoPago,
      garantia,
      amortizacion,
      interes,
      interesMoratorio,
      seguroVida,
      seguroTodoRiesgo,
      vlrSegVida,
      vlrSegTodoRiesgo,
      vlrInteres,
      vlrInteresMora,
      saldoSegTodoRiesgo,
      saldoSegVida,
      financialCode,
      expiryDate,
      mortgageDate,
      landRegistry,
      deedNumber,
      notary,
    } = this.state;
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let search = window.sessionStorage.getItem("localDocument");

    const uploadFile = (event) => {
      console.log(event.target.files[0]);
      const data = new FormData();
      data.append("file", event.target.files[0]);
      axios
        .post(apiMethods.UPLOAD_FILE_ID + this.state.originalId, data)
        .then((res) => {
          let files = [...this.state.files];
          files.push({
            id: res.data.id,
            name: res.data.name,
          });
          this.setState({ files: files });
        });
    };

    const renderDetailsButton = (params) => {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              axios
                .get(apiMethods.GET_FILE_ID + params.row.id, {
                  responseType: "blob",
                })
                .then((res) => {
                  saveAs(res.data, params.row.name);
                });
            }}
          >
            Descargar
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              axios
                .delete(
                  apiMethods.DELETE_FILE_ID +
                  params.row.id +
                  "/creditId/" +
                  this.state.originalId
                )
                .then((res) => {
                  this.setState({
                    files: this.state.files.filter(
                      (file) => file.id !== params.row.id
                    ),
                  });
                });
            }}
          >
            Eliminar
          </Button>
        </>
      );
    };

    const clientsColumns = [
      { field: "id", headerName: "ID", width: "200" },
      { field: "name", headerName: "Nombre", width: "250" },
      {
        field: "accion",
        headerName: "Acción",
        width: "250",
        renderCell: renderDetailsButton,
      },
    ];

    var canPay = false;
    axios
      .get(apiMethods.GET_CREDITO_ID + search, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        if (response.data.credits.estado == "aprobado") {
          canPay = true;
        }
      })
      .catch((error) => {
        console.error(error);
        document.getElementById("warnalert").innerHTML = "Error al eliminar";
        $("#warnalert").show();
        $("#warnalert").fadeOut(7000);
      });

    if (actUsrToken != undefined) {
      let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
      const DatePickerStateAprobacion = ({ value, onClick }) => (
        <Button
          className="react-datepicker-ignore-onclickoutside"
          onClick={onClick}
          id="datePicker"
        >
          {value}
        </Button>
      );
      const DatePickerStateGiro = ({ value, onClick }) => (
        <Button
          className="react-datepicker-ignore-onclickoutside"
          onClick={onClick}
          id="datePicker"
        >
          {value}
        </Button>
      );
      const DatePickerStateMortgageDate = ({ value, onClick }) => (
        <Button
          className="react-datepicker-ignore-onclickoutside"
          onClick={onClick}
          id="datePicker"
        >
          {value}
        </Button>
      );
      const DatePickerStateExpiryDate = ({ value, onClick }) => (
        <Button
          className="react-datepicker-ignore-onclickoutside"
          onClick={onClick}
          id="datePicker"
        >
          {value}
        </Button>
      );
      const DatePickerStateFechaDesembolsoReal = ({ value, onClick }) => (
        <Button
          className="react-datepicker-ignore-onclickoutside"
          onClick={onClick}
          id="datePicker"
        >
          {value}
        </Button>
      );
      const DatePickerState = ({ value, onClick }) => (
        <Button
          className="react-datepicker-ignore-onclickoutside"
          onClick={onClick}
          id="datePicker"
        >
          {value}
        </Button>
      );
      return (
        <React.Fragment>
          <div className="card">
            <form>
              <p className="alert alert-success float-right" id="alertCli"></p>
              <p className="alert alert-danger float-right" id="warnalert"></p>
              <div>
                <Form.Label className="formTitle">Clientes</Form.Label>
                <br></br>
                <table id="table">
                  <tbody>
                    <tr>{this.renderTableHeaderClientes()}</tr>
                    {this.renderTableDataClientes()}
                  </tbody>
                </table>
              </div>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="id"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={id}
                      onChange={this.changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">Plazo</Form.Label>
                    <Form.Control
                      type="text"
                      name="plazo"
                      id="plazo"
                      value={plazo}
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      onChange={this.changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Monto del credito
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="vlrInicial"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={vlrInicial}
                      onChange={this.changeHandlerFormato}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">Moneda</Form.Label>
                    <Form.Control
                      as="select"
                      name="moneda"
                      className="selector"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={moneda}
                      onChange={this.changeHandler}
                    >
                      <option value="pesos">Pesos</option>
                      <option value="uvr">UVR</option>
                    </Form.Control>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">Garantía</Form.Label>
                    <Form.Control
                      as="select"
                      name="garantia"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      className="selector"
                      value={garantia}
                      onChange={this.changeHandler}
                    >
                      <option value="libranza">Libranza</option>
                      <option value="hipoteca">Hipoteca</option>
                      <option value="soloPagare">Solo pagaré</option>
                    </Form.Control>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Tipo de amortización
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="amortizacion"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      className="selector"
                      value={amortizacion}
                      onChange={this.changeHandler}
                    >
                      <option value="cuotaConstante">Cuota constante</option>
                    </Form.Control>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Tasa de interés
                    </Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type="text"
                        name="interes"
                        disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                        id="interes"
                        value={interes}
                        onChange={this.changeHandler}
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">%</div>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">Estado</Form.Label>
                    <Form.Control
                      as="select"
                      name="estado"
                      className="selectorEstado"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={estado}
                      onChange={this.changeHandler}
                    >
                      <option value="noAprobado">No Aprobado</option>
                      <option value="aprobado">Aprobado</option>
                    </Form.Control>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Código entidad financiera
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="financialCode"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={financialCode}
                      onChange={this.changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Número de matricula inmobiliaria
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="landRegistry"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={landRegistry}
                      onChange={this.changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Notaría
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      name="notary"
                      value={notary}
                      onChange={this.changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Número de escritura
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="deedNumber"
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      value={deedNumber}
                      onChange={this.changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form id="desembolsoReal">
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Fecha Desembolso
                    </Form.Label>{" "}
                    <br></br>
                    <DatePicker
                      selected={this.state.fechaDesembolsoReal}
                      onChange={this.handleChangeFechaDesembolsoReal}
                      dateFormat={"dd/MM/yyyy"}
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      customInput={<DatePickerStateFechaDesembolsoReal />}
                    />
                  </FormGroup>
                </Col>
                {this.state.estado === "aprobado" && (
                  <Col md={6}>
                    <FormGroup>
                      <div id="aprobado" className="group">
                        <Form.Label className="formTitle">
                          Fecha Aprobación
                        </Form.Label>{" "}
                        <br></br>
                        <DatePicker
                          selected={this.state.fechaAprobacion}
                          disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                          onChange={this.handleChangeAprobacion}
                          dateFormat={"dd/MM/yyyy"}
                          customInput={<DatePickerStateAprobacion />}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row form id="desembolsoReal">
                <Col md={6}>
                  <FormGroup>
                    <Form.Label className="formTitle">
                      Vencimiento final
                    </Form.Label>{" "}
                    <br></br>
                    <DatePicker
                      selected={this.state.expiryDate}
                      onChange={this.handleChangeExpiryDate}
                      disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                      dateFormat={"dd/MM/yyyy"}
                      customInput={<DatePickerStateFechaDesembolsoReal />}
                    />
                  </FormGroup>
                </Col>
                  <Col md={6}>
                    <FormGroup>
                      <div id="aprobado" className="group">
                        <Form.Label className="formTitle">
                          Fecha hipoteca
                        </Form.Label>{" "}
                        <br></br>
                        <DatePicker
                          selected={this.state.mortgageDate}
                          onChange={this.handleChangeMortgageDate}
                          disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                          dateFormat={"dd/MM/yyyy"}
                          customInput={<DatePickerStateAprobacion />}
                        />
                      </div>
                    </FormGroup>
                  </Col>
              </Row>
              {this.state.pasoEdicionLocal > 0 && (
                <React.Fragment>
                  <Form.Label className="formTitle">Liquidación</Form.Label>
                  <div
                    id={this.state.moneda === "uvr" ? "optionUVRLiq" : null}
                    className={this.state.moneda === "uvr" ? "group" : null}
                  >
                    <table
                      id="table"
                      name="liq"
                      class="table table-hover bg-white table-striped"
                    >
                      <tbody>
                        <tr>{this.renderTableHeaderLiquidaciones()}</tr>
                        {this.state.moneda === "uvr"
                          ? this.renderTableDataLiquidacionesUVR()
                          : this.renderTableDataLiquidaciones()}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Form.Label className="formTitle">
                      Abonos registrados
                    </Form.Label>
                    <table id="table">
                      <tbody>
                        <tr>{this.renderTableHeaderTransacciones()}</tr>
                        {this.renderTableDataTransacciones()}
                      </tbody>
                    </table>
                  </div>
                  <Form.Label className="formTitle">Proyección</Form.Label>
                  <div className="leftBtn">
                    <Button
                      color="primary"
                      variant="contained"
                      id="showProy"
                      onClick={this.showProy}
                    >
                      Mostrar Proyección
                    </Button>
                  </div>
                  <br></br>
                  <div id="proyTable" className="group">
                    <table id="table">
                      <tbody>
                        <tr>{this.renderTableHeaderProyeccion()}</tr>
                        {this.state.moneda === "uvr"
                          ? this.renderTableDataProyeccionUVR()
                          : this.renderTableDataProyeccion()}
                      </tbody>
                    </table>
                  </div>
                  <Row form>
                    <Col md={2}>
                      <div className="leftBtn">
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                          onClick={this.addCuota}
                        >
                          Añadir cuota
                        </Button>
                      </div>
                      <br></br>
                    </Col>
                    <Col>
                      <div className="leftBtn">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.handleShowDownload}
                        >
                          Descargar estado de cuenta
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.downloadPaymentsAplication.bind(this)}
                          style={{ marginLeft: "1rem" }}
                        >
                          Descargar aplicación de pagos
                        </Button>
                        <Modal
                          centered={true}
                          show={this.state.showModalDownload}
                          onHide={this.handleCloseDownload}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Descargar estado de cuenta
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Por favor indique el periodo a descargar
                          </Modal.Body>
                          <Form.Control
                            as="select"
                            name="estado"
                            className="selectorDownload"
                            value={estado}
                            onChange={this.changeHandler}
                          >
                            {this.createSelectItems()}
                          </Form.Control>{" "}
                          <Modal.Footer>
                            <Button
                              type="submit"
                              color="primary"
                              disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                              variant="contained"
                              onClick={this.generateInitialPDF}
                            >
                              Descargar estado de cuenta inicial
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              onClick={this.generatePDF}
                            >
                              Descargar
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <Modal
                          centered={true}
                          show={this.state.showModalLiq}
                          onHide={this.handleCloseLiq}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Liquidar credito según periodo
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Por favor indique el periodo a liquidar
                          </Modal.Body>
                          <Form.Control
                            as="select"
                            name="estado"
                            className="selectorLiq"
                            value={estado}
                            onChange={this.changeHandler}
                          >
                            {this.createSelectItemsLiq()}
                          </Form.Control>{" "}
                          <Modal.Footer>
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              onClick={this.relativePayment}
                            >
                              Liquidar
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="leftBtn">
                        <Button
                          color="primary"
                          disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                          variant="contained"
                          onClick={this.handleShowLiq}
                        >
                          Liquidar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <br></br>
                </React.Fragment>
              )}
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                onClick={this.submitHandler}
              >
                Enviar
              </Button>
            </form>
            <br></br>

            <Dialog
              open={this.state.showPaymentsAplicationDialog}
              onClose={this.paymentsAplicationDialog}
              fullWidth={true}
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                {"Documentos anexos al crédito"}
              </DialogTitle>
              <DialogContent>
                <div
                  style={{
                    height: 250,
                    width: "100%",
                    margin: "1rem 0 2rem 0",
                  }}
                >
                  <DataGrid
                    rows={this.state.files}
                    columns={clientsColumns}
                    pageSize={5}
                    disableSelectionOnClick
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <input
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={uploadFile}
                />
                <label htmlFor="raised-button-file">
                  <Button style={{ marginRight: "1rem" }} component="span">
                    Subir archivo
                  </Button>
                </label>
                <label htmlFor="demo">
                  <Button
                    component="span"
                    onClick={this.paymentsAplicationDialog}
                    autoFocus
                  >
                    Cerrar
                  </Button>
                </label>
              </DialogActions>
            </Dialog>

            <Form>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                onClick={this.handleShowDeleteCredit}
              >
                Eliminar crédito
              </Button>
              <Button
                variant="contained"
                onClick={this.paymentsAplicationDialog}
                disabled={JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1 ? false : true}
                color="primary"
                style={{ marginLeft: "1rem" }}
              >
                Documentos
              </Button>
              <Modal
                centered={true}
                show={this.state.showModalDeleteCredito}
                onHide={this.handleCloseDeleteCredit}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Eliminación de crédito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  ¿Está seguro que desea eliminar el crédito?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={this.removeCredit}
                  >
                    Aceptar
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={this.handleCloseDeleteCredit}
                  >
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                centered={true}
                show={this.state.showModalDeletePayment}
                onHide={this.handleShowDeletePayment}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Eliminación de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  ¿Está seguro que desea eliminar el pago?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={this.delPago}
                  >
                    Aceptar
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={this.handleCloseDeletePayment}
                  >
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          </div>
          <br></br>
          {(this.state.pasoEdicionLocal >= 2 && JSON.parse(atob(window.sessionStorage.getItem("localToken").split(".")[1])).roleId == 1) && (
            <div className="card">
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Label className="formTitle">
                      Monto del pago
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="montoPago"
                      id="currency-fieldPago"
                      onChange={this.formatter}
                    />
                  </Col>
                  <Col>
                    <Form.Label className="formTitle">
                      Fecha del pago
                    </Form.Label>{" "}
                    <br></br>
                    <DatePicker
                      selected={this.state.fechaPago}
                      onChange={this.handleChangeDatePicker}
                      dateFormat={"dd/MM/yyyy"}
                      customInput={<DatePickerState />}
                      maxDate={new Date()}
                    />
                  </Col>
                  <Col>
                    <br></br>
                    <form>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        name="naturalBtn"
                        onClick={this.handleShow}
                      >
                        Enviar Pago
                      </Button>
                      <Modal
                        centered={true}
                        show={this.state.showModal}
                        onHide={this.handleClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Pago fuera de plazo defindo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Desea enviar el pago con seguros en ceros, o definir
                          los seguros manualmente?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={this.definirSegurosModal}
                          >
                            Definir seguros
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={this.enviarPagoModal}
                          >
                            Enviar pago
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </form>
                  </Col>
                </Form.Row>
              </Form>
            </div>
          )}

          <br></br>
        </React.Fragment>
      );
    }
  };
}

export default detailCreditos;
