import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Modal } from "react-bootstrap";
import logo from "../res/logoBase64.js";
import footer from "../res/footerBase64.js";
import "react-datepicker/dist/react-datepicker.css";
import * as apiMethods from "../res/apiMethods.js";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControlLabel,
  Checkbox,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import history from "../services/history.service.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyFormat from "react-currency-format";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Col } from "reactstrap";
import DatePicker from "react-datepicker";

import Sidebar from "../components/Sidebar";

import { Nav, NavDropdown } from "react-bootstrap";
import { fontSize } from "@mui/system";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

axios.interceptors.request.use(
  (config) => {
    if (window.sessionStorage.getItem("localToken")) {
      config.headers.Authorization =
        window.sessionStorage.getItem("localToken");
    } else {
      //history.push("/login");
      //window.location.reload();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



const Credits = () => {
  const [props, setProps] = useState({
    user: "",
    deudores: [],
    vlrCapital: "",
    plazo: "",
    estado: "",
    moneda: "",
    fechaAprobacion: new Date(),
    fechaDesembolso: new Date(),
    fechaDesembolsoReal: new Date(),
    transacciones: [],
    vlrInicial: "",
    transaccionesData: [],
    clientesData: [],
    id: "",
    creditos: [],
    fechaPago: new Date(),
    originalId: "",
    montoPago: "",
    cntTransacciones: "",
    pagosUsuario: [],
    garantia: "",
    amortizacion: "",
    auxProxCorte: null,
    auxProxCorteSave: null,
    interes: "",
    interesMoratorio: "",
    seguroVida: "",
    seguroTodoRiesgo: "",
    fechaPagoReciente: null,
    showPaymentsAplicationDialog: false,
    creditos: [],
    fechaPagoProximo: null,
    vlrSegVida: "",
    vlrSegTodoRiesgo: "",
    vlrInteres: "",
    vlrInteresMora: "",
    saldoSegVida: "",
    saldoSegTodoRiesgo: "",
    liquidacionesData: [],
    liquidacionesResp: [],
    proyeccionData: [],
    proyeccion: [],
    estadoReal: "",
    pagosPorCreditos: [],
    detalleData: [],
    labelsDetails: [
      "Saldo Inicial",
      "Movimientos",
      "Aplicacion del pago",
      "Nuevo saldo",
    ],
    resPeriodo: [],
    iteradorPagos: [],
    pasoEdicionLocal: "",
    liquidacionesDataUVR: [],
    detalleDataUVR: [],
    auxTransacciones: [],
    showModal: false,
    showModalDownload: false,
    showModalLiq: false,
    showModalPayment: false,
    showModalPeriod: false,
    fechaRef: "",
    proyState: 0,
    auxFechaPagoReciente: "",
    auxFechaPago: "",
    lastUVR: "",
    emptyPayment: false,
    initialPDF: false,
    files: [],
    financialCode: "",
    showModalDeleteCredito: false,
    showModalDeletePayment: false,
    liquidaciones: [],
    expiryDate: null,
    mortgageDate: null,
    landRegistry: "",
    deedNumber: "",
    notary: "",
    exists: false,
    blockMassiveLiq: false,
    paymentYears: [],
    paymentsPerYear: [],
    periodicReportDialog: true,
    excelCreated: false,
    startDate: new Date(),
    endDate: new Date(),
    startDateSelected: false,
    limiteSuperior: new Date(),
    limiteFecha: new Date(),
    fechaUltimoPago: new Date()
  });

  const [documentoAdd, setDocumentoAdd] = useState("");
  const [queryClients, setQueryClients] = useState([]);

  const months = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const handleChangeDocumento = (event) => {
    setDocumentoAdd(event.target.value);
  };
  const setState = (value, cb) => {
    setProps((prevState) => ({ ...prevState, ...value }));
    if (cb) {
      if (typeof cb === "function") cb();
    }
  };

  const handleShowDeletePayment = (e) => {
    e.preventDefault();
    setState({ showModalDeletePayment: true });
  };

  const handleShowLiq = (e) => {
    e.preventDefault();
    setState({ showModalLiq: true });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
  });

  const setCharAt = (str, index, chr) => {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  };

  const definirSegurosModal = (e) => {
    let actUsrToken = window.sessionStorage.getItem("localToken");

    var auxFinFecha = new Date(
      props.proyeccion[props.proyeccionData.length - 1].fechaFin
    );
    var auxIniFecha = new Date(
      props.proyeccion[props.proyeccionData.length - 1].fechaIni
    );
    var auxFinFechaProy = new Date(
      props.proyeccion[props.proyeccionData.length - 1].fechaFin
    );
    var auxIniFechaProy = new Date(
      props.proyeccion[props.proyeccionData.length - 1].fechaIni
    );
    auxFinFechaProy.setMonth(auxFinFechaProy.getMonth() + 1);
    auxIniFechaProy.setMonth(auxIniFechaProy.getMonth() + 1);
    var auxCuota = props.proyeccion[props.proyeccion.length - 1].noCuota;
    auxCuota = auxCuota + 1;
    var auxPago = new Date(props.fechaPago);
    var newProys = [];
    auxPago.setHours(0, 0, 0, 0);
    var mesesAplazados = 0;

    while (true) {
      if (auxPago > auxIniFecha && auxPago <= auxFinFecha) {
        break;
      }
      mesesAplazados++;
      auxIniFecha.setMonth(auxIniFecha.getMonth() + 1);
      auxFinFecha.setMonth(auxFinFecha.getMonth() + 1);
    }

    for (let i = 0; i < mesesAplazados; i++) {
      var auxIniL = new Date(auxIniFechaProy);
      var auxFinL = new Date(auxFinFechaProy);
      var auxProy = {
        liquidado: false,
        presentaPagos: false,
        fechaIni: auxIniL,
        fechaFin: auxFinL,
        noCuota: auxCuota,
        capital: 0,
        interes: 0,
        vlrCuota: 0,
        segVida: 0,
        segTodoRiesgo: 0,
      };
      newProys.push(auxProy);
      auxFinFechaProy.setMonth(auxFinFechaProy.getMonth() + 1);
      auxIniFechaProy.setMonth(auxIniFechaProy.getMonth() + 1);
      auxCuota++;
    }

    var auxProyeccion = props.proyeccion;

    for (let i = 0; i < newProys.length; i++) {
      auxProyeccion.push(newProys[i]);
    }

    axios
      .patch(
        apiMethods.PATCH_CREDITO_ID + props.id,
        { proyeccion: auxProyeccion },
        {
          headers: {
            Authorization: actUsrToken,
          },
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const enviarPagoModal = (e) => {
    submitHandlerPagos(e);
  };

  const updateblockMassiveLiq = async (e, value, setFieldValue) => {
    setFieldValue("blockMassiveLiq", value); //cambio
    let updateResponse = await axios.patch(
      apiMethods.PATCH_CREDITO_ID + props.id,
      { blockMassiveLiq: value }
    );
  };
  const handleClose = (e) => {
    setState({ showModal: false });
  };

  const handleShowDownload = (e) => {
    e.preventDefault();
    setState({ showModalDownload: true });
  };

  const handleCloseDownload = (e) => {
    setState({ showModalDownload: false });
  };

  const handleShowPayment = (e) => {
    e.preventDefault();
    setState({ showModalPayment: true });
  };

  const handleClosePayment = (e) => {
    setState({ showModalPayment: false });
  };

  const handleShowPeriod = (e) => {
    e.preventDefault();
    setState({ showModalPeriod: true });
  };

  const handleClosePeriod = (e) => {
    setState({ showModalPeriod: false });
  };

  const handleChangeDatePickerPayment = (date, dateTarget) => {
    if (dateTarget === "start") {
      setState({
        startDate: date,
        startDateSelected: true
      });
    } else {
      setState({
        endDate: date,
      });
    }
  };

  const handleCloseDeleteCredit = (e) => {
    setState({ showModalDeleteCredito: false });
  };

  const handleShowDeleteCredit = (e) => {
    e.preventDefault();
    setState({ showModalDeleteCredito: true });
  };

  const handleCloseDeletePayment = (e) => {
    setState({ showModalDeletePayment: false });
  };

  const handleCloseLiq = (e) => {
    setState({ showModalLiq: false });
  };

  const fixTimeZone = (originalDate) => {
    if (originalDate) {
      let dateString = originalDate.toISOString();
      if (dateString.charAt(12) != 0 || dateString.charAt(11) != 5) {
        dateString = setCharAt(dateString, 12, "5");
        dateString = setCharAt(dateString, 11, "0");
      }
      return new Date(dateString);
    }
  };

  const onError = (e) => {
    e.preventDefault();
    for (let error in formik.errors) {
      toast.error(formik.errors[error]);
    }
  };

  const validationSchema = yup.object().shape({
    interes: yup.number().min(1, "El valor debe ser mayor a 1"),
    plazo: yup
      .number()
      .min(4, "El plazo mínimo es de 4 meses")
      .max(250, "El plazo máximo es de 250 meses"),
  });

  const formik = useFormik({
    initialValues: props,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    (async () => {
      document.title = "Créditos";
      //let search = getLastItem(window.location.href);
      let actUsrToken = window.sessionStorage.getItem("localToken");
      setState({
        user: JSON.parse(
          atob(window.sessionStorage.getItem("localToken").split(".")[1])
        ),
      });
      if (getLastItem(window.location.href) !== "credits") {
        setState({ exists: true });
        let search = window.sessionStorage.getItem("localDocument");
        let response = await axios.get(apiMethods.GET_CREDITO_ID + search, {
          headers: {
            Authorization: actUsrToken,
          },
        });
        let creditData = {
          deudores: response.data.credits.deudores,
          vlrCapital: formatter.format(response.data.credits.vlrCapital),
          plazo: response.data.credits.plazo,
          moneda: response.data.credits.moneda,
          estado: response.data.credits.estado,
          financialCode: response.data.credits.financialCode,
          fechaCierre:response.data.credits.fechaCierre,
          fechaInicial: response.data.credits.fechaInicial
            ? response.data.credits.fechaInicial.replace(/-/g, "/")
            : null,
          fechaAprobacion: response.data.credits.fechaAprobacion
            ? new Date(response.data.credits.fechaAprobacion)
            : new Date(),
          fechaDesembolso: response.data.credits.fechaDesembolso
            ? new Date(
              new Date(response.data.credits.fechaDesembolso).getTime() +
              Math.abs(
                new Date(
                  response.data.credits.fechaDesembolso
                ).getTimezoneOffset() * 60000
              )
            )
            : null,
          fechaDesembolsoReal: response.data.credits.fechaDesembolsoReal
            ? fixTimeZone(new Date(response.data.credits.fechaDesembolsoReal))
            : new Date(),
          transaccionesData: response.data.credits.transacciones,
          transacciones: response.data.credits.transacciones,
          vlrInicial: formatter.format(response.data.credits.vlrInicial),
          id: response.data.credits.id,
          originalId: response.data.credits._id,
          garantia: response.data.credits.garantia,
          amortizacion: response.data.credits.amortizacion,
          interes: response.data.credits.interes,
          auxProxCorteSave: response.data.credits.fechaProxCorte,
          auxProxCorte: response.data.credits.fechaProxCorte,
          interesMoratorio: response.data.credits.interesMoratorio,
          vlrInteres: formatter.format(response.data.credits.vlrInteres),
          vlrInteresMora: formatter.format(
            response.data.credits.vlrInteresMora
          ),
          liquidacionesResp: response.data.credits.liquidaciones,
          files: response.data.credits.files,
          saldoSegVida: formatter.format(response.data.credits.saldoSegVida),
          saldoSegTodoRiesgo: formatter.format(
            response.data.credits.saldoSegTodoRiesgo
          ),
          seguroVida: response.data.credits.seguroVida,
          seguroTodoRiesgo: response.data.credits.seguroTodoRiesgo,
          vlrSegVida: response.data.credits.vlrSegVida,
          vlrSegTodoRiesgo: response.data.credits.vlrSegTodoRiesgo,
          proyeccionData: response.data.credits.proyeccion,
          proyeccion: response.data.credits.proyeccion,
          liquidacionesData: response.data.credits.liquidaciones,
          liquidaciones: response.data.credits.liquidaciones,
          estadoReal: response.data.credits.estado,
          vlrCuota: response.data.credits.vlrCuota,
          pasoEdicionLocal: response.data.credits.pasoEdicion,
          vlrInicialUVR: response.data.credits.vlrInicialUVR,
          auxTransacciones: response.data.credits.transacciones,
          auxFechaPagoReciente: response.data.credits.fechaPagoReciente,
          mortgageDate: response.data.credits.mortgageDate
            ? response.data.credits.mortgageDate
            : null,
          deedNumber: response.data.credits.deedNumber,
          notary: response.data.credits.notary,
          expiryDate: response.data.credits.expiryDate
            ? response.data.credits.expiryDate
            : null,
          landRegistry: response.data.credits.landRegistry,
          blockMassiveLiq: response.data.credits.blockMassiveLiq
            ? response.data.credits.blockMassiveLiq
            : false,
        };

        formik.resetForm({ values: creditData });
        setState({ ...creditData });

        let deudores = [];

        creditData.deudores.forEach((deudor) => {
          deudores.push(
            new Promise(async (resolve) => {
              let client = await (
                await axios.get(apiMethods.GET_CLIENTE_DOCUMENTO + deudor)
              ).data.client;
              resolve({
                id: client.documento,
                tipoDocumento: client.tipoDocumento,
                nombre: client.nombre,
              });
            })
          );
        });

        setQueryClients(await Promise.all(deudores));

        var detallesData = [];
        var detallesDataUVR = [];

        var transaccionesData = creditData.transaccionesData;
        for (let i = 0; i < transaccionesData.length; i++) {
          var local = transaccionesData[i];

          let current_datetime = new Date(
            local.fechaTx.substring(0, 10).replace(/-/g, "/")
          );
          let formatted_date =
            current_datetime.getDate() +
            "-" +
            months[current_datetime.getMonth()] +
            "-" +
            current_datetime.getFullYear();
          var localData = {
            siCapital: 0,
            siInteresCorriente: 0,
            siInteresMoratorio: 0,
            siSegVida: 0,
            siSegTodoRiesgo: 0,
            moInteresCorriente: 0,
            moInteresMoratorio: 0,
            moSegVida: 0,
            moSegTodoRiesgo: 0,
            apCapital: 0,
            apInteresCorriente: 0,
            apInteresMoratorio: 0,
            apSegVida: 0,
            apSegTodoRiesgo: 0,
            nvCapital: 0,
            nvInteresCorriente: 0,
            nvInteresMoratorio: 0,
            nvSegVida: 0,
            nvSegTodoRiesgo: 0,
          };

          local.fechaTx = formatted_date;
          local.vlrTx = formatter.format(local.vlrTx);
          local.vlrAplicadoCapital = formatter.format(
            toFixed(local.vlrAplicadoCapital, 0)
          );
          local.vlrAplicadoIntCte = formatter.format(
            toFixed(local.vlrAplicadoIntCte, 0)
          );
          local.vlrAplicadoIntMora = formatter.format(
            toFixed(local.vlrAplicadoIntMora, 0)
          );
          local.vlrAplicadoSegVida = formatter.format(
            toFixed(local.vlrAplicadoSegVida, 0)
          );
          local.vlrAplicadoSegTodoRiesgo = formatter.format(
            toFixed(local.vlrAplicadoSegTodoRiesgo, 0)
          );

          localData.siCapital = formatter.format(
            toFixed(creditData.liquidacionesData[i].capital,0)
          );

          localData.moInteresCorriente = formatter.format(
            toFixed(creditData.transaccionesData[i].genInteresCorriente, 0)
          );
          localData.moInteresMoratorio = formatter.format(
            toFixed(creditData.transaccionesData[i].genInteresMoratorio, 0)
          );
          localData.moSegVida = formatter.format(
            toFixed(creditData.transaccionesData[i].genSeguroVida, 0)
          );
          localData.moSegTodoRiesgo = formatter.format(
            toFixed(creditData.transaccionesData[i].genSeguroTodoRiesgo, 0)
          );

          localData.apCapital =
            creditData.transaccionesData[i].vlrAplicadoCapital;
          localData.apInteresCorriente =
            creditData.transaccionesData[i].vlrAplicadoIntCte;
          localData.apInteresMoratorio =
            creditData.transaccionesData[i].vlrAplicadoIntMora;
          localData.apSegVida =
            creditData.transaccionesData[i].vlrAplicadoSegVida;
          localData.apSegTodoRiesgo =
            creditData.transaccionesData[i].vlrAplicadoSegTodoRiesgo;

          localData.nvCapital = formatter.format(
            toFixed(creditData.liquidacionesData[i + 1].capital, 0)
          );
          localData.nvInteresCorriente = formatter.format(
            toFixed(creditData.liquidacionesData[i + 1].interesCorriente, 0)
          );
          localData.nvInteresMoratorio = formatter.format(
            toFixed(creditData.liquidacionesData[i + 1].interesMoratorio, 0)
          );
          localData.nvSegVida = formatter.format(
            toFixed(creditData.liquidacionesData[i + 1].segVida, 0)
          );
          localData.nvSegTodoRiesgo = formatter.format(
            toFixed(creditData.liquidacionesData[i + 1].segTodoRiesgo, 0)
          );

          if (creditData.transaccionesData[i].mesesEnMora != 0) {
            localData.siInteresCorriente = formatter.format(
              toFixed(creditData.transaccionesData[i].dataMesesEnMora[
                creditData.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvInteresCorriente,0)
            );
            localData.siInteresMoratorio = formatter.format(
              toFixed(creditData.transaccionesData[i].dataMesesEnMora[
                creditData.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvInteresMoratorio,0)
            );
            localData.siSegVida = formatter.format(
              toFixed(creditData.transaccionesData[i].dataMesesEnMora[
                creditData.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvSegVida,0)
            );
            localData.siSegTodoRiesgo = formatter.format(
              toFixed(creditData.transaccionesData[i].dataMesesEnMora[
                creditData.transaccionesData[i].dataMesesEnMora.length - 1
              ].nvSegTodoRiesgo,0)
            );
          } else {
            localData.siInteresCorriente = formatter.format(
              toFixed(creditData.liquidacionesData[i].interesCorriente,0)
            );
            localData.siInteresMoratorio = formatter.format(
              toFixed(creditData.liquidacionesData[i].interesMoratorio,0)
            );
            localData.siSegVida = formatter.format(
              toFixed(creditData.liquidacionesData[i].segVida,0)
            );
            localData.siSegTodoRiesgo = formatter.format(
              toFixed(creditData.liquidacionesData[i].segTodoRiesgo,0)
            );
          }

          if (creditData.moneda == "uvr") {
            var localDataUVR = {
              siCapital: 0,
              siInteresCorriente: 0,
              siInteresMoratorio: 0,
              siSegVida: 0,
              siSegTodoRiesgo: 0,
              moInteresCorriente: 0,
              moInteresMoratorio: 0,
              moSegVida: 0,
              moSegTodoRiesgo: 0,
              apCapital: 0,
              apInteresCorriente: 0,
              apInteresMoratorio: 0,
              apSegVida: 0,
              apSegTodoRiesgo: 0,
              nvCapital: 0,
              nvInteresCorriente: 0,
              nvInteresMoratorio: 0,
              nvSegVida: 0,
              nvSegTodoRiesgo: 0,
            };

            localDataUVR.siCapital = formatter.format(
              toFixed(creditData.liquidaciones[i].capitalUVR, 0)
            );
            localDataUVR.moInteresCorriente = formatter.format(
              toFixed(creditData.transaccionesData[i].genInteresCorrienteUVR, 0)
            );
            localDataUVR.moInteresMoratorio = formatter.format(
              toFixed(creditData.transaccionesData[i].genInteresMoratorioUVR, 0)
            );
            localDataUVR.moSegVida = formatter.format(
              creditData.transaccionesData[i].genSeguroVida
            );
            localDataUVR.moSegTodoRiesgo = formatter.format(
              creditData.transaccionesData[i].genSeguroTodoRiesgo
            );

            localDataUVR.apCapital = formatter.format(
              toFixed(creditData.transaccionesData[i].vlrAplicadoCapitalUVR, 0)
            );
            localDataUVR.apInteresCorriente = formatter.format(
              toFixed(creditData.transaccionesData[i].vlrAplicadoIntCteUVR, 0)
            );
            localDataUVR.apInteresMoratorio = formatter.format(
              toFixed(creditData.transaccionesData[i].vlrAplicadoIntMoraUVR, 0)
            );
            localDataUVR.apSegVida =
              creditData.transaccionesData[i].vlrAplicadoSegVida;
            localDataUVR.apSegTodoRiesgo =
              creditData.transaccionesData[i].vlrAplicadoSegTodoRiesgo;
            localDataUVR.nvCapital = formatter.format(
              toFixed(creditData.liquidacionesData[i + 1].capitalUVR, 0)
            );
            localDataUVR.nvInteresCorriente = formatter.format(
              toFixed(
                creditData.liquidacionesData[i + 1].interesCorrienteUVR,
                0
              )
            );
            localDataUVR.nvInteresMoratorio = formatter.format(
              toFixed(
                creditData.liquidacionesData[i + 1].interesMoratorioUVR,
                0
              )
            );
            localDataUVR.nvSegVida = formatter.format(
              creditData.liquidacionesData[i + 1].segVida
            );
            localDataUVR.nvSegTodoRiesgo = formatter.format(
              creditData.liquidacionesData[i + 1].segTodoRiesgo
            );

            if (creditData.transaccionesData[i].mesesEnMora != 0) {
              localDataUVR.siInteresCorriente = formatter.format(
                toFixed(
                  creditData.transaccionesData[i].dataMesesEnMora[
                    creditData.transaccionesData[i].dataMesesEnMora.length - 1
                  ].nvInteresCorrienteUVR,
                  0
                )
              );
              localDataUVR.siInteresMoratorio = formatter.format(
                toFixed(
                  creditData.transaccionesData[i].dataMesesEnMora[
                    creditData.transaccionesData[i].dataMesesEnMora.length - 1
                  ].nvInteresMoratorioUVR,
                  0
                )
              );
              localDataUVR.siSegVida = formatter.format(
                creditData.transaccionesData[i].dataMesesEnMora[
                  creditData.transaccionesData[i].dataMesesEnMora.length - 1
                ].nvSegVida
              );
              localDataUVR.siSegTodoRiesgo = formatter.format(
                creditData.transaccionesData[i].dataMesesEnMora[
                  creditData.transaccionesData[i].dataMesesEnMora.length - 1
                ].nvSegTodoRiesgo
              );
            } else {
              localDataUVR.siInteresCorriente = formatter.format(
                toFixed(creditData.liquidacionesData[i].interesCorrienteUVR, 0)
              );
              localDataUVR.siInteresMoratorio = formatter.format(
                toFixed(creditData.liquidacionesData[i].interesMoratorioUVR, 0)
              );
              localDataUVR.siSegVida = formatter.format(
                creditData.liquidacionesData[i].segVida
              );
              localDataUVR.siSegTodoRiesgo = formatter.format(
                creditData.liquidacionesData[i].segTodoRiesgo
              );
            }
            detallesDataUVR.push(localDataUVR);
          }

          detallesData.push(localData);
          transaccionesData[i] = local;
        }

        var totalTx = [];
        var totalTxUVR = [];

        for (let i = 0; i < creditData.transaccionesData.length; i++) {
          var localTxAbs = [];
          var localOp = [];
          var localTxAbsUVR = [];
          var localOpUVR = [];

          localOp.push(detallesData[i].siCapital);
          localOp.push(detallesData[i].siInteresCorriente);
          localOp.push(detallesData[i].siInteresMoratorio);
          localOp.push(detallesData[i].siSegVida);
          localOp.push(detallesData[i].siSegTodoRiesgo);
          localTxAbs.push(localOp);

          var localOp = [];
          localOp.push(detallesData[i].moInteresCorriente);
          localOp.push(detallesData[i].moInteresMoratorio);
          localOp.push(detallesData[i].moSegVida);
          localOp.push(detallesData[i].moSegTodoRiesgo);
          localTxAbs.push(localOp);

          var localOp = [];
          localOp.push(detallesData[i].apCapital);
          localOp.push(detallesData[i].apInteresCorriente);
          localOp.push(detallesData[i].apInteresMoratorio);
          localOp.push(detallesData[i].apSegVida);
          localOp.push(detallesData[i].apSegTodoRiesgo);
          localTxAbs.push(localOp);

          var localOp = [];
          localOp.push(detallesData[i].nvCapital);
          localOp.push(detallesData[i].nvInteresCorriente);
          localOp.push(detallesData[i].nvInteresMoratorio);
          localOp.push(detallesData[i].nvSegVida);
          localOp.push(detallesData[i].nvSegTodoRiesgo);
          localTxAbs.push(localOp);

          if (creditData.moneda == "uvr") {
            localOpUVR.push(detallesDataUVR[i].siCapital);
            localOpUVR.push(detallesDataUVR[i].siInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].siInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].siSegVida);
            localOpUVR.push(detallesDataUVR[i].siSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            var localOpUVR = [];
            localOpUVR.push(detallesDataUVR[i].moInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].moInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].moSegVida);
            localOpUVR.push(detallesDataUVR[i].moSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            var localOpUVR = [];
            localOpUVR.push(detallesDataUVR[i].apCapital);
            localOpUVR.push(detallesDataUVR[i].apInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].apInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].apSegVida);
            localOpUVR.push(detallesDataUVR[i].apSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            var localOpUVR = [];
            localOpUVR.push(detallesDataUVR[i].nvCapital);
            localOpUVR.push(detallesDataUVR[i].nvInteresCorriente);
            localOpUVR.push(detallesDataUVR[i].nvInteresMoratorio);
            localOpUVR.push(detallesDataUVR[i].nvSegVida);
            localOpUVR.push(detallesDataUVR[i].nvSegTodoRiesgo);
            localTxAbsUVR.push(localOpUVR);

            totalTxUVR.push(localTxAbsUVR);
          }

          totalTx.push(localTxAbs);
        }

        var resTx = [];
        var resTxUVR = [];

        for (let b = 1; b < creditData.transaccionesData.length + 1; b++) {
          var localTx = [];
          localTx.push(
            formatter.format(creditData.liquidacionesData[b].capital)
          );
          localTx.push(
            formatter.format(creditData.liquidacionesData[b].interesCorriente)
          );
          localTx.push(
            formatter.format(creditData.liquidacionesData[b].interesMoratorio)
          );
          localTx.push(
            formatter.format(creditData.liquidacionesData[b].segVida)
          );
          localTx.push(
            formatter.format(creditData.liquidacionesData[b].segTodoRiesgo)
          );

          if (creditData.moneda == "uvr") {
            var localTxUVR = [];
            localTxUVR.push(
              formatter.format(
                toFixed(creditData.liquidacionesData[b].capitalUVR, 2)
              )
            );
            localTxUVR.push(
              formatter.format(
                toFixed(creditData.liquidacionesData[b].interesCorrienteUVR, 2)
              )
            );
            localTxUVR.push(
              formatter.format(
                toFixed(creditData.liquidacionesData[b].interesMoratorioUVR, 2)
              )
            );
            localTxUVR.push(
              formatter.format(creditData.liquidacionesData[b].segVida)
            );
            localTxUVR.push(
              formatter.format(creditData.liquidacionesData[b].segTodoRiesgo)
            );
            resTxUVR.push(localTxUVR);
          }
          resTx.push(localTx);
        }

        var proyeccionAux = creditData.proyeccionData;
        for (let i = 0; i < proyeccionAux.length; i++) {
          var local = proyeccionAux[i];

          var fechaIni = fixTimeZone(new Date(local.fechaIni));
          var fechaFin = fixTimeZone(new Date(local.fechaFin));

          local.periodo =
            fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) +
            " " +
            months[fechaIni.getMonth()] +
            " " +
            fechaIni.getFullYear() +
            " al " +
            fechaFin.getDate() +
            " de " +
            months[fechaFin.getMonth()] +
            " " +
            fechaFin.getFullYear();

          local.capital = formatter.format(local.capital);
          local.interes = formatter.format(local.interes);
          local.vlrCuota = formatter.format(local.vlrCuota);
          local.segVida = formatter.format(local.segVida);
          local.segTodoRiesgo = formatter.format(local.segTodoRiesgo);
          if (creditData.moneda == "uvr") {
            local.vlrCapitalUVR = formatter.format(
              toFixed(local.vlrCapitalUVR, 2)
            );
            local.vlrInteresUVR = formatter.format(
              toFixed(local.vlrInteresUVR, 2)
            );
            local.vlrCuotaUVR = formatter.format(toFixed(local.vlrCuotaUVR, 2));
          }
          proyeccionAux[i] = local;
        }

        var liquidacionesAux = [];
        var liquidacionesAuxUVR = [];

        var liquidacionesData = creditData.liquidacionesData;
        var mesSalidaMora = [];
        var pagosPorCreditos = [];
        var iteradorPagos = [];

        var sumMoras = 0;
        var sumHasPagos = 0;
        for (let i = 0; i < creditData.transaccionesData.length; i++) {
          sumMoras = sumMoras + creditData.transaccionesData[i].mesesEnMora;
          if (creditData.transaccionesData[i].mesesEnMora != 0) {
            for (
              let j = 0;
              j < creditData.transaccionesData[i].mesesEnMora;
              j++
            ) {
              mesSalidaMora.push(i);
            }
          }
        }
        var moved = false;
        var fechaRef;
        if (
          creditData.liquidaciones[0].interesCorriente != 0 ||
          creditData.liquidaciones[0].interesMoratorio != 0 ||
          creditData.liquidaciones[0].segVida != 0 ||
          creditData.liquidaciones[0].segTodoRiesgo != 0
        ) {
          for (let i = 0; i < creditData.proyeccionData.length; i++) {
            if (
              creditData.fechaDesembolso >=
              new Date(creditData.proyeccionData[i].fechaIni) &&
              creditData.fechaDesembolso <=
              new Date(creditData.proyeccionData[i].fechaFin)
            ) {
              if (creditData.proyeccion[0].noCuota == 0) {
                fechaRef = creditData.proyeccion[i];
              } else {
                fechaRef = creditData.proyeccion[i - 1];
              }
              moved = true;
            }
          }
        }

        if (moved == false) {
          for (let i = 0; i < creditData.proyeccionData.length; i++) {
            var acum = 0;
            for (let j = 0; j < creditData.transaccionesData.length; j++) {
              if (creditData.transaccionesData[j].cuota == i) {
                acum++;
              }
            }
            pagosPorCreditos.push(acum);
          }
        } else {
          for (
            let i = fechaRef.noCuota;
            i < creditData.proyeccionData.length;
            i++
          ) {
            var acum = 0;
            for (let j = 0; j < creditData.transaccionesData.length; j++) {
              if (creditData.transaccionesData[j].cuota == i) {
                acum++;
              }
            }
            pagosPorCreditos.push(acum);
          }
        }

        for (let i = 0; i < creditData.proyeccionData.length; i++) {
          if (creditData.proyeccionData[i].presentaPagos) {
            sumHasPagos++;
          }
        }

        var cntFilas = sumHasPagos + sumMoras;
        var liquidacion = {
          periodo: null,
          pagosTotal: 0,
          capital: formatter.format(creditData.liquidacionesData[0].capital),
          interesCorriente: formatter.format(
            creditData.liquidacionesData[0].interesCorriente
          ),
          interesMoratorio: formatter.format(
            creditData.liquidacionesData[0].interesMoratorio
          ),
          segVida: formatter.format(creditData.liquidacionesData[0].segVida),
          segTodoRiesgo: formatter.format(
            creditData.liquidacionesData[0].segTodoRiesgo
          ),
        };
        liquidacionesAux.push(liquidacion);

        if (creditData.moneda == "uvr") {
          var liquidacionUVR = {
            periodo: null,
            pagosTotal: 0,
            capital: formatter.format(
              toFixed(creditData.liquidacionesData[0].capitalUVR, 2)
            ),
            interesCorriente: formatter.format(
              toFixed(creditData.liquidacionesData[0].interesCorrienteUVR, 2)
            ),
            interesMoratorio: formatter.format(
              toFixed(creditData.liquidacionesData[0].interesMoratorioUVR, 2)
            ),
            segVida: formatter.format(
              toFixed(creditData.liquidacionesData[0].segVida, 2)
            ),
            segTodoRiesgo: formatter.format(
              toFixed(creditData.liquidacionesData[0].segTodoRiesgo, 2)
            ),
          };
          liquidacionesAuxUVR.push(liquidacionUVR);
        }

        if (creditData.moneda == "pesos") {
          var cntMora = 0;
          var moraLocal = 0;
          var cntLiquidacion = 0;
          var itPagos = 1;

          for (let i = 0; i < cntFilas; i++) {
            var cuotaLocal = creditData.proyeccionData[i];
            if (moved == true) {
              var cuotaLocal = creditData.proyeccionData[i + fechaRef.noCuota];
            }
            var localSum = 0;
            var localSumUVR = 0;
            var liquidacion = {
              periodo: 0,
              pagosTotal: 0,
              capital: 0,
              interesCorriente: 0,
              interesMoratorio: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };

            let fechaIni = fixTimeZone(new Date(cuotaLocal.fechaIni));
            let fechaFin = fixTimeZone(new Date(cuotaLocal.fechaFin));

            liquidacion.periodo =
              fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) +
              " " +
              months[fechaIni.getMonth()] +
              " " +
              fechaIni.getFullYear() +
              " al " +
              fechaFin.getDate() +
              " de " +
              months[fechaFin.getMonth()] +
              " " +
              fechaFin.getFullYear();

            if (
              creditData.liquidaciones[0].interesCorriente != 0 ||
              creditData.liquidaciones[0].interesMoratorio != 0 ||
              creditData.liquidaciones[0].segVida != 0 ||
              creditData.liquidaciones[0].segTodoRiesgo != 0
            ) {
              for (let j = 0; j < creditData.transaccionesData.length; j++) {
                if (
                  creditData.transaccionesData[j].cuota ==
                  i + fechaRef.noCuota
                ) {
                  localSum =
                    localSum +
                    parseFloat(
                      creditData.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                }
              }
            } else {
              for (let j = 0; j < creditData.transaccionesData.length; j++) {
                if (creditData.transaccionesData[j].cuota == i) {
                  localSum =
                    localSum +
                    parseFloat(
                      creditData.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                }
              }
            }

            if (!cuotaLocal.presentaPagos) {
              iteradorPagos[i] = 0;
              liquidacion.capital = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvCapital
              );
              liquidacion.interesCorriente = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresMoratorio
              );
              liquidacion.segVida = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegTodoRiesgo
              );
              moraLocal++;
              cntMora++;
            } else {
              iteradorPagos[i] = itPagos++;
              cntLiquidacion = cntLiquidacion + pagosPorCreditos[i];
              liquidacion.capital = formatter.format(
                liquidacionesData[cntLiquidacion].capital
              );
              liquidacion.interesCorriente = formatter.format(
                liquidacionesData[cntLiquidacion].interesCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                liquidacionesData[cntLiquidacion].interesMoratorio
              );
              liquidacion.segVida = formatter.format(
                liquidacionesData[cntLiquidacion].segVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                liquidacionesData[cntLiquidacion].segTodoRiesgo
              );
              moraLocal = 0;
            }

            liquidacion.pagosTotal = formatter.format(localSum);
            liquidacionesAux.push(liquidacion);

            if (creditData.moneda == "uvr") {
              liquidacionUVR.pagosTotal = formatter.format(localSumUVR);
              liquidacionesAuxUVR.push(liquidacionUVR);
            }
          }
        } else {
          var cntMora = 0;
          var moraLocal = 0;
          var cntLiquidacion = 0;
          var itPagos = 1;

          for (let i = 0; i < cntFilas; i++) {
            var cuotaLocal = creditData.proyeccionData[i];
            if (moved == true) {
              var cuotaLocal = creditData.proyeccionData[i + fechaRef.noCuota];
            }
            var localSum = 0;
            var localSumUVR = 0;
            var liquidacion = {
              periodo: 0,
              pagosTotal: 0,
              capital: 0,
              interesCorriente: 0,
              interesMoratorio: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };
            var liquidacionUVR = {
              periodo: 0,
              pagosTotal: 0,
              capital: 0,
              interesCorriente: 0,
              interesMoratorio: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };

            let fechaIni = fixTimeZone(new Date(cuotaLocal.fechaIni));
            let fechaFin = fixTimeZone(new Date(cuotaLocal.fechaFin));

            liquidacion.periodo =
              fechaIni.getDate(fechaIni.setDate(fechaIni.getDate() + 1)) +
              " " +
              months[fechaIni.getMonth()] +
              " " +
              fechaIni.getFullYear() +
              " al " +
              fechaFin.getDate() +
              " de " +
              months[fechaFin.getMonth()] +
              " " +
              fechaFin.getFullYear();
            liquidacionUVR.periodo = liquidacion.periodo;

            if (
              creditData.liquidaciones[0].interesCorriente != 0 ||
              creditData.liquidaciones[0].interesMoratorio != 0 ||
              creditData.liquidaciones[0].segVida != 0 ||
              creditData.liquidaciones[0].segTodoRiesgo != 0
            ) {
              for (let j = 0; j < creditData.transaccionesData.length; j++) {
                if (
                  creditData.transaccionesData[j].cuota ==
                  i + fechaRef.noCuota
                ) {
                  localSum =
                    localSum +
                    parseFloat(
                      creditData.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                  localSumUVR =
                    localSumUVR + creditData.transaccionesData[j].vlrTxUVR;
                }
              }
            } else {
              for (let j = 0; j < creditData.transaccionesData.length; j++) {
                if (creditData.transaccionesData[j].cuota == i) {
                  localSum =
                    localSum +
                    parseFloat(
                      creditData.transaccionesData[j].vlrTx.replace(/,/g, "")
                    );
                  localSumUVR =
                    localSumUVR + creditData.transaccionesData[j].vlrTxUVR;
                }
              }
            }

            if (!cuotaLocal.presentaPagos) {
              iteradorPagos[i] = 0;
              liquidacion.capital = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvCapital
              );
              liquidacion.interesCorriente = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvInteresMoratorio
              );
              liquidacion.segVida = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegTodoRiesgo
              );

              liquidacionUVR.capital = formatter.format(
                toFixed(
                  transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                    moraLocal
                  ].nvCapitalUVR,
                  2
                )
              );
              liquidacionUVR.interesCorriente = formatter.format(
                toFixed(
                  transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                    moraLocal
                  ].nvInteresCorrienteUVR,
                  2
                )
              );
              liquidacionUVR.interesMoratorio = formatter.format(
                toFixed(
                  transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                    moraLocal
                  ].nvInteresMoratorioUVR,
                  2
                )
              );
              liquidacionUVR.segVida = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegVida
              );
              liquidacionUVR.segTodoRiesgo = formatter.format(
                transaccionesData[mesSalidaMora[cntMora]].dataMesesEnMora[
                  moraLocal
                ].nvSegTodoRiesgo
              );

              moraLocal++;
              cntMora++;
            } else {
              iteradorPagos[i] = itPagos++;
              cntLiquidacion = cntLiquidacion + pagosPorCreditos[i];
              liquidacion.capital = formatter.format(
                liquidacionesData[cntLiquidacion].capital
              );
              liquidacion.interesCorriente = formatter.format(
                liquidacionesData[cntLiquidacion].interesCorriente
              );
              liquidacion.interesMoratorio = formatter.format(
                liquidacionesData[cntLiquidacion].interesMoratorio
              );
              liquidacion.segVida = formatter.format(
                liquidacionesData[cntLiquidacion].segVida
              );
              liquidacion.segTodoRiesgo = formatter.format(
                liquidacionesData[cntLiquidacion].segTodoRiesgo
              );

              liquidacionUVR.capital = formatter.format(
                toFixed(liquidacionesData[cntLiquidacion].capitalUVR, 2)
              );
              liquidacionUVR.interesCorriente = formatter.format(
                toFixed(
                  liquidacionesData[cntLiquidacion].interesCorrienteUVR,
                  2
                )
              );
              liquidacionUVR.interesMoratorio = formatter.format(
                toFixed(
                  liquidacionesData[cntLiquidacion].interesMoratorioUVR,
                  2
                )
              );
              liquidacionUVR.segVida = formatter.format(
                liquidacionesData[cntLiquidacion].segVida
              );
              liquidacionUVR.segTodoRiesgo = formatter.format(
                liquidacionesData[cntLiquidacion].segTodoRiesgo
              );

              moraLocal = 0;
            }

            liquidacion.pagosTotal = formatter.format(localSum);
            liquidacionUVR.pagosTotal = formatter.format(
              toFixed(localSumUVR, 2)
            );

            liquidacionesAux.push(liquidacion);
            liquidacionesAuxUVR.push(liquidacionUVR);
          }
        }

        response = await axios.get(apiMethods.GET_CREDITO_ID + search);
        creditData = {
          ...creditData,
          liquidacionesDataUVR: liquidacionesAuxUVR,
          liquidacionesData: liquidacionesAux,
          liquidaciones: response.data.credits.liquidaciones,
          proyeccionData: proyeccionAux,
          proyeccion: response.data.credits.proyeccion,
          transaccionesData: transaccionesData,
          transacciones: response.data.credits.transacciones,
          pagosPorCreditos: pagosPorCreditos,
          detalleData: totalTx,
          detalleDataUVR: totalTxUVR,
          resPeriodo: resTx,
          resPeriodoUVR: resTxUVR,
          iteradorPagos: iteradorPagos,
        };

        response = await axios.get(apiMethods.GET_AUXILIAR_ID);
        for (let i = 0; i < creditData.deudores.length; i++) {
          axios
            .get(apiMethods.GET_CLIENTE_DOCUMENTO + creditData.deudores[i], {
              headers: {
                Authorization: actUsrToken,
              },
            })
            .then((response) => {
              const auxArray = props.clientesData;
              auxArray.push(response.data.client);
              setState({ clientesData: auxArray });
            })
            .catch((error) => {
              console.error(error);
            });
        }

        let fechaCierreGX = new Date(creditData.fechaCierre)
        
        let encontrado = false
        let limSuperior, limSuperiorNumber, limFecha, lf, prox
        limSuperiorNumber = transaccionesData.length
        limSuperior = transaccionesData[limSuperiorNumber-1]
        
        if(0 < transaccionesData.length){
          while(!encontrado){
            if(transaccionesData[limSuperiorNumber-1].vlrTx == 0){
              limSuperior = transaccionesData[limSuperiorNumber-1]
              encontrado = true
            }else{limSuperiorNumber--}
          }
          
          prox = fixTimeZone(new Date(limSuperior.fechaProxCorte))

          if(prox.getMonth() == 0){
            lf = (prox.getFullYear() - 1) + "-" + "12" + "-" + prox.getDate()
          }else{lf = prox.getFullYear() + "-" + (prox.getMonth()) + "-" + prox.getDate()}

          limFecha = fixTimeZone(new Date(lf))

        }else{
          limFecha = fixTimeZone(new Date("1998-01-01"))
        }
        
        let ultimoPago, numProyeccion, encontradoProy, fechaUltimoPagoU, auxFecha
        numProyeccion = transaccionesData.length
        encontradoProy = false

        if(0 < transaccionesData.length){

          while(!encontradoProy){
            if(transaccionesData[numProyeccion-1].vlrTx != 0){
              ultimoPago = transaccionesData[numProyeccion-1]
              encontradoProy = true
            }else if(numProyeccion > 1)
            {numProyeccion--}
            else{
              ultimoPago = transaccionesData[transaccionesData.length-1]
              encontradoProy = true
            }
          }
          
          fechaUltimoPagoU = fixTimeZone(new Date(ultimoPago.fechaProxCorte))
          
          if(fechaUltimoPagoU.getMonth() == 0){
            auxFecha = (fechaUltimoPagoU.getFullYear() - 1) + "-" + "12" + "-" + fechaUltimoPagoU.getDate()
          }else{auxFecha = fechaUltimoPagoU.getFullYear() + "-" + (fechaUltimoPagoU.getMonth()) + "-" + fechaUltimoPagoU.getDate()}

          fechaUltimoPagoU = fixTimeZone(new Date(auxFecha))

        }else{
          fechaUltimoPagoU = fixTimeZone(new Date("1998-01-01"))
        }

        let fechaMasVieja, oldestYear, contadorYear
        var years = []
        let pagosXyears = []
        let q = creditData.transacciones.filter(element => element.id == 0).map(obj => {
          fechaMasVieja = new Date(obj.fechaTx)
          if (fechaMasVieja.getFullYear() > 0 && fechaMasVieja.getFullYear() != undefined) {
            oldestYear = fechaMasVieja.getFullYear()
            contadorYear = new Date().getFullYear() - fechaMasVieja.getFullYear()
            for (let i = 0; i <= contadorYear; i++) {
              years.push(oldestYear + i)
              pagosXyears.push(creditData.transacciones.filter((element) => fixTimeZone(new Date(element.fechaTx)).getFullYear() == years[i]))
            }
          }
        })
        setState({
          cntCargasUVR: response.data.auxiliar.cntCargasUVR,
          paymentYears: years,
          paymentsPerYear: pagosXyears,
          limiteSuperior: fechaCierreGX,
          limiteFecha: limFecha,
          fechaUltimoPago: fechaUltimoPagoU
        });
        response = await axios.get(
          apiMethods.GET_UVRLOAD_ID + response.data.auxiliar.cntCargasUVR
        );

        setState({
          lastUVR: response.data.uvrLoad.fecha.toString().replace(/-/g, "/"),
          ...creditData,
        });
        if (window.sessionStorage.getItem("initialPDF") == "true") {console.log("entre en 1508")
          //generateInitialPDF();
        }
      } else {
        if (window.sessionStorage.getItem("clientStored")) {
          try {
            let clientResponse = await axios.get(
              apiMethods.GET_CLIENTE_DOCUMENTO +
              window.sessionStorage.getItem("clientStored")
            );
            if (clientResponse.data.code == 0) {
              let client = {
                id: clientResponse.data.client.documento,
                tipoDocumento: clientResponse.data.client.tipoDocumento,
                nombre: clientResponse.data.client.nombre,
              };
              setQueryClients([client]);
              setDocumentoAdd("");
              window.sessionStorage.removeItem("clientStored");
            } else {
              toast.warn("Cliente no encontrado");
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    })();
  }, []);

  const handleChangeDatePicker = (date) => {
    setState({
      fechaPago: date,
    });
  };

  const changeHandler = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (props.exists) {
      updateCredit(formik.values);
    } else {
      createCredit(formik.values);
    }
  };

  const removeCredit = (e) => {
    e.preventDefault();
    let search = window.sessionStorage.getItem("localDocument");
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;

    for (let i = 0; i < props.deudores.length; i++) {
      axios
        .get(apiMethods.GET_CLIENTE_DOCUMENTO + props.deudores[i], {
          headers: {
            Authorization: actUsrToken,
          },
        })
        .then((response) => {
          var tempArray = response.data.client.creditos;
          tempArray.splice(tempArray.indexOf(search), 1);
          setState({ creditos: tempArray });
          axios
            .patch(
              apiMethods.PATCH_CLIENTE_DOCUMENTO + props.deudores[i],
              props,
              {
                headers: {
                  Authorization: actUsrToken,
                },
              }
            )
            .then((response) => { })
            .catch((error) => {
              console.error(error);
              toast.error("Error al eliminar");
            });
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error al eliminar");
        });
    }

    axios
      .get(apiMethods.GET_USUARIO_ID + localId, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var tempArrayUsr = response.data.users.creditos;
        tempArrayUsr.splice(tempArrayUsr.indexOf(search), 1);
        axios
          .patch(
            apiMethods.PATCH_USUARIO_ID + localId,
            { creditos: tempArrayUsr },
            {
              headers: {
                Authorization: actUsrToken,
              },
            }
          )
          .then((response) => {
            axios
              .post(apiMethods.DELETE_CREDITO_ID + search, null, {
                headers: {
                  Authorization: actUsrToken,
                },
              })
              .then((response) => {
                window.sessionStorage.setItem("success", "true");
                window.sessionStorage.setItem("type", "D");
                history.push("/search/credits");
                window.location.reload();
              })
              .catch((error) => {
                console.error(error);
                toast.error("Error al eliminar");
              });
          })
          .catch((error) => {
            console.error(error);
            toast.error("Error al eliminar");
          });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error al eliminar");
      });
  };

  function createSelectItems() {
    let items = [];
    var hasPayments = [];
    var cntMesesMoraTotal = 0;

    for (let i = 0; i < props.transacciones.length; i++) {
      cntMesesMoraTotal =
        cntMesesMoraTotal + props.transacciones[i].mesesEnMora;
    }

    var sumMora = 0;

    for (let i = 0; i < props.proyeccionData.length; i++) {
      if (props.proyeccionData[i].presentaPagos && props.proyeccionData[i].liquidado) {
        hasPayments.push(i);
      }
    }

    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    for (let i = 0; i < hasPayments.length; i++) {
      var id = "periodo" + hasPayments[i];
      var auxLiq = props.proyeccionData[hasPayments[i]];
      var auxIni = new Date(auxLiq.fechaIni);
      var auxFin = new Date(auxLiq.fechaFin);

      auxIni = fixTimeZone(auxIni);
      auxFin = fixTimeZone(auxFin);

      var auxPeriodo =
        ("0" + auxIni.getDate()).slice(-2) +
        " " +
        months[auxIni.getMonth()] +
        " " +
        auxIni.getFullYear() +
        " al " +
        ("0" + auxFin.getDate()).slice(-2) +
        " " +
        months[auxFin.getMonth()] +
        " " +
        auxFin.getFullYear();
      items.push(
        <option key={auxPeriodo} id={id} value={auxPeriodo}>
          {auxPeriodo}
        </option>
      );
    }
    return items;
  }

  const generateInitialPDF = () => {
    let e = document.createEvent('Event')
    console.log("llegue 1709")
    setState(
      {
        ...props,
        initialPDF: true,
      },
      () => {
        if (props.transacciones.length !== 0) {
          console.log("llegue 1717")
          generatePDF(e);
        } else {console.log("llegue 1719  ")
          relativePayment(e,true);
        }
      }
    );
  };

  const paymentsAplicationDialog = () => {
    setState({
      showPaymentsAplicationDialog: !props.showPaymentsAplicationDialog,
    });
  };

  const createSelectItemsLiq = () => {
    let items = [];
    var hasPayments = [];
    if (props.transacciones.length > 0) {
      var auxReciente = new Date(
        props.transacciones[props.transacciones.length - 1].fechaTx
      );
    } else {
      var auxReciente = new Date(props.fechaDesembolso);
    }
    auxReciente.setHours(0, 0, 0, 0);

    var auxLiq;
    var auxPresenta;

    if (props.transacciones.length > 0) {
      for (let i = props.proyeccion.length - 1; i >= 0; i--) {
        if (props.proyeccion[i].presentaPagos == true) {
          auxPresenta = i;
          break;
        }
      }

      for (let i = auxPresenta; i < props.proyeccion.length; i++) {
        if (props.proyeccion[i].liquidado == false) {
          if (
            new Date().getTime() <
            new Date(props.proyeccion[i].fechaIni).getTime() ||
            new Date().getTime() >
            new Date(props.proyeccion[i].fechaFin).getTime()
          ) {
            auxLiq = props.proyeccion[i];
            break;
          } else {
            break;
          }
        } else {
          if (props.proyeccion[i + 1]) {
            if (
              new Date().getTime() <
              new Date(props.proyeccion[i + 1].fechaIni).getTime() ||
              new Date().getTime() >
              new Date(props.proyeccion[i + 1].fechaFin).getTime()
            ) {
              auxLiq = props.proyeccion[i + 1];
              break;
            } else {
              break;
            }
          } else {
            var fechaIni = new Date(props.proyeccion[i].fechaFin);
            var fechaFin = new Date(fechaIni);
            fechaFin.setMonth(fechaFin.getMonth() + 1);
            var corte = {
              liquidado: false,
              presentaPagos: false,
              fechaIni: fechaIni,
              fechaFin: fechaFin,
              noCuota: 0,
              capital: 0,
              interes: 0,
              vlrCuota: 0,
              segVida: 0,
              segTodoRiesgo: 0,
            };

            if (
              new Date().getTime() < fechaIni.getTime() ||
              new Date().getTime() > fechaFin.getTime()
            ) {
              auxLiq = corte;
              break;
            } else {
              break;
            }
          }
        }
      }
    } else {console.log("else 1811");
      if (props.proyeccion[0]) {
        auxLiq = props.proyeccion[0];
      } else {
        var fechaIni = new Date(props.fechaDesembolso);
        var fechaFin = new Date(fechaIni);
        fechaFin.setMonth(fechaFin.getMonth() + 1);
        var corte = {
          liquidado: false,
          presentaPagos: false,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          noCuota: 0,
          capital: 0,
          interes: 0,
          vlrCuota: 0,
          segVida: 0,
          segTodoRiesgo: 0,
        };
        auxLiq = corte;
      }
    }

    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    if (auxLiq) {
      if (auxLiq.fechaIni instanceof Date) {
        var auxIni = new Date(
          auxLiq.fechaIni.toISOString().substring(0, 10).replace(/-/g, "/")
        );
        var auxFin = new Date(
          auxLiq.fechaFin.toISOString().substring(0, 10).replace(/-/g, "/")
        );
      } else {
        var auxIni = new Date(
          auxLiq.fechaIni.substring(0, 10).replace(/-/g, "/")
        );
        var auxFin = new Date(
          auxLiq.fechaFin.substring(0, 10).replace(/-/g, "/")
        );
      }

      console.log(fixTimeZone(auxIni))
      console.log(fixTimeZone(auxFin))

      auxIni = fixTimeZone(auxIni);
      auxFin = fixTimeZone(auxFin);

      var auxPeriodo =
        ("0" + auxIni.getDate()).slice(-2) +
        " " +
        months[auxIni.getMonth()] +
        " " +
        auxIni.getFullYear() +
        " al " +
        ("0" + auxFin.getDate()).slice(-2) +
        " " +
        months[auxFin.getMonth()] +
        " " +
        auxFin.getFullYear();
      items.push(
        <option
          key={auxPeriodo}
          id={
            auxFin.getFullYear() +
            "/" +
            ("0" + (auxFin.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + auxFin.getDate()).slice(-2)
          }
          value={auxPeriodo}>
          {auxPeriodo}
        </option>
      );console.log(auxPeriodo);
    }
    console.log(items);
    return items;
  };

  const relativePayment = (e, initialPDF) => {
    e.preventDefault();console.log(initialPDF);
    if (initialPDF) {
      var periodo = new Date(createSelectItemsLiq()[0].props.id);
      console.log(periodo);
      window.sessionStorage.setItem("initialPDF", "true");
    } else {
      var de = document.getElementsByClassName("selectorLiq");
      var periodo = new Date(de.estado.selectedOptions[0].id);
    }
    setState(
      {
        fechaPago: periodo,
        auxFechaPago: periodo,
        emptyPayment: true,
      }
    );
    console.log("llegue 1912")
    //handleShow(e);
  };

  useEffect(() => {
    if (props.emptyPayment) {
      const e = new Event('build');
      handleShow(e);
    }
  }, [props.emptyPayment])

  const submitHandlerPagos = () => {
    
    var day = props.lastUVR.substring(0, 2);
    var month = props.lastUVR.substring(3, 5);
    var year = props.lastUVR.substring(8, 12);
    var auxDateParse = new Date(month + "/" + day + "/" + year);
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    var montoPago = document.getElementById("currency-fieldPago")
      ? document.getElementById("currency-fieldPago").value
      : "0";
    montoPago = parseInt(montoPago.replace(/,/g, ""));
    if (montoPago == null || (montoPago == 0 && props.emptyPayment == false)) {
      toast.warn("Monto inválido");
    } else if (props.moneda == "uvr" && props.fechaPago > auxDateParse) {
      toast.warn("Valor UVR inexistente para la fecha indicada");
    } else {
      if (props.emptyPayment) {
        montoPago = 0;
      }
      console.log(montoPago)
      console.log(props.emptyPayment)
      
      let auxFechaPago = new Date(props.fechaPago);
      auxFechaPago.setHours(auxFechaPago.getHours() - 5);
      auxFechaPago = auxFechaPago
        .toISOString()
        .substring(0, 10)
        .replace(/-/g, "/");

      let body = {
        idCredito: props.id,
        montoPago: montoPago,
        fechaPago: auxFechaPago,
        userId: decodedPayload.usrId,
        save: true,
      };
      axios
        .post(apiMethods.CREATE_PAYMENT, body, {
          headers: {
            Authorization: actUsrToken,
          },
        })
        .then((response) => {
          console.log(response)
          
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error al pagar");
        });
    }
  };

  const isDeleteBlocked = (fTX) => {
    if(
        (props.user?.roleId != 1 ? false : true) 
        && validateDelete(fTX)
      ){
      return true;
    }else{return false;}
  }

  const validateDelete = (fTX) => {  
    let fechaTX = new Date(fTX) 

    if(!isNaN(props.limiteSuperior.getTime()) ){
      if(fixTimeZone(fechaTX) <= fixTimeZone(props.limiteSuperior)){
        return true;
      }
      return false;
    }
    return false;
  
  }

  const isBlocked = () => {
    if(
        (props.user?.roleId != 1 ? false : true) 
        && validateFechaPago()
      ){
      return true;
    }else{return false;}
  }

  const validateFechaPago = () => {

    let auxFechaPago = new Date(props.fechaPago) 
    auxFechaPago.setHours(0,0,0,0)
    
    
    let auxLimiteFecha = new Date(props.limiteFecha)
    

    if(fixTimeZone(auxFechaPago) <= fixTimeZone(auxLimiteFecha)){
      return true;
    }

    return false;
  }

  const handleShow = (e) => {
    e.preventDefault();
    let actUsrToken = window.sessionStorage.getItem("localToken");
    if (
      props.liquidaciones.length == 1 &&
      props.estado == "aprobado" &&
      (props.liquidaciones[0].interesCorriente != 0 ||
        props.liquidaciones[0].interesMoratorio != 0 ||
        props.liquidaciones[0].segVida != 0 ||
        props.liquidaciones[0].segTodoRiesgo != 0)
    ) {
      var auxInit = new Date(props.fechaInicial);
    } else {
      var auxInit = new Date(props.fechaDesembolso);
    }

    var year = auxInit.getFullYear();
    var month = auxInit.getMonth() + 1;
    var dt = auxInit.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var parsedDate = dt + "-" + month + "-" + year;

    axios
      .get(apiMethods.GET_UVR_FECHA + parsedDate, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        if (props.moneda == "uvr") {
          var vlrUVRInitAux = response.data.uvr[0].valor;
          var vlrUVRInit = vlrUVRInitAux;
        }

        let search = window.sessionStorage.getItem("localDocument");

        if (
          props.liquidaciones.length == 1 &&
          props.estado == "aprobado" &&
          (props.liquidaciones[0].interesCorriente != 0 ||
            props.liquidaciones[0].interesMoratorio != 0 ||
            props.liquidaciones[0].segVida != 0 ||
            props.liquidaciones[0].segTodoRiesgo != 0)
        ) {
          var interesAnual = props.interes;
          var interesMensualP = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
          var interesMensual = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
          interesMensual = interesMensual * 100;
          var pv = props.vlrInicial.replace(/,/g, "");
          var np = props.plazo;
          var fv = 0;
          var type = 0;
          var ir = interesMensualP;

          var pmt, pvif;
          fv || (fv = 0);
          type || (type = 0);

          if (ir === 0) return -(pv + fv) / np;

          pvif = Math.pow(1 + ir, np);

          pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

          var proyeccion = [];
          var cap = pv;
          var auxInit = new Date(props.fechaInicial);

          for (let i = 0; i < np; i++) {
            var noCuota = i;
            var fechaIni = new Date(auxInit);
            auxInit.setMonth(auxInit.getMonth() + 1);
            var fechaFin = new Date(auxInit);
            var corte = {
              liquidado: false,
              presentaPagos: false,
              fechaIni: fechaIni,
              fechaFin: fechaFin,
              noCuota: noCuota,
              capital: cap,
              interes: int,
              vlrCuota: pmt,
              segVida: 0,
              segTodoRiesgo: 0,
              vlrCuotaUVR: parseFloat(props.vlrCuota) / vlrUVRInit,
              vlrInteresUVR: parseFloat(int) / vlrUVRInit,
              vlrCapitalUVR: parseFloat(cap) / vlrUVRInit,
            };
            var int = cap * interesMensualP;
            cap = cap - (pmt - int);
            proyeccion.push(corte);
          }
          setState(
            {
              proyeccion: proyeccion,
            },
            function () {
              var auxPago = new Date(props.fechaPago);
              var auxProy = new Date(
                proyeccion[proyeccion.length - 1].fechaFin
              );
              auxPago.setHours(0, 0, 0, 0);
              if (auxPago.getTime() > auxProy.getTime()) {
                setState({ showModal: true });
              } else {
                submitHandlerPagos();
              }
            }
          );
        } else {
          setTimeout(() => {

            var auxPago = new Date(props.fechaPago);
          console.log(props)
          var auxProy = new Date(
            props.proyeccion[props.proyeccion.length - 1].fechaFin
          );
          auxPago.setHours(0, 0, 0, 0);
          if (auxPago.getTime() > auxProy.getTime()) {
            setState({ showModal: true });
          } else {
            submitHandlerPagos();
          }

          },5000)
        }
      });
  };

  function formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const closePeriod = () => {
    let body = {
      fechaIni: props.startDate.getFullYear() + "/" + ("0" + (props.startDate.getMonth() + 1)).slice(-2) + "/" + ("0" + props.startDate.getDate()).slice(-2),
      fechaFin: props.endDate.getFullYear() + "/" + ("0" + (props.endDate.getMonth() + 1)).slice(-2) + "/" + ("0" + props.endDate.getDate()).slice(-2)
    };

    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    axios
      .post(apiMethods.CIERRE_PERIODO, body, {})
      .then((response) => {
        Swal.close();
        toast.success(`Cierre de periodo exitoso`);
        // window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error al cerrar el periodo");
      });

  }

  const downloadPaymentsAplication = () => {
    var auxThis = { ...props };

    const formatter = new Intl.NumberFormat("en-US", {
      currency: "USD",
    });

    var tablePagos = [
      [
        {
          text: "Fecha del Pago",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "Valor del Abono",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "APLICACION DE LOS ABONOS A",
          style: "tableHeader",
          colSpan: 5,
          alignment: "center",
        },
        {},
        {},
        {},
        {},
        {
          text: "Saldo a Capital",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
      ],
      [
        "",
        "",
        {
          text: "Seguros",
          style: "tableHeader",
          alignment: "center",
          colSpan: 2,
        },
        "",
        { text: "Ints. Mora", style: "tableHeader", alignment: "center" },
        { text: "Intereses", style: "tableHeader", alignment: "center" },
        { text: "Capital", style: "tableHeader", alignment: "center" },
        {},
      ],
    ];
    props.transacciones.filter(element => (new Date(element.fechaTx).getTime() < props.endDate.getTime()) && (new Date(element.fechaTx).getTime() > props.startDate.getTime())).forEach((pago) => {

      if (pago.vlrTx != 0) {
        
        var fechaPago = fixTimeZone(new Date(pago.fechaTx))
        
        tablePagos.push([
          ("0" + fechaPago.getDate()).slice(-2) +
          "/" +
          ("0" + (fechaPago.getMonth() + 1)).slice(-2) +
          "/" +
          fechaPago.getFullYear(),
          formatter.format(pago.vlrTx),
          "UVR",
          "",
          pago.vlrAplicadoIntMoraUVR
            ? formatter.format(toFixed(pago.vlrAplicadoIntMoraUVR, 0))
            : 0,
          pago.vlrAplicadoIntCteUVR
            ? formatter.format(toFixed(pago.vlrAplicadoIntCteUVR, 0))
            : 0,
          pago.vlrAplicadoCapitalUVR
            ? formatter.format(toFixed(pago.vlrAplicadoCapitalUVR, 0))
            : 0,
          props.liquidacionesResp[pago.id + 1].capitalUVR
            ? formatter.format(
              toFixed(props.liquidacionesResp[pago.id + 1].capitalUVR, 0)
            )
            : 0,
        ]);
        tablePagos.push([
          "",
          "",
          "$",
          formatNumber(
            toFixed(
              parseFloat(pago.vlrAplicadoSegVida) +
              parseFloat(pago.vlrAplicadoSegTodoRiesgo),
              0
            )
          ),
          formatter.format(toFixed(pago.vlrAplicadoIntMora, 0)),
          formatNumber(toFixed(pago.vlrAplicadoIntCte, 0)),
          formatNumber(toFixed(pago.vlrAplicadoCapital, 0)),
          formatNumber(
            toFixed(props.liquidacionesResp[pago.id + 1].capital, 0)
          ),
        ]);
      }
    });

    var title = "AUXILIAR APLICACIÓN DE PAGOS PARA CONTABILIZAR DESDE";
    var docDefinition = {
      pageMargins: [40, 280, 40, 200],
      header: function () {
        return [
          {
            margin: [0, 0, 0, 10],
            canvas: [
              {
                type: "rect",
                x: -15,
                y: -15,
                w: 610,
                h: 76,
                color: "#e92128",
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            canvas: [
              {
                type: "rect",
                x: -15,
                y: -20,
                w: 610,
                h: 8,
                color: "#B9B9B9",
              },
            ],
          },
          {
            text: "INMOBILIARIA",
            style: "title1",
            absolutePosition: { x: 305, y: 5 },
          },
          {
            text: "MOBILIA",
            style: "title2",
            absolutePosition: { x: 305, y: 27 },
          },
          {
            width: 100,
            image: logo,
            alignment: "center",
            absolutePosition: { y: 6, x: -110 },
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 1.3,
            text: "INFORME HISTÓRICO DE APLICACIÓN DE PAGOS",
            style: "textBold",
          },
          {
            margin: [40, 0, 0, 5],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 2.5,
            text: " ",
            style: "redText",
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 2,
            text: "OBLIGACION HIPOTECARIA NUMERO:",
            style: "textBold",
          },
          {
            absolutePosition: { y: 124, x: 405 },
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 150,
                h: 30,
                color: "#DCDDDF",
              },
            ],
          },
          {
            lineHeight: 2,
            text: auxThis.financialCode ? auxThis.financialCode : auxThis.id,
            style: "textBold",
            alignment: "right ",
            absolutePosition: { y: 134, x: 460 },
          },
          {
            margin: [40, 0, 0, 5],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            margin: [40, 10, 40, 0],

            table: {
              body: [
                [
                  {
                    fillColor: "#DCDDDF",
                    border: [false, false, false, false],
                    columns: [
                      {
                        width: 250,
                        stack: [
                          {
                            lineHeight: 1.5,
                            text: "APRECIADO CLIENTE",
                            style: "text",
                          },
                          {
                            lineHeight: 1.5,
                            text: auxThis.clientesData[0].nombre,
                            style: "textBold",
                          },
                          {
                            lineHeight: 1.5,
                            text: auxThis.clientesData[0].direccion,
                            style: "text",
                          },
                        ],
                      },
                      {
                        width: "auto",
                        columns: [
                          {
                            stack: [
                              {
                                lineHeight: 1,
                                text: "Valor del crédito:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Fecha de desembolso:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Tasa de interés:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Plazo:",
                                style: "textBold",
                              },
                              {
                                lineHeight: 1,
                                text: "Sistema de Amortización:",
                                style: "textBold",
                              },
                            ],
                          },
                          {
                            stack: [
                              {
                                lineHeight: 1,
                                text: "$" + auxThis.vlrInicial,
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text: auxThis.fechaDesembolsoReal
                                  .toISOString()
                                  .substring(0, 10),
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text: auxThis.interes + " % " + "E.A.",
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text: auxThis.plazo + " meses",
                                style: "text",
                              },
                              {
                                lineHeight: 1,
                                text:
                                  auxThis.amortizacion +
                                  " en " +
                                  auxThis.moneda,
                                style: "text",
                              },
                            ],
                          },
                        ],
                        columnGap: 10,
                      },
                    ],
                    columnGap: 30,
                  },
                ],
              ],
            },
          },
        ];
      },
      footer: function () {
        return [
          {
            absolutePosition: { y: 60, x: 0 },
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 700,
                h: 78,
                color: "#DCDDDF",
              },
            ],
          },
          {
            lineHeight: 2,
            text: "FORMA DE PAGO",
            style: "textBold",
            alignment: "center",
            absolutePosition: { x: 0, y: 72 },
          },
          {
            absolutePosition: { x: 20, y: 93 },
            margin: [40, 0, 0, 40],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            absolutePosition: { y: 102, x: 0 },
            lineHeight: 1.2,
            text: "Su pago lo puede efectuar en el Banco Caja Social Cuenta convenio No. 11542 \na nombre de Inmobiliaria Mobilia S.A. En cualquier ciudad del país",
            style: "textBold",
            alignment: "center",
          },
          {
            width: 600,
            image: footer,
            alignment: "center",
            absolutePosition: { y: 137, x: 0 },
          },
        ];
      },
      content: [
        {
          style: "tableExample2",
          table: {
            widths: [50, 50, 28, "*", "*", "*", "*", "*"],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: tablePagos,
          },
        },
      ],
      styles: {
        subtitle: {
          fontSize: 10,
          bold: true,
        },
        text: {
          fontSize: 10,
          bold: false,
        },
        textBoldTable: {
          fontSize: 9,
          bold: true,
        },
        headerPago: {
          fontSize: 10,
          bold: false,
        },
        textBold: {
          fontSize: 10,
          bold: true,
        },
        tableHeader: {
          fontSize: 8,
          bold: true,
        },
        tableHeaderColor: {
          fontSize: 8,
          bold: true,
          color: "white",
          fillColor: "#e92128",
        },
        redText: {
          fontSize: 10,
          bold: true,
          color: "#e92128",
        },
        tableExample: {
          fontSize: 8,
          color: "black",
        },
        tableExample2: {
          fontSize: 9,
          color: "black",
        },

        title1: {
          fontSize: 15,
          color: "white",
        },
        title2: {
          fontSize: 20,
          bold: true,
          color: "white",
        },
      },
    };
    pdfMake.createPdf(docDefinition).download("APLICACIÓN PAGOS.pdf");
  }

  const delPago = () => {
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    console.log(window.sessionStorage.getItem("initialPDF"));
    if (props.transacciones.length == 1) {
      
      var auxTx = [];
      var lastTx = props.transacciones[props.transacciones.length - 1];console.log(props.transacciones[props.transacciones.length - 1]);
      var demoAuxTx = [];

      if(window.sessionStorage.getItem("initialPDF") == "true"){
        var auxCorte = new Date(props.fechaDesembolso);console.log(auxCorte);
        var auxProxCorte = new Date(auxCorte);console.log(auxCorte);
        auxCorte.setMonth(auxCorte.getMonth() - 1);console.log(auxCorte);//Problema (Que pasa si es enero)
        var localTx = {
          fechaTx: auxCorte,
          dias: 0,
          fechaProxCorte: auxProxCorte,
          cuota: 0,
          vlrCapProyectado: lastTx.vlrCapProyectado,
          vlrCapProyectadoUVR: lastTx.vlrCapProyectadoUVR,
        };
      }else{
        var auxCorte = new Date(props.fechaDesembolso);console.log(auxCorte);
        auxCorte.setMonth(auxCorte.getMonth() + 1);console.log(auxCorte);//Problema (Que pasa si es diciembre)
        var auxProxCorte = new Date(auxCorte);console.log(auxCorte);
        var localTx = {
          fechaTx: props.fechaDesembolso,
          dias: 0,
          fechaProxCorte: auxProxCorte,
          cuota: 0,
          vlrCapProyectado: lastTx.vlrCapProyectado,
          vlrCapProyectadoUVR: lastTx.vlrCapProyectadoUVR,
        };
      }
      
      console.log(localTx);
      var auxLiq = props.liquidaciones[props.liquidaciones.length - 2];

      var delLiq = props.liquidaciones;
      var localProy = props.proyeccion;
      delLiq.splice(delLiq.length - 1, 1);
      console.log(props.pagosPorCreditos[
        props.auxTransacciones[props.auxTransacciones.length - 1].cuota
      ]);
      console.log(props.pagosPorCreditos[
        props.auxTransacciones[props.auxTransacciones.length - 1].cuota
      ]);
      if (
        props.pagosPorCreditos[
        props.auxTransacciones[props.auxTransacciones.length - 1].cuota
        ] == 1
      ) {
        localProy[
          props.auxTransacciones[props.auxTransacciones.length - 1].cuota
        ].presentaPagos = false;
        localProy[
          props.auxTransacciones[props.auxTransacciones.length - 1].cuota
        ].liquidado = false;
      }

      for (let i = 0; i < props.proyeccion.length; i++) {
        if (props.proyeccionData[i].noCuota == localTx.cuota) {
          cntPagosPerCuota++;
        }
      }
      if (cntPagosPerCuota > 0) {
        localProy.presentaPagos = false;
        localProy.liquidado = false;
      }
    } else {
      var auxTx = props.transacciones;
      var auxTxSave = auxTx[auxTx.length - 1];
      auxTx.splice(auxTx.length - 1, 1);

      var demoAuxTx = auxTx;
      var localTx = auxTx[auxTx.length - 1];
      var auxLiq = props.liquidaciones[props.liquidaciones.length - 2];
      var delLiq = props.liquidaciones;
      var localProy = props.proyeccion;
      delLiq.splice(delLiq.length - 1, 1);

      if (
        props.pagosPorCreditos[
        props.auxTransacciones[props.auxTransacciones.length - 1].cuota
        ] == 1
      ) {
        localProy[
          props.auxTransacciones[props.auxTransacciones.length - 1].cuota
        ].presentaPagos = false;
        localProy[
          props.auxTransacciones[props.auxTransacciones.length - 1].cuota
        ].liquidado = false;
      }
      var cntPagosPerCuota = 0;
      for (let i = 0; i < props.proyeccion.length; i++) {
        if (props.proyeccionData[i].noCuota == auxTxSave.cuota) {
          cntPagosPerCuota++;
        }
      }
      if (cntPagosPerCuota > 0) {
        localProy[auxTxSave.cuota].presentaPagos = false;
        localProy[auxTxSave.cuota].liquidado = false;
      }
    }
    console.log({
      vlrCapital: auxLiq.capital,
      fechaPagoReciente: localTx.fechaTx,//
      transacciones: demoAuxTx,
      vlrInteres: auxLiq.interesCorriente,
      vlrInteresMora: auxLiq.interesMoratorio,
      diasAcumulados: localTx.dias,
      fechaProxCorte: localTx.fechaProxCorte,//
      liquidaciones: delLiq,
      vlrCapProyectado: localTx.vlrCapProyectado,
      cuota: localTx.cuota + 1,
    });
    axios
      .patch(
        apiMethods.PATCH_CREDITO_ID + props.id,
        {
          vlrCapital: auxLiq.capital,
          fechaPagoReciente: localTx.fechaTx,
          transacciones: demoAuxTx,
          vlrInteres: auxLiq.interesCorriente,
          vlrInteresMora: auxLiq.interesMoratorio,
          diasAcumulados: localTx.dias,
          fechaProxCorte: localTx.fechaProxCorte,
          liquidaciones: delLiq,
          vlrCapProyectado: localTx.vlrCapProyectado,
          cuota: localTx.cuota + 1,
        },
        {
          headers: {
            Authorization: actUsrToken,
          },
        }
      )
      .then(async (response) => {
        await axios.patch(apiMethods.PATCH_CREDITO_ID + props.id, {proyeccion: localProy});
        axios
          .get(apiMethods.GET_USUARIO_ID + decodedPayload.usrId, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            var localTxUsr = response.data.users.transacciones;
            localTxUsr.pop();
            try {
              //await axios.patch(apiMethods.PATCH_USUARIO_ID + decodedPayload.usrId, { transacciones: localTxUsr, }, { headers: { Authorization: actUsrToken } })
              if (props.moneda == "uvr") {
                axios
                  .patch(
                    apiMethods.PATCH_CREDITO_ID + props.id,
                    {
                      vlrCapitalUVR: auxLiq.capitalUVR,
                      vlrInteresUVR: auxLiq.interesCorrienteUVR,
                      vlrInteresMoraUVR: auxLiq.interesMoratorioUVR,
                      vlrCapProyectadoUVR: localTx.vlrCapProyectadoUVR,
                    },
                    {
                      headers: {
                        Authorization: actUsrToken,
                      },
                    }
                  )
                  .then((response) => {
                    window.location.reload();
                  });
              } else {
                window.location.reload();
              }
            } catch (error) {
              console.error(error);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  function buildTableBody(data, columns) {
    var body = [];

    var columnsText = []

    columns.forEach(c => {
      columnsText.push({text:c,fontSize:9,bold:true})
    })

    //body.push(columnsText);

    data.forEach(function(row) {
        var dataRow = [];

        columns.forEach(function(column) {
            dataRow.push(row[column]);
        })

        body.push(dataRow);
    });

    return body;
}

function table(data, columns, id) {
    if(id == 'A'){
      return {
        table: {
            widths: [70,70,70,70],
            alignment:'center',
            //headerRows: 1,
            body: buildTableBody(data, columns)
        }
      };
    }else if(id == 'B'){
      return {
        table: {
            //headerRows: 1,
            alignment:'center',
            body: buildTableBodyB(data, columns),alignment: "center"
        }
      };
    }else if(id == 'C'){
      return {
        table: {
            widths: [55,55],
            alignment:'center',
            //headerRows: 1,
            body: buildTableBodyC(data, columns),alignment: "center"
        }
      };
    }else if(id == 'D'){
      return {
        table: {
            widths: [70,70,70,70],
            alignment:'center',
            //headerRows: 1,
            body: buildTableBodyD(data, columns),alignment: "center"
        }
      };
    }else{
      return {
        table: {
            widths: [55,55],
            //headerRows: 1,
            body: buildTableBodyD(data, columns),alignment: "center"
        }
    };
  }
    
}

function buildTableBodyC(data, columns) {
  var body = [];
  var auxC = []
  auxC.push({text:"CUOTA APROX. EN PESOS",fontSize:9,bold:true,alignment: "center"})
  auxC.push({text:"SALDO EN PESOS",fontSize:9,bold:true,alignment: "center"})
  
  //body.push(auxC);

  data.forEach(function(row) {
      var dataRow = [];

      columns.forEach(function(column) {
          dataRow.push(row[column]);
      })

      body.push(dataRow);
  });

  return body;
}

function buildTableBodyB(data, columns) {
  var body = [];
  var auxC = []
  auxC.push({text:"NUMERO NUMERO NUMERO",fontSize:9,color:"white",bold:true,alignment: "center"})
  
  //body.push(auxC);

  data.forEach(function(row) {
      var dataRow = [];

      columns.forEach(function(column) {
          dataRow.push(row[column]);
      })

      body.push(dataRow);
  });

  return body;
}

function buildTableBodyD(data, columns) {
  var body = [];

    var columnsText = []

    columns.forEach(c => {
      columnsText.push({text:c,fontSize:8,bold:true,alignment:'center'})
    })

    body.push(columnsText);

  return body;
}

  const generatePDFProyeccion = (e) =>{

    var auxProps = props;
    let periodos = [];
    let capitalSaldo = [];
    let abonos = [];

    //console.log(props)
    //console.log(props.transaccionesData)
    //console.log(props.detalleData)
    //console.log(props.detalleDataUVR)
    //tener los periodos de cada cuota
    props.transaccionesData.forEach(transaccion =>{
      if(transaccion.vlrTx == 0){
        periodos.push(transaccion.fechaProxCorte)
      }
    })
    //Ultimo mes de los periodos a validar
    let ultimoPeriodo = fixTimeZone(new Date(periodos[periodos.length-1]))

    if(
      ultimoPeriodo.getMonth() == 0 &&
      (ultimoPeriodo.getDate() == 30 ||
      ultimoPeriodo.getDate() == 31)  
    )
    {
      periodos.push(
        ultimoPeriodo.getFullYear() +
        "-" + (ultimoPeriodo.getMonth() + 2) +
        "-"+ "29"
      )
    }else if(ultimoPeriodo.getMonth() == 11){
      periodos.push(
        ultimoPeriodo.getFullYear() +
        "-" + "0" +
        "-"+ ultimoPeriodo.getDate()
      )
      
    }else{
      periodos.push(
        ultimoPeriodo.getFullYear() +
        "-" + (ultimoPeriodo.getMonth() + 2) +
        "-"+ ultimoPeriodo.getDate()
      )
    }
    
    let iterator = 0
    let ultimo = []
    
    for(iterator;iterator < periodos.length-1;iterator++){
        let existe = false;
        props.transaccionesData.filter(
          element => fixTimeZone(new Date(periodos[iterator])) <= fixTimeZone(new Date(element.fechaProxCorte)) &&
          fixTimeZone(new Date(periodos[iterator+1])) > fixTimeZone(new Date(element.fechaProxCorte))
        ).forEach(transaccion => {
          if(!existe){
            //var n = transaccion.vlrTx
            var nn = transaccion.vlrTx
            if(props.moneda == "uvr"){
              nn = transaccion.vlrTxUVR
            }
            var n = nn + ""
            var p = Number(n.replace(/,/g, ""))
            //console.log(p)
            abonos.push(p)
            ultimo.push(transaccion)
            existe = true;
          }else{
            var n = transaccion.vlrTx + ""
            if(props.moneda == "uvr"){
              n = transaccion.vlrTxUVR + ""
            }
            var p = Number(n.replace(/,/g, ""))
            //console.log(p)
            abonos[iterator] = abonos[iterator] + p
            ultimo[iterator] = transaccion 
          }
        })
    }
    
    /*console.log(abonos)
    console.log(ultimo)
    console.log("prueba")
    console.log(props.detalleData[ultimo[0].id])
    console.log(props.detalleDataUVR[ultimo[0].id])
    console.log(props.detalleData[ultimo[1].id][3][0])
    console.log(props.detalleDataUVR[ultimo[1].id][3][0])/props.moneda == "uvr"

    console.log(props.detalleData.length)*/

    var text = {
      text: "",
      style: "headerPago",
      margin: [0, 10, 0, 0],
    };

    var rows = []

    for(var i = 0;i < abonos.length; i++){
      rows.push({
                noCuota:"",
                cuota:"",
                interes:"",
                abono:abonos[i],
                saldo:props.detalleData[ultimo[i].id][3][0],
                cuotaComp:"",
                saldoComp:props.detalleData[ultimo[i].id][3][0]
              })
    }

    var datos_centrales = []
    var altura_cuota = []
    var comparacion = []
    var nPri = []
    var it = 0

    //var cntNeededPayments = neededPayments[0].id;
    for(var i = 0;i < periodos.length-1; i++){
      //.filter(proyeccion => fixTimeZone(new Date(proyeccion.fechaFin)) == fixTimeZone(new Date(periodos[0])))
      props.proyeccionData.filter(proyeccion => (fixTimeZone(new Date(proyeccion.fechaFin)) >= fixTimeZone(new Date(periodos[i])) && 
      fixTimeZone(new Date(proyeccion.fechaFin)) < fixTimeZone(new Date(periodos[i+1])))
      ).forEach(element => {
        altura_cuota.push(
          {"ALTURA CUOTA":{text:element.noCuota,fontSize:9}}
        )
        if(props.moneda == "uvr"){
          var interesesUVR = 0
          //console.log(element)
          var corr =  props.detalleDataUVR[ultimo[it].id][0][1] + ""
          var morr = props.detalleDataUVR[ultimo[it].id][0][2] + ""
          var c = Number(corr.replace(/,/g, ""))
          var m = Number(morr.replace(/,/g, ""))
          interesesUVR = c + m

          datos_centrales.push(
            {
              //{text:"$" + element.vlrCuota,fontSize:8}
              //"$" + element.vlrCuota
              //"ALTURA DE CUOTA":element.noCuota,
              "CUOTA APROXIMADA EN UVR":{text: element.vlrCuotaUVR + " UVR",fontSize:9},
              "INTERESES EN UVR": {text:interesesUVR.toLocaleString(undefined, {maximumFractionDigits:0}) + " UVR",fontSize:9},
              "ABONO EN UVR": {text : abonos[it].toLocaleString(undefined, {maximumFractionDigits:0}) + " UVR",fontSize:9},
              "SALDO EN UVR": {text: props.detalleDataUVR[ultimo[it].id][3][0] + " UVR",fontSize:9},
              //"CUOTA": "$" + element.vlrCuota,
              //"SALDO": "$" + props.detalleData[ultimo[it].id][3][0]
            }
          )
        }else{
          var interesesPesos = 0
          //console.log(element)
          var corr =  props.detalleData[ultimo[it].id][0][1] + ""
          var morr = props.detalleData[ultimo[it].id][0][2] + ""
          var c = Number(corr.replace(/,/g, ""))
          var m = Number(morr.replace(/,/g, ""))
          interesesPesos = c + m
          datos_centrales.push(
            {
              //{text:"$" + element.vlrCuota,fontSize:8}
              //"$" + element.vlrCuota
              //"ALTURA DE CUOTA":element.noCuota,
              "CUOTA APROXIMADA EN PESOS":{text:"$" + element.vlrCuota,fontSize:9},
              "INTERESES EN PESOS": {text:"$" + interesesPesos.toLocaleString(undefined, {maximumFractionDigits:0}),fontSize:9},
              "ABONO EN PESOS": {text : "$" + abonos[it].toLocaleString(undefined, {maximumFractionDigits:0}),fontSize:9},
              "SALDO EN PESOS": {text:"$" + props.detalleData[ultimo[it].id][3][0],fontSize:9},
              //"CUOTA": "$" + element.vlrCuota,
              //"SALDO": "$" + props.detalleData[ultimo[it].id][3][0]
            }
          )
        }
        comparacion.push(
          {
            "CUOTA":{text: "$" + element.vlrCuota,fontSize:9},
            "SALDO": {text: "$" + props.detalleData[ultimo[it].id][3][0],fontSize:9} 
          }
        )
        nPri.push(
          {
            "CUOTA APROX. EN PESOS":{text:"$" + element.vlrCuota,fontSize:9}
          }
        )
        it++
      })
    }

    
    /*console.log("HOLAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    console.log(nPri)
    console.log(nPri[0])*/

    var textPesos = [{text:'ALTURA CUOTA',alignment:'center',fontSize:12,bold:true},
                      [{text:'PESOS',alignment:'center',fontSize:12,bold:true},table(datos_centrales,[//'ALTURA DE CUOTA',
                      'CUOTA APROX. EN PESOS',
                      'INTERESES EN PESOS',
                      'ABONO EN PESOS',
                      'SALDO EN PESOS',
                      //  'CUOTA','SALDO'
                      ],'D')],
                      [{text:'PESOS',alignment:'center',fontSize:12,bold:true},table(comparacion,['CUOTA', 'SALDO'],'E')]]
    var textUVR = [{text:'ALTURA CUOTA',alignment:'center',fontSize:12,bold:true},
                    [{text:'PESOS',alignment:'center',fontSize:12,bold:true},table(datos_centrales,[//'ALTURA DE CUOTA',
                    'CUOTA APROX. EN UVR',
                    'INTERESES EN UVR',
                    'ABONO EN UVR',
                    'SALDO EN UVR',
                  //  'CUOTA','SALDO'
                    ],'D')],
                    [{text:'PESOS',alignment:'center',fontSize:12,bold:true},table(comparacion,['CUOTA', 'SALDO'],'E')]]
    var headLine

    var columnasHeaderDoc = []

    var filasHeaderDoc = []

    if(props.moneda == "uvr"){
      headLine = textUVR 
      var tablaProy = table(datos_centrales,[//'ALTURA DE CUOTA',
                              'CUOTA APROXIMADA EN UVR',
                              'INTERESES EN UVR',
                              'ABONO EN UVR',
                              'SALDO EN UVR',
                            //  'CUOTA','SALDO'
                            ],'A')

      columnasHeaderDoc.push(
      {
        lineHeight: 1,
        text: "Plazo Proyectado",
        style: "textBold",
      },
      {
        lineHeight: 1,
        text: "Capital UVR",
        style: "textBold",
      },
      {
        lineHeight: 1,
        text: "Capital Pesos",
        style: "textBold",
      },
      {
        lineHeight: 1,
        text: "Cuota UVR ",
        style: "textBold",
      },
      {
        lineHeight: 1,
        text: "Tasa ",
        style: "textBold",
      },
      )

      filasHeaderDoc.push(
        {
          lineHeight: 1,
          text:
          periodos.length-1 + " Meses",
          style: "text",
        },
        {
          lineHeight: 1,
          text: auxProps.vlrInicialUVR.toLocaleString(undefined, {maximumFractionDigits:1}) + " UVR",
          style: "text",
        },
        {
          lineHeight: 1,
          text: "$" + auxProps.vlrInicial,
          style: "text",
        },
        {
          lineHeight: 1,
          text: props.proyeccionData[0].vlrCuotaUVR + " UVR",
          style: "text",
        },
        {
          lineHeight: 1,
          text:
            auxProps.interes + " % " + "E.A.",
          style: "text",
        },
      )

    }else{
      headLine = textPesos
      var tablaProy = table(datos_centrales,[//'ALTURA DE CUOTA',
                              'CUOTA APROXIMADA EN PESOS',
                              'INTERESES EN PESOS',
                              'ABONO EN PESOS',
                              'SALDO EN PESOS',
                            //  'CUOTA','SALDO'
                            ],'A')

      columnasHeaderDoc.push(
        {
          lineHeight: 1,
          text: "Plazo Proyectado",
          style: "textBold",
        },
        {
          lineHeight: 1,
          text: "Capital Pesos",
          style: "textBold",
        },
        {
          lineHeight: 1,
          text: "Cuota PESOS ",
          style: "textBold",
        },
        {
          lineHeight: 1,
          text: "Tasa ",
          style: "textBold",
        },)
  
        filasHeaderDoc.push(
          {
            lineHeight: 1,
            text:
            periodos.length-1 + " Meses",
            style: "text",
          },
          {
            lineHeight: 1,
            text: "$" + auxProps.vlrInicial,
            style: "text",
          },
          {
            lineHeight: 1,
            text: "$" + props.proyeccionData[0].vlrCuota,
            style: "text",
          },
          {
            lineHeight: 1,
            text:
              auxProps.interes + " % " + "E.A.",
            style: "text",
          },
        )
    }
    
    var tablaAltura = table(altura_cuota,['ALTURA CUOTA'],'B')
    var tablaComparacion = table(comparacion,['CUOTA', 'SALDO'],'C')

    var docDefinition = {
      pageMargins: [40, 280, 40, 200],
      header: function () {
        return [
          {
            margin: [0, 0, 0, 10],
            canvas: [
              {
                type: "rect",
                x: -15,
                y: -15,
                w: 610,
                h: 76,
                color: "#e92128",
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            canvas: [
              {
                type: "rect",
                x: -15,
                y: -20,
                w: 610,
                h: 8,
                color: "#B9B9B9",
              },
            ],
          },
          {
            text: "INMOBILIARIA",
            style: "title1",
            absolutePosition: { x: 305, y: 5 },
          },
          {
            text: "MOBILIA",
            style: "title2",
            absolutePosition: { x: 305, y: 27 },
          },
          {
            width: 100,
            image: logo,
            alignment: "center",
            absolutePosition: { y: 6, x: -110 },
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 1.3,
            text: "PROYECCIÓN",
            style: "textBold",
          },
          {
            margin: [40, 0, 0, 5],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 2.5,
            text: "MOBILIA",
            style: "redText",
          },
          {
            margin: [40, 0, 0, 0],
            lineHeight: 2,
            text: "OBLIGACION HIPOTECARIA NUMERO:",
            style: "textBold",
          },
          {
            absolutePosition: { y: 124, x: 405 },
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 150,
                h: 30,
                color: "#DCDDDF",
              },
            ],
          },
          {
            lineHeight: 2,
            text: auxProps.financialCode
              ? auxProps.financialCode
              : auxProps.id,
            style: "textBold",
            alignment: "right ",
            absolutePosition: { y: 134, x: 460 },
          },
          {
            margin: [40, 0, 0, 5],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            margin: [40, 10, 40, 0],

            table: {
              body: [
                [
                  {
                    fillColor: "#DCDDDF",
                    border: [false, false, false, false],
                    columns: [
                      {
                        width: 300,
                        stack: [
                          {
                            lineHeight: 1.5,
                            text: "APRECIADO CLIENTE",
                            style: "text",
                          },
                          {
                            lineHeight: 1.5,
                            text: auxProps.clientesData[0].nombre,
                            style: "textBold",
                          },
                          {
                            lineHeight: 1.5,
                            text: auxProps.clientesData[0].direccion,
                            style: "text",
                          },
                        ],
                      },
                      {
                        width: "auto",
                        columns: [
                          {
                            stack: [
                              
                              columnasHeaderDoc,//AGREGAR TASA y OBLIGACIÓN
                              //PARA UVR LA FILA DE CAPITAL UVR ENCIMA DE CAPITAL PESOS, CAMBIAR CUOTA PESOS CON UVR, COLOCAR EL VALOR DEL UVR Y LA TEORICA
                            ],
                          },
                          {
                            stack: [
                              filasHeaderDoc,
                            ],
                          },
                        ],
                        columnGap: 10,
                      },
                    ],
                    columnGap: 30,
                  },
                ],
              ],
            },
          },
          {
            margin: [40, 10, 40, 0],
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    fontSize: 10,
                    fillColor: "#DCDDDF",
                    border: [false, false, false, false],
                    alignment: "center",
                    margin: [0, 7, 0, 7],
                    columns: [
                      /*{
                        text: "PAGOS RECIBIDOS EN ESTE PERIODO",
                        bold: true,
                      },*/
                    ],
                  },
                ],
              ],
            },
          },
        ];
      },
      footer: function () {
        return [
          {
            absolutePosition: { y: 60, x: 0 },
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: 700,
                h: 78,
                color: "#DCDDDF",
              },
            ],
          },
          {
            lineHeight: 2,
            text: "FORMA DE PAGO",
            style: "textBold",
            alignment: "center",
            absolutePosition: { x: 0, y: 72 },
          },
          {
            absolutePosition: { x: 20, y: 93 },
            margin: [40, 0, 0, 40],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 3,
                lineColor: "red",
              },
            ],
          },
          {
            absolutePosition: { y: 102, x: 0 },
            lineHeight: 1.2,
            text: "Su pago lo puede efectuar en el Banco Caja Social Cuenta convenio No. 11542 \na nombre de Inmobiliaria Mobilia S.A. En cualquier ciudad del país",
            style: "textBold",
            alignment: "center",
          },
          {
            width: 600,
            image: footer,
            alignment: "center",
            absolutePosition: { y: 137, x: 0 },
          },
        ];
      },
      content: [
        {
          margin: [0, 10, 0, 0],
          text: "Saldo, cuota y proyecciones del credito:",
          style: "text",
        },
        {
          table: {
            alignment:'center',
            headerRows: 1,
            widths: [55,325,130],
            body: [
              headLine,
              [
                //{//table:{
                  //body:[
                    [ 
                      //{ text: "ALTURA", style: "textBoldTable" },
                      tablaAltura
                    ],
                  //]
               // }
                //},
                [
                  tablaProy,
                ],
                [
                  tablaComparacion,
                ]
              ]
            ],alignment: "center"
          }  
        },
      ],
      styles: {
        subtitle: {
          fontSize: 10,
          bold: true,
        },
        text: {
          fontSize: 10,
          bold: false,
        },
        textBoldTable: {
          fontSize: 9,
          bold: true,
        },
        headerPago: {
          fontSize: 10,
          bold: false,
        },
        textBold: {
          fontSize: 10,
          bold: true,
        },
        tableHeader: {
          fontSize: 8,
          bold: true,
        },
        tableHeaderColor: {
          fontSize: 8,
          bold: true,
          color: "white",
          fillColor: "#e92128",
        },
        redText: {
          fontSize: 10,
          bold: true,
          color: "#e92128",
        },
        tableExample: {
          fontSize: 8,
          color: "black",
        },
        tableExample2: {
          fontSize: 9,
          color: "black",
        },

        title1: {
          fontSize: 15,
          color: "white",
        },
        title2: {
          fontSize: 20,
          bold: true,
          color: "white",
        },
      },
    };

    pdfMake.createPdf(docDefinition).download("prueba.pdf");

  }
  
  const generatePDF = (e) => {
    e.preventDefault();

    let actUsrToken = window.sessionStorage.getItem("localToken");
    var e = document.getElementsByClassName("selectorDownload");
    console.log("entre en 3593")
    if (window.sessionStorage.getItem("initialPDF") == "true") {
      var periodo = 0;
    } else {
      var periodo = parseFloat(
        e.estado.selectedOptions[0].id.replace(/\D/g, "")
      );
    }
    var savePeriodo = periodo;

    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    //console.log(createSelectItemsLiq());console.log(createSelectItemsLiq()[0]);console.log(props);
    if (window.sessionStorage.getItem("initialPDF") == "true") {console.log("entre en 3618");
      var auxDia = e.estado.selectedOptions[0].value.substring(15, 17);
      var auxMesText =  e.estado.selectedOptions[0].value.substring(18, 21);
      var auxMes;
      var auxAnio = e.estado.selectedOptions[0].value.substring(22, 26);
    } else {
      var auxDia = e.estado.selectedOptions[0].value.substring(15, 17);
      var auxMesText = e.estado.selectedOptions[0].value.substring(18, 21);
      var auxMes;
      var auxAnio = e.estado.selectedOptions[0].value.substring(22, 26);
    }

    for (let i = 0; i < months.length; i++) {
      if (months[i] == auxMesText) {
        auxMes = i;
      }
    }
    auxMes++;

    if (window.sessionStorage.getItem("initialPDF") == "true") {console.log("entre en 3637")
      auxMes--;
    }

    if (auxMes == 1) {
      auxMes = 12
      auxAnio = (parseFloat(auxAnio) - 1).toString();
    }

    if (auxMes < 10) {
      auxMes = "0" + auxMes;
    }

    let auxIni = fixTimeZone(new Date(props.proyeccion[periodo].fechaIni));
    let auxFin = fixTimeZone(new Date(props.proyeccion[periodo].fechaFin));

    var fechasPeriodo =
      ("0" + (auxIni.getDate() + 1)).slice(-2) +
      " " +
      months[auxIni.getMonth()] +
      " " +
      auxIni.getFullYear() +
      " al " +
      ("0" + auxFin.getDate()).slice(-2) +
      " " +
      months[auxFin.getMonth()] +
      " " +
      auxFin.getFullYear();

    var auxInit = new Date(
      props.transacciones[props.transacciones.length - 1].fechaTx
    );
    auxInit.setMonth(auxInit.getMonth() + 1);
    auxInit.setDate(props.fechaDesembolso.getDate());

    var year = auxInit.getFullYear();
    var month = auxInit.getMonth();
    var dt = auxInit.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var parsedDate = dt + "-" + month + "-" + year;

    axios
      .get(apiMethods.GET_UVR_FECHA + parsedDate, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var auxProxCorte = new Date(props.auxProxCorte);

        var year = auxProxCorte.getFullYear();
        var month = auxProxCorte.getMonth() + 1;
        var dt = auxProxCorte.getDate();

        if (dt < 10) {
          dt = "0" + dt;
        }
        if (month < 10) {
          month = "0" + month;
        }
        var parsedDate = auxDia + "-" + auxMes + "-" + auxAnio;

        var fechaPago = props.fechaPago;
        if (response.data.valor) {
          var vlrUVRInitAux = response.data.uvr[0].valor;
          var vlrUVRInit = parseFloat(vlrUVRInitAux);
        }

        axios
          .get(apiMethods.GET_UVR_FECHA + parsedDate, {
            headers: {
              Authorization: actUsrToken,
            },
          })
          .then((response) => {
            var fechaPago2 = props.fechaPago;
            if (response.data.uvr || props.moneda == "pesos") {
              if (props.moneda == "uvr") {
                var vlrUVRInitAux2 = response.data.uvr[0].valor;
                var vlrUVRInit2 = parseFloat(vlrUVRInitAux2);
              }

              var transacciones = props.transaccionesData;
              var auxTxAbs = [];
              var headers = [
                "Periodo",
                "Pagos realizados en el periodo",
                "Capital",
                "Interés Corriente",
                "Interés Moratorio",
                "Seguro de vida",
                "Seguro de incendio y todo riesgo",
              ];
              var auxHeaders = [];

              for (let i = 0; i < 7; i++) {
                var auxHeader = {
                  text: headers[i],
                  style: ["tableHeader"],
                };
                auxHeaders.push(auxHeader);
              }
              auxTxAbs.push(auxHeaders);

              var clientes = [];

              for (let i = 1; i < props.clientesData.length; i++) {
                var title = {
                  lineHeight: 1,
                  text: "APRECIADO CLIENTE",
                  style: "text",
                };
                clientes.push(title);

                var nombre = {
                  lineHeight: 1,
                  text: [props.clientesData[i].nombre],
                  style: "textBold",
                };

                clientes.push(nombre);

                var direccion = {
                  lineHeight: 2,
                  text: [props.clientesData[i].direccion],
                  style: "text",
                };

                clientes.push(direccion);
              }

              var paymentsHeader = [
                {
                  text: "PAGOS RECIBIDOS EN ESTE PERIODO",
                  alignment: "center",
                  colSpan: 2,
                  border: [false, false, false, false],
                },
                {},
              ];

              var paymentsBody = [];

              var neededPayments = [];
              var neededPaymentsData = [];

              var auxPeriodo = periodo;

              var fechaDesembolsoReal;

              if (
                props.liquidaciones[0].interesCorriente != 0 ||
                props.liquidaciones[0].interesMoratorio != 0 ||
                props.liquidaciones[0].segVida != 0 ||
                props.liquidaciones[0].segTodoRiesgo != 0
              ) {
                var sumMora = 0;
                var auxFecha = new Date(props.fechaInicial);
                fechaDesembolsoReal =
                  new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                    auxFecha
                  ) +
                  " de " +
                  new Intl.DateTimeFormat("es", { month: "long" }).format(
                    auxFecha
                  ) +
                  " " +
                  new Intl.DateTimeFormat("en", { year: "numeric" }).format(
                    auxFecha
                  );

                for (let i = 0; i < props.transacciones.length; i++) {
                  sumMora = sumMora + props.transacciones[i].mesesEnMora;
                }

                var cntCuotas = 0;

                for (let i = 0; i < props.liquidacionesData.length; i++) {
                  if (props.liquidacionesData[i].pagosTotal != 0) {
                    cntCuotas++;
                  }
                }

                if (cntCuotas == sumMora && props.transacciones.length > 1) {
                  sumMora--;
                }

                var sum = 0;
              } else {
                fechaDesembolsoReal =
                  new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                    props.fechaDesembolso
                  ) +
                  " de " +
                  new Intl.DateTimeFormat("es", { month: "long" }).format(
                    props.fechaDesembolso
                  ) +
                  " " +
                  new Intl.DateTimeFormat("en", { year: "numeric" }).format(
                    props.fechaDesembolso
                  );
              }

              var neededLiq = [];
              var sumMoraTx = 0;

              for (let i = 0; i <= periodo; i++) {
                if (props.proyeccion[i].presentaPagos == false) {
                  sumMoraTx++;
                }
              }

              if (props.proyeccion[0].liquidado == false) {
                sumMoraTx--;
              }

              if (sumMoraTx < 0) {
                sumMoraTx = 0;
              }

              var cntCero = 0;

              for (let i = 0; i < props.transacciones.length; i++) {
                if (props.transacciones[i].vlrTx == 0) {
                  cntCero++;
                }
              }

              periodo = periodo - cntCero;

              if (periodo < 0) {
                periodo = 0;
              }

              var genPago = props.transacciones[periodo - sumMoraTx];

              for (let i = 0; i < props.transacciones.length; i++) {
                if (props.proyeccionData[0].liquidado == true) {
                  if (props.transacciones[i].cuota == savePeriodo) {
                    neededPayments.push(props.transacciones[i]);
                  }
                } else {
                  if (props.transacciones[i].cuota == savePeriodo) {
                    neededPayments.push(props.transacciones[i]);
                  }
                }
              }

              var cero = false;
              var normal = false;

              for (let i = 0; i < neededPayments.length; i++) {
                if (neededPayments[i].vlrTx == 0) {
                  cero = true;
                } else {
                  normal = true;
                }
              }

              if (cero && normal) {
                for (let i = 0; i < neededPayments.length; i++) {
                  if (neededPayments[i].vlrTx == 0) {
                    neededPayments.splice(i);
                  }
                }
              }

              for (let i = 0; i < neededPayments.length; i++) {
                neededLiq.push(props.liquidaciones[neededPayments[i].id + 1]);
              }

              var auxFechaFinPeriodo = new Date(
                props.proyeccion[neededPayments[0].cuota].fechaFin
              );
              auxFechaFinPeriodo = fixTimeZone(new Date(auxFechaFinPeriodo));
              auxFechaFinPeriodo.setMonth(auxFechaFinPeriodo.getMonth() + 1);
              auxFechaFinPeriodo.setHours(0, 0, 0, 0);

              var fechaCompleta =
                ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                "/" +
                ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                "/" +
                auxFechaFinPeriodo.getFullYear();
              var sumIntCteGen = 0;
              var sumIntMoraGen = 0;
              var sumIntCteGenUVR = 0;
              var sumIntMoraGenUVR = 0;
              var sumSegGen = 0;

              var sumIntCteApl = 0;
              var sumIntMoraApl = 0;
              var sumSegApl = 0;
              var sumCapApl = 0;

              var sumIntCteAplUVR = 0;
              var sumIntMoraAplUVR = 0;
              var sumCapAplUVR = 0;

              for (let i = 0; i < neededPaymentsData.length; i++) {
                sumIntCteGen =
                  sumIntCteGen + neededPaymentsData[i].genInteresCorriente;
                sumIntMoraGen =
                  sumIntMoraGen + neededPaymentsData[i].genInteresMoratorio;
                sumSegGen =
                  sumSegGen +
                  neededPaymentsData[i].genSeguroVida +
                  neededPaymentsData[i].genSeguroTodoRiesgo;

                sumCapApl =
                  sumCapApl + neededPaymentsData[i].vlrAplicadoCapital;
                sumIntCteApl =
                  sumIntCteApl + neededPaymentsData[i].vlrAplicadoIntCte;
                sumIntMoraApl =
                  sumIntMoraApl + neededPaymentsData[i].vlrAplicadoIntMora;
                sumSegApl =
                  sumSegApl +
                  neededPaymentsData[i].vlrAplicadoSegVida +
                  neededPaymentsData[i].vlrAplicadoSegTodoRiesgo;

                if (props.moneda == "uvr") {
                  sumIntCteGenUVR =
                    sumIntCteGenUVR +
                    neededPaymentsData[i].genInteresCorrienteUVR;
                  sumIntMoraGenUVR =
                    sumIntMoraGenUVR +
                    neededPaymentsData[i].genInteresMoratorioUVR;

                  sumCapAplUVR =
                    sumCapAplUVR + neededPaymentsData[i].vlrAplicadoCapitalUVR;
                  sumIntCteAplUVR =
                    sumIntCteAplUVR +
                    neededPaymentsData[i].vlrAplicadoIntCteUVR;
                  sumIntMoraAplUVR =
                    sumIntMoraAplUVR +
                    neededPaymentsData[i].vlrAplicadoIntMoraUVR;
                }
              }

              var auxLiquidacionPrev;
              var auxLiquidacionPost;

              auxLiquidacionPost =
                props.liquidaciones[
                neededPayments[0].id + props.pagosPorCreditos[auxPeriodo - 1]
                ];

              for (let i = 0; i < neededPayments.length; i++) {
                paymentsBody.push({
                  Fecha: neededPayments[i].fechaTx,
                  Monto: "$" + neededPayments[i].vlrTx,
                });
              }

              if (props.moneda == "uvr") {
                var auxVlrCuota =
                  props.proyeccion[0].vlrCuotaUVR * parseFloat(vlrUVRInit2);
              } else {
                var auxVlrCuota = props.proyeccion[0].vlrCuota;
              }

              if (
                props.proyeccion[
                neededPayments[neededPayments.length - 1].cuota + 1
                ]
              ) {
                auxVlrCuota += parseFloat(
                  props.proyeccion[
                    neededPayments[neededPayments.length - 1].cuota + 1
                  ].segVida
                );
                auxVlrCuota += parseFloat(
                  props.proyeccion[
                    neededPayments[neededPayments.length - 1].cuota + 1
                  ].segTodoRiesgo
                );
              }

              if (props.moneda === "uvr") {
                var auxProy = 0;
                if (
                  props.proyeccion[
                  neededPayments[neededPayments.length - 1].cuota + 1
                  ]
                ) {
                  auxProy = parseFloat(
                    props.proyeccion[
                      neededPayments[neededPayments.length - 1].cuota + 1
                    ].vlrCapitalUVR
                  );
                }
                var capitalEnMora =
                  parseFloat(neededLiq[neededLiq.length - 1].capitalUVR) -
                  auxProy;

                if (capitalEnMora < 1) {
                  capitalEnMora = 0;
                }
                capitalEnMora = parseFloat(vlrUVRInit2) * capitalEnMora;
                if (capitalEnMora < 0) {
                  capitalEnMora = 0;
                }
              } else {
                var auxProy = 0;
                if (
                  props.proyeccion[
                  neededPayments[neededPayments.length - 1].cuota + 1
                  ]
                ) {
                  auxProy = parseFloat(
                    props.proyeccion[
                      neededPayments[neededPayments.length - 1].cuota + 1
                    ].capital
                  );
                }
                var capitalEnMora =
                  parseFloat(neededLiq[neededLiq.length - 1].capital) - auxProy;

                if (capitalEnMora < 0) {
                  capitalEnMora = 0;
                }
              }

              if (props.moneda == "uvr") {
                var saldoMora =
                  toFixed(parseFloat(capitalEnMora) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesCorrienteCuotaUVR
                  ) *
                  parseFloat(vlrUVRInit2) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesMoratorioCuotaUVR
                  ) *
                  parseFloat(vlrUVRInit2) +
                  parseFloat(neededLiq[neededLiq.length - 1].segVida) +
                  parseFloat(neededLiq[neededLiq.length - 1].segTodoRiesgo),0);
              } else {
                var saldoMora =
                  toFixed(parseFloat(capitalEnMora) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesCorrienteCuota
                  ) +
                  parseFloat(
                    neededLiq[neededLiq.length - 1].interesMoratorioCuota
                  ) +
                  parseFloat(neededLiq[neededLiq.length - 1].segVida) +
                  parseFloat(neededLiq[neededLiq.length - 1].segTodoRiesgo),0);
              }

              if (props.moneda == "uvr") {
                if (neededPayments[0].cuota <= parseFloat(props.plazo)) {
                  if (saldoMora > 0) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "/" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "/" +
                      auxFechaFinPeriodo.getFullYear() +
                      " por un monto aproximado de $" +
                      formatter.format(toFixed(auxVlrCuota, 0)) +
                      "\n" +
                      "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                    var textoPaguese = "PAGUESE INMEDIATAMENTE";
                  } else if (saldoMora == 0) {
                    var textoMora =
                      "Su obligación está al día, su próxima cuota es del dia " +
                      fechaCompleta +
                      " por un monto de $" +
                      formatter.format(toFixed(auxVlrCuota, 0));
                    var textoPaguese =
                      "PAGUESE EL: " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "-" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "-" +
                      auxFechaFinPeriodo.getFullYear();
                  } else if (saldoMora < 0) {
                    var textoMora = "Su obligación está al día";
                  } else if (
                    props.proyeccion[props.proyeccion.length - 1].fechaFin <
                    neededPayments[0].fechaTx
                  ) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente por un monto aproximado de $" +
                      formatter.format(toFixed(auxVlrCuota, 0)) +
                      "\n" +
                      "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                  }
                } else {
                  var textoMora =
                    "Su obligación se encuentra en mora en $" +
                    formatter.format(saldoMora) +
                    " por favor ponerse al dia inmediatamente.";
                  var textoPaguese = "PAGUESE INMEDIATAMENTE";
                }
                if (window.sessionStorage.getItem("initialPDF") == "true") {
                  var auxFecha = props.fechaDesembolso;
                  auxFecha.setMonth(auxFecha.getMonth() + 1);
                  var textoMora =
                    "Su obligación se encuentra al día, su próxima cuota programada es el dia " +
                    ("0" + auxFecha.getDate()).slice(-2) +
                    "/" +
                    ("0" + (auxFecha.getMonth() + 1)).slice(-2) +
                    "/" +
                    auxFecha.getFullYear() +
                    " por un monto aproximado de $" +
                    formatter.format(toFixed(auxVlrCuota, 0)) +
                    "\n" +
                    "IMPORTANTE: por favor comuníquese con nosotros para conocer el valor exacto de su cuota.";
                  setState({ initialPDF: false });
                }
              } else {
                if (neededPayments[0].cuota <= parseFloat(props.plazo)) {
                  if (saldoMora > 0) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente, su próxima cuota programada es el dia " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "/" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "/" +
                      auxFechaFinPeriodo.getFullYear() +
                      " por un monto de $" +
                      formatter.format(toFixed(auxVlrCuota, 0));
                    var textoPaguese = "PAGUESE INMEDIATAMENTE";
                  } else if (saldoMora == 0) {
                    var textoMora =
                      "Su obligación está al día, su próxima cuota es del dia " +
                      fechaCompleta +
                      " por un monto de $" +
                      formatter.format(toFixed(auxVlrCuota, 0));
                    var textoPaguese =
                      "PAGUESE EL: " +
                      ("0" + auxFechaFinPeriodo.getDate()).slice(-2) +
                      "-" +
                      ("0" + (auxFechaFinPeriodo.getMonth() + 1)).slice(-2) +
                      "-" +
                      auxFechaFinPeriodo.getFullYear();
                  } else if (saldoMora < 0) {
                    var textoMora = "Su obligación está al día";
                  } else if (
                    props.proyeccion[props.proyeccion.length - 1].fechaFin <
                    neededPayments[0].fechaTx
                  ) {
                    var textoMora =
                      "Su obligación se encuentra en mora en $" +
                      formatter.format(saldoMora) +
                      " por favor ponerse al dia inmediatamente por un monto de $" +
                      formatter.format(toFixed(auxVlrCuota, 0));
                  }
                } else {
                  var textoMora =
                    "Su obligación se encuentra en mora en $" +
                    formatter.format(saldoMora) +
                    " por favor ponerse al dia inmediatamente.";
                  var textoPaguese = "PAGUESE INMEDIATAMENTE";
                }
                if (window.sessionStorage.getItem("initialPDF") == "true") {
                  var auxFecha = props.fechaDesembolso;
                  auxFecha.setMonth(auxFecha.getMonth() + 1);
                  var textoMora =
                    "Su obligación se encuentra al día, su próxima cuota programada es el dia " +
                    ("0" + auxFecha.getDate()).slice(-2) +
                    "/" +
                    ("0" + (auxFecha.getMonth() + 1)).slice(-2) +
                    "/" +
                    auxFecha.getFullYear() +
                    " por un monto de $" +
                    formatter.format(toFixed(auxVlrCuota, 0));
                  setState({ initialPDF: false });
                }
              }

              var liqTables = [];
              var cntNeededPayments = neededPayments[0].id;

              for (let i = 0; i < neededPayments.length; i++) {
                var liqGen = [];

                var auxFecha = new Date(neededPayments[i].fechaTx);
                auxFecha = fixTimeZone(new Date(auxFecha));

                var textoFecha =
                  ("0" + auxFecha.getDate()).slice(-2) +
                  "-" +
                  months[auxFecha.getMonth()] +
                  "-" +
                  auxFecha.getFullYear();

                var auxNuevoCorriente = formatter.format(
                  parseFloat(
                    props.detalleData[cntNeededPayments][3][1].replace(/,/g, "")
                  )
                );

                var auxSegVida;

                if (
                  i == 0 &&
                  neededPayments[0].id == 0 &&
                  props.detalleData[cntNeededPayments][0][3] == "0"
                ) {
                  auxSegVida = formatter.format(
                    props.liquidacionesResp[0].segVida
                  );
                } else {
                  auxSegVida = props.detalleData[cntNeededPayments][0][3];
                }

                if (
                  i == 0 &&
                  neededPayments[0].id == 0 &&
                  props.detalleData[cntNeededPayments][0][4] == "0"
                ) {
                  var auxSegTodoRiesgo = formatter.format(
                    props.liquidacionesResp[0].segTodoRiesgo
                  );
                } else {
                  var auxSegTodoRiesgo =
                    props.detalleData[cntNeededPayments][0][4];
                }

                if (props.moneda == "uvr") {
                  var auxNuevoCorrienteUVR = formatter.format(
                    parseFloat(
                      props.detalleDataUVR[cntNeededPayments][3][1].replace(
                        /,/g,
                        ""
                      )
                    )
                  );
                  var saldoInicial = [
                    "Saldo Inicial",
                    props.detalleDataUVR[cntNeededPayments][0][0] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][0][0] +
                    ")",
                    props.detalleDataUVR[cntNeededPayments][0][1] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][0][1] +
                    ")",
                    props.detalleDataUVR[cntNeededPayments][0][2] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][0][2] +
                    ")",
                    "$" + auxSegVida,
                    "$" + auxSegTodoRiesgo,
                  ];
                  var movimientos = [
                    "Movimientos",
                    "",
                    props.detalleDataUVR[cntNeededPayments][1][0] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][1][0] +
                    ")",
                    props.detalleDataUVR[cntNeededPayments][1][1] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][1][1] +
                    ")",
                    "$" + props.detalleData[cntNeededPayments][1][2],
                    "$" + props.detalleData[cntNeededPayments][1][3],
                  ];
                  var aplicacion = [
                    "Aplicación del pago",
                    props.detalleDataUVR[cntNeededPayments][2][0] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][2][0] +
                    ")",
                    props.detalleDataUVR[cntNeededPayments][2][1] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][2][1] +
                    ")",
                    props.detalleDataUVR[cntNeededPayments][2][2] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][2][2] +
                    ")",
                    "$" + props.detalleData[cntNeededPayments][2][3],
                    "$" + props.detalleData[cntNeededPayments][2][4],
                  ];
                  var saldo = [
                    "Nuevo Saldo",
                    props.detalleDataUVR[cntNeededPayments][3][0] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][3][0] +
                    ")",
                    auxNuevoCorrienteUVR +
                    " UVR" +
                    " " +
                    "($" +
                    auxNuevoCorriente +
                    ")",
                    props.detalleDataUVR[cntNeededPayments][3][2] +
                    " UVR" +
                    " " +
                    "($" +
                    props.detalleData[cntNeededPayments][3][2] +
                    ")",
                    "$" + props.detalleData[cntNeededPayments][3][3],
                    "$" + props.detalleData[cntNeededPayments][3][4],
                  ];
                } else {
                  var saldoInicial = [
                    "Saldo Inicial",
                    "$" + props.detalleData[cntNeededPayments][0][0],
                    "$" + props.detalleData[cntNeededPayments][0][1],
                    "$" + props.detalleData[cntNeededPayments][0][2],
                    "$" + auxSegVida,
                    "$" + auxSegTodoRiesgo,
                  ];
                  var movimientos = [
                    "Movimientos",
                    "",
                    "$" + props.detalleData[cntNeededPayments][1][0],
                    "$" + props.detalleData[cntNeededPayments][1][1],
                    "$" + props.detalleData[cntNeededPayments][1][2],
                    "$" + props.detalleData[cntNeededPayments][1][3],
                  ];
                  var aplicacion = [
                    "Aplicación del pago",
                    "$" + props.detalleData[cntNeededPayments][2][0],
                    "$" + props.detalleData[cntNeededPayments][2][1],
                    "$" + props.detalleData[cntNeededPayments][2][2],
                    "$" + props.detalleData[cntNeededPayments][2][3],
                    "$" + props.detalleData[cntNeededPayments][2][4],
                  ];
                  var saldo = [
                    "Nuevo Saldo",
                    "$" + props.detalleData[cntNeededPayments][3][0],
                    "$" + auxNuevoCorriente,
                    "$" + props.detalleData[cntNeededPayments][3][2],
                    "$" + props.detalleData[cntNeededPayments][3][3],
                    "$" + props.detalleData[cntNeededPayments][3][4],
                  ];
                }

                if (props.moneda == "uvr") {
                  var movimientosRes = [
                    "Movimientos",
                    "",
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaGenUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaGen,
                        0
                      )
                    ) +
                    ")",
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaGenUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaGen,
                        0
                      )
                    ) +
                    ")",
                    "$0",
                    "$0",
                  ];
                  var saldoRes = [
                    "Nuevo Saldo",
                    formatter.format(
                      toFixed(neededLiq[neededLiq.length - 1].capitalUVR, 0)
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1].capitalProxCorte,
                        0
                      )
                    ) +
                    ")",
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1].interesCorrienteCuota,
                        0
                      )
                    ) +
                    ")",
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaUVR,
                        0
                      )
                    ) +
                    " UVR" +
                    " " +
                    "($" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1].interesMoratorioCuota,
                        0
                      )
                    ) +
                    ")",
                    "$" + props.detalleData[cntNeededPayments][3][3],
                    "$" + props.detalleData[cntNeededPayments][3][4],
                  ];
                } else {
                  var movimientosRes = [
                    "Movimientos",
                    "",
                    "$" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesCorrienteCuotaGen,
                        0
                      )
                    ),
                    "$" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1]
                          .interesMoratorioCuotaGen,
                        0
                      )
                    ),
                    "$0",
                    "$0",
                  ];
                  var saldoRes = [
                    "Nuevo Saldo",
                    "$" +
                    formatter.format(
                      toFixed(neededLiq[neededLiq.length - 1].capital, 0)
                    ),
                    "$" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1].interesCorrienteCuota,
                        0
                      )
                    ),
                    "$" +
                    formatter.format(
                      toFixed(
                        neededLiq[neededLiq.length - 1].interesMoratorioCuota,
                        0
                      )
                    ),
                    "$" + props.detalleData[cntNeededPayments][3][3],
                    "$" + props.detalleData[cntNeededPayments][3][4],
                  ];
                }

                if (
                  neededPayments .length > 1 &&
                  i == neededPayments.length - 1
                ) {
                  var auxTable = {
                    style: "tableExample",
                    color: "black",
                    margin: [0, 10, 0, 0],
                    table: {
                      body: [
                        [
                          "",
                          {
                            text: "Capital",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Corriente",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Moratorio",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Vida",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Incendio y Todo Riesgo",
                            style: "textBoldTable",
                          },
                        ],
                        saldoInicial,
                        movimientos,
                        aplicacion,
                        saldo,
                      ],
                    },
                    pageBreak: "after",
                  };
                } else {
                  var auxTable = {
                    style: "tableExample",
                    color: "black",
                    margin: [0, 10, 0, 0],
                    table: {
                      body: [
                        [
                          "",
                          {
                            text: "Capital",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Corriente",
                            style: "textBoldTable",
                          },
                          {
                            text: "Interés Moratorio",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Vida",
                            style: "textBoldTable",
                          },
                          {
                            text: "Seguro de Incendio y Todo Riesgo",
                            style: "textBoldTable",
                          },
                        ],
                        saldoInicial,
                        movimientos,
                        aplicacion,
                        saldo,
                      ],
                    },
                  };
                }

                var auxTableRes = {
                  style: "tableExample",
                  color: "black",
                  margin: [0, 10, 0, 0],
                  table: {
                    body: [
                      [
                        "",
                        { text: "Capital", style: "textBoldTable" },
                        {
                          text: "Interés Corriente",
                          style: "textBoldTable",
                        },
                        {
                          text: "Interés Moratorio",
                          style: "textBoldTable",
                        },
                        {
                          text: "Seguro de Vida",
                          style: "textBoldTable",
                        },
                        {
                          text: "Seguro de Incendio y Todo Riesgo",
                          style: "textBoldTable",
                        },
                      ],
                      movimientosRes,
                      saldoRes,
                    ],
                  },
                };
                if (neededPayments[i].vlrTx != 0) {
                  if (props.moneda == "uvr") {
                    var text = {
                      text:
                        "Pago " +
                        (i + 1) +
                        ". " +
                        formatter.format(
                          toFixed(neededPayments[i].vlrTxUVR, 2)
                        ) +
                        " UVR " +
                        "($" +
                        formatter.format(toFixed(neededPayments[i].vlrTx, 2)) +
                        ")" +
                        " el " +
                        textoFecha,
                      style: "headerPago",
                      margin: [0, 10, 0, 0],
                    };
                  } else {
                    var text = {
                      text:
                        "Pago " +
                        (i + 1) +
                        ". " +
                        formatter.format(toFixed(neededPayments[i].vlrTx, 2)) +
                        " el " +
                        textoFecha,
                      style: "headerPago",
                      margin: [0, 10, 0, 0],
                    };
                  }
                } else {
                  var text = {
                    text: "",
                    style: "headerPago",
                    margin: [0, 10, 0, 0],
                  };
                }

                cntNeededPayments++;

                liqGen.push(text);
                liqGen.push(auxTable);
                liqTables.push(liqGen);
              }
              var auxProps = props;
              var nombre = props.clientesData[0].nombre;
              var direccion = props.clientesData[0].direccion;

              var docDefinition = {
                pageMargins: [40, 280, 40, 200],
                header: function () {
                  return [
                    {
                      margin: [0, 0, 0, 10],
                      canvas: [
                        {
                          type: "rect",
                          x: -15,
                          y: -15,
                          w: 610,
                          h: 76,
                          color: "#e92128",
                        },
                      ],
                    },
                    {
                      margin: [0, 0, 0, 10],
                      canvas: [
                        {
                          type: "rect",
                          x: -15,
                          y: -20,
                          w: 610,
                          h: 8,
                          color: "#B9B9B9",
                        },
                      ],
                    },
                    {
                      text: "INMOBILIARIA",
                      style: "title1",
                      absolutePosition: { x: 305, y: 5 },
                    },
                    {
                      text: "MOBILIA",
                      style: "title2",
                      absolutePosition: { x: 305, y: 27 },
                    },
                    {
                      width: 100,
                      image: logo,
                      alignment: "center",
                      absolutePosition: { y: 6, x: -110 },
                    },
                    {
                      margin: [40, 0, 0, 0],
                      lineHeight: 1.3,
                      text: "ESTADO DE CUENTA",
                      style: "textBold",
                    },
                    {
                      margin: [40, 0, 0, 5],
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 515,
                          y2: 0,
                          lineWidth: 3,
                          lineColor: "red",
                        },
                      ],
                    },
                    {
                      margin: [40, 0, 0, 0],
                      lineHeight: 2.5,
                      text: "PERIODO ENTRE " + fechasPeriodo,
                      style: "redText",
                    },
                    {
                      margin: [40, 0, 0, 0],
                      lineHeight: 2,
                      text: "OBLIGACION HIPOTECARIA NUMERO:",
                      style: "textBold",
                    },
                    {
                      absolutePosition: { y: 124, x: 405 },
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 150,
                          h: 30,
                          color: "#DCDDDF",
                        },
                      ],
                    },
                    {
                      lineHeight: 2,
                      text: auxProps.financialCode
                        ? auxProps.financialCode
                        : auxProps.id,
                      style: "textBold",
                      alignment: "right ",
                      absolutePosition: { y: 134, x: 460 },
                    },
                    {
                      margin: [40, 0, 0, 5],
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 515,
                          y2: 0,
                          lineWidth: 3,
                          lineColor: "red",
                        },
                      ],
                    },
                    {
                      margin: [40, 10, 40, 0],

                      table: {
                        body: [
                          [
                            {
                              fillColor: "#DCDDDF",
                              border: [false, false, false, false],
                              columns: [
                                {
                                  width: 250,
                                  stack: [
                                    {
                                      lineHeight: 1.5,
                                      text: "APRECIADO CLIENTE",
                                      style: "text",
                                    },
                                    {
                                      lineHeight: 1.5,
                                      text: auxProps.clientesData[0].nombre,
                                      style: "textBold",
                                    },
                                    {
                                      lineHeight: 1.5,
                                      text: auxProps.clientesData[0].direccion,
                                      style: "text",
                                    },
                                  ],
                                },
                                {
                                  width: "auto",
                                  columns: [
                                    {
                                      stack: [
                                        {
                                          lineHeight: 1,
                                          text: "Valor del crédito:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Fecha de desembolso:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Tasa de interés:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Plazo:",
                                          style: "textBold",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: "Sistema de Amortización:",
                                          style: "textBold",
                                        },
                                      ],
                                    },
                                    {
                                      stack: [
                                        {
                                          lineHeight: 1,
                                          text: "$" + auxProps.vlrInicial,
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: fechaDesembolsoReal,
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text:
                                            auxProps.interes + " % " + "E.A.",
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text: auxProps.plazo + " meses",
                                          style: "text",
                                        },
                                        {
                                          lineHeight: 1,
                                          text:
                                            auxProps.amortizacion +
                                            " en " +
                                            auxProps.moneda,
                                          style: "text",
                                        },
                                      ],
                                    },
                                  ],
                                  columnGap: 10,
                                },
                              ],
                              columnGap: 30,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      margin: [40, 10, 40, 0],
                      table: {
                        widths: ["*"],
                        body: [
                          [
                            {
                              fontSize: 10,
                              fillColor: "#DCDDDF",
                              border: [false, false, false, false],
                              alignment: "center",
                              margin: [0, 7, 0, 7],
                              columns: [
                                {
                                  text: "PAGOS RECIBIDOS EN ESTE PERIODO",
                                  bold: true,
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    },
                  ];
                },
                footer: function () {
                  return [
                    {
                      absolutePosition: { y: 60, x: 0 },
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 700,
                          h: 78,
                          color: "#DCDDDF",
                        },
                      ],
                    },
                    {
                      lineHeight: 2,
                      text: "FORMA DE PAGO",
                      style: "textBold",
                      alignment: "center",
                      absolutePosition: { x: 0, y: 72 },
                    },
                    {
                      absolutePosition: { x: 20, y: 93 },
                      margin: [40, 0, 0, 40],
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 515,
                          y2: 0,
                          lineWidth: 3,
                          lineColor: "red",
                        },
                      ],
                    },
                    {
                      absolutePosition: { y: 102, x: 0 },
                      lineHeight: 1.2,
                      text: "Su pago lo puede efectuar en el Banco Caja Social Cuenta convenio No. 11542 \na nombre de Inmobiliaria Mobilia S.A. En cualquier ciudad del país",
                      style: "textBold",
                      alignment: "center",
                    },
                    {
                      width: 600,
                      image: footer,
                      alignment: "center",
                      absolutePosition: { y: 137, x: 0 },
                    },
                  ];
                },
                content: [
                  liqTables,
                  {
                    margin: [0, 10, 0, 0],
                    text: "Resultado del periodo:",
                    style: "text",
                  },
                  auxTableRes,
                  {
                    margin: [0, 15, 0, 0],
                    table: {
                      widths: ["*"],
                      body: [
                        [
                          {
                            fontSize: 10,
                            fillColor: "#DCDDDF",
                            border: [false, false, false, false],
                            alignment: "center",
                            margin: [0, 7, 0, 5],
                            columns: [
                              {
                                lineHeight: 1.5,
                                text: textoMora,
                                bold: false,
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                ],
                styles: {
                  subtitle: {
                    fontSize: 10,
                    bold: true,
                  },
                  text: {
                    fontSize: 10,
                    bold: false,
                  },
                  textBoldTable: {
                    fontSize: 9,
                    bold: true,
                  },
                  headerPago: {
                    fontSize: 10,
                    bold: false,
                  },
                  textBold: {
                    fontSize: 10,
                    bold: true,
                  },
                  tableHeader: {
                    fontSize: 8,
                    bold: true,
                  },
                  tableHeaderColor: {
                    fontSize: 8,
                    bold: true,
                    color: "white",
                    fillColor: "#e92128",
                  },
                  redText: {
                    fontSize: 10,
                    bold: true,
                    color: "#e92128",
                  },
                  tableExample: {
                    fontSize: 8,
                    color: "black",
                  },
                  tableExample2: {
                    fontSize: 9,
                    color: "black",
                  },

                  title1: {
                    fontSize: 15,
                    color: "white",
                  },
                  title2: {
                    fontSize: 20,
                    bold: true,
                    color: "white",
                  },
                },
              };

              pdfMake.createPdf(docDefinition).download("prueba.pdf");
              if (window.sessionStorage.getItem("initialPDF") == "true") {console.log("entre en 5093")
                delPago();
                window.sessionStorage.setItem("initialPDF", "false");
              }
            } else {
              toast.error(
                "No existen valores UVR para el periodo seleccionado"
              );
            }
          });
      });
  };

  const toFixed = (num, fixed) => {
    if (num != null) {
      var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    } else {
      return 0;
    }
  };

  const createCredit = (formData) => {
    formData.deudores = [];
    if (
      typeof formData.vlrCapital === "string" ||
      formData.vlrCapital instanceof String
    )
      formData.vlrCapital = parseFloat(
        formData.vlrCapital.replace(/\$|,/g, "")
      );

    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;

    queryClients.forEach((client) => {
      formData.deudores.push(client.id);
    });

    if (formData.deudores.length == 0) {
      toast.warn("Debe añadir al menos un cliente");
    }

    axios.get(apiMethods.GET_AUXILIAR_ID).then((response) => {
      var localCode = response.data.auxiliar.cntCreditos;
      var localLiq = response.data.auxiliar.cntLiquidaciones;
      localLiq = localLiq + 1;
      localCode = localCode + 1;

      var str = formData.vlrCapital;
      axios
        .patch(apiMethods.PATCH_AUXILIAR_ID, {
          id: 0,
          cntCreditos: localCode,
        })
        .then((response) => {
          formData = {
            ...formData,
            id: localCode,
            vlrCapital: str,
            vlrInicial: str,
            vlrCapProyectado: str,
            vlrInteres: 0,
            vlrInteresMora: 0,
            vlrInteresUVR: 0,
            vlrInteresMoraUVR: 0,
            diasAcumulados: 0,
          };

          for (let i = 0; i < formData.deudores.length; i++) {
            var aux;
            axios
              .get(apiMethods.GET_CLIENTE_DOCUMENTO + formData.deudores[i])
              .then((response) => {
                aux = response.data.client.creditos;
                aux.push(localCode);
                axios
                  .patch(
                    apiMethods.PATCH_CLIENTE_DOCUMENTO + formData.deudores[i],
                    {
                      creditos: aux,
                    }
                  )
                  .then((response) => { })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => {
                console.error(error);
              });

            var interesAnual = formData.interes;

            var interesMensualP = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
            var interesMensual = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
            interesMensual = interesMensual * 100;
            var pv = formData.vlrInicial;
            var np = formData.plazo;
            var fv = 0;
            var type = 0;
            var ir = interesMensualP;

            formData.interesMensual = interesMensual;

            var pmt, pvif;
            fv || (fv = 0);
            type || (type = 0);

            if (ir === 0) return -(pv + fv) / np;

            pvif = Math.pow(1 + ir, np);
            pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

            formData.vlrCuota = Math.round(pmt);

            var proyeccion = [];
            var cap = pv;

            for (let i = 0; i < np; i++) {
              var noCuota = i + 1;
              var int = Math.round(cap * interesMensualP);
              var corte = {
                liquidado: false,
                presentaPagos: false,
                fechaIni: new Date(),
                fechaFin: new Date(),
                noCuota: noCuota,
                capital: cap,
                interes: int,
                vlrCuota: Math.round(pmt),
                segVida: 0,
                segTodoRiesgo: 0,
              };
              cap = Math.round(cap - (pmt - int));
              proyeccion.push(corte);
            }

            formData = {
              ...formData,
              liquidaciones: [
                {
                  id: 0,
                  capital: formData.vlrInicial,
                  interesCorriente: 0,
                  interesMoratorio: 0,
                  segVida: 0,
                  segTodoRiesgo: 0,
                  interesCorrienteCuota: 0,
                  interesCorrienteCuotaUVR: 0,
                  interesMoratorioCuota: 0,
                  interesMoratorioCuotaUVR: 0,
                },
              ],
              proyeccion: proyeccion,
              cntLiquidaciones: localLiq,
              fechaProxCorte: new Date(),
              pasoEdicion: 0,
              cuota: 0,
            };

            axios
              .post(apiMethods.CREATE_CREDITO, formData)
              .then((response) => {
                switch (response.data.code) {
                  case 0:
                    axios
                      .get(apiMethods.GET_USUARIO_ID + decodedPayload.usrId)
                      .then((response) => {
                        var localCredits = response.data.users.creditos;
                        localCredits.push(localCode);
                        axios
                          .patch(
                            apiMethods.PATCH_USUARIO_ID + decodedPayload.usrId,
                            { creditos: localCredits, id: localId },
                            {
                              headers: {
                                Authorization: actUsrToken,
                              },
                            }
                          )
                          .then((response) => {
                            window.sessionStorage.setItem(
                              "localDocument",
                              localCode
                            );
                            //history.push(`/detailCreditos`);

                            history.push(`/credits/${localCode}`);
                            window.location.reload();
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                    break;
                  case 403:
                    toast.error(
                      "Código de entidad financiera se encuentra registrado"
                    );
                    break;
                  default:
                    break;
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          toast.error("Error al actualizar");
          console.error(error);
        });
    });
  };

  const updateCredit = async (formData) => {
    let search = getLastItem(window.location.href);
    let creditData = props;
    var valid = true;

    for (let i = 0; i < creditData.proyeccion.length; i++) {
      var local = creditData.proyeccion[i];
      local.segVida = local.segVida.toString().replace(/,/g, "");
      local.segTodoRiesgo = local.segTodoRiesgo.toString().replace(/,/g, "");
      if (isNaN(local.segVida) || isNaN(local.segTodoRiesgo)) {
        valid = false;
        break;
      }
    }

    var plazoAux = document.getElementById("plazo").value;
    var auxInteres = creditData.interes;

    if (valid == true) {
      if (
        isNaN(plazoAux) ||
        plazoAux == "" ||
        plazoAux.includes(",") ||
        plazoAux.includes(".") ||
        parseInt(plazoAux, 10) < 6 ||
        parseInt(plazoAux, 10) > 250
      ) {
        toast.warn("Plazo inválido");
      } else if (
        isNaN(auxInteres) ||
        parseFloat(auxInteres, 10) < 1 ||
        auxInteres == ""
      ) {
        toast.warn("Tasa de interés inválida");
      } else if (
        creditData.estado == "aprobado" &&
        creditData.fechaDesembolsoReal == null
      ) {
        toast.warn("Fecha de desembolso inválida");
      } else if (
        props.liquidaciones.length == 1 &&
        props.estado == "aprobado" &&
        (props.liquidaciones[0].interesCorriente != 0 ||
          props.liquidaciones[0].interesMoratorio != 0 ||
          props.liquidaciones[0].segVida != 0 ||
          props.liquidaciones[0].segTodoRiesgo != 0)
      ) {
        toast.error("Crédito no disponible");
      } else {
        formData = {
          ...formData,
          vlrInicial: parseInt(
            formData.vlrInicial.toString().replace(/,/g, "").replace(/\$/g, "")
          ),
          vlrCapital: parseInt(
            formData.vlrCapital.toString().replace(/,/g, "").replace(/\$/g, "")
          ),
          vlrInteres: parseInt(
            formData.vlrInteres.toString().replace(/,/g, "").replace(/\$/g, "")
          ),
          vlrInteresMora: parseInt(
            formData.vlrInteresMora.toString().replace(/,/g, "").replace(/\$/g, "")
          ),
          saldoSegVida: parseInt(
            formData.saldoSegVida.toString().replace(/,/g, "").replace(/\$/g, "")
          ),
          saldoSegTodoRiesgo: parseInt(
            formData.saldoSegTodoRiesgo.toString().replace(/,/g, "").replace(/\$/g, "")
          ),
        };

        if (formData.estado == "noAprobado") {
          try {
            delete formData.proyeccion
            delete formData.liquidaciones
            delete formData.transacciones
            let updateResponse = await axios.patch(apiMethods.PATCH_CREDITO_ID + search, formData)
            window.sessionStorage.setItem("success", "true");
            window.sessionStorage.setItem("type", "E");
            history.push("/search/credits");
            window.location.reload();
          } catch (e) {
            console.error(e);
            toast.error("Error al actualizar");
          }
        } else if (formData.estado == "aprobado") {
          let responseCredit = await axios.get(apiMethods.GET_CREDITO_ID + search)
          //console.log(responseCredit)
          
          await axios.patch(apiMethods.PATCH_CREDITO_ID + search, { pasoEdicion: responseCredit.data.credits.pasoEdicion + 1 })
          if (creditData.transacciones.length > 0) {
            try {
              let auxProyeccion = formData.proyeccion
              auxProyeccion.forEach(cuota => {
                cuota.capital = parseFloat(cuota.capital.toString().replace(/,/g, ""));
                cuota.interes = parseFloat(cuota.interes.toString().replace(/,/g, ""));
                cuota.vlrCuota = parseFloat(cuota.vlrCuota.toString().replace(/,/g, ""));
                if (creditData.moneda == "uvr") {
                  cuota.vlrCapitalUVR = parseFloat(cuota.vlrCapitalUVR.toString().replace(/,/g, ""));
                  cuota.vlrInteresUVR = parseFloat(cuota.vlrInteresUVR.toString().replace(/,/g, ""));
                  cuota.vlrCuotaUVR = parseFloat(cuota.vlrCuotaUVR.toString().replace(/,/g, ""));
                }
                cuota.segVida = parseFloat(cuota.segVida.toString().replace(/,/g, ""));
                cuota.segTodoRiesgo = parseFloat(cuota.segTodoRiesgo.toString().replace(/,/g, ""));
                delete cuota.periodo;
              })
              await axios.patch(apiMethods.PATCH_CREDITO_ID + search, { proyeccion: auxProyeccion })
              toast.success("Crédito actualizado");
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } catch (e) {
              toast.error("Error al actualizar");
              console.error(e)
            }
          } else {
            if (
              props.liquidaciones.length == 1 &&
              props.estado == "aprobado" &&
              (props.liquidaciones[0].interesCorriente != 0 ||
                props.liquidaciones[0].interesMoratorio != 0 ||
                props.liquidaciones[0].segVida != 0 ||
                props.liquidaciones[0].segTodoRiesgo != 0)
            ) {
              toast.error("Actualización no disponible")
            } else {

              var auxFecha = new Date(formData.fechaDesembolsoReal);
              auxFecha.setHours(0, 0, 0, 0);
              //console.log(auxFecha)
              
              auxFecha.setMonth(auxFecha.getMonth() + 1);
              let responseCredit = await axios.get(apiMethods.GET_CREDITO_ID + search);
              //var auxPlazo = responseCredit.data.credits.plazo;
              //var auxMonto = responseCredit.data.credits.vlrInicial;
              //var auxInt = responseCredit.data.credits.interes;
              var auxMontoLocal = parseInt(creditData.vlrInicial.toString().replace(/,/g, ""));

              if (creditData.moneda == "pesos") {

                var auxInit = new Date(formData.fechaDesembolsoReal);
                var auxFecha = new Date(formData.fechaDesembolsoReal);

                auxFecha.setMonth(auxFecha.getMonth() + 1);

                auxInit.setHours(0, 0, 0, 0);
                auxFecha.setHours(0, 0, 0, 0);



                var auxDes;
                var auxDes2;


                auxDes = auxInit;
                auxDes2 = auxFecha;



                var interesAnual = formData.interes;
                var interesMensualP = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                var interesMensual = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                interesMensual = interesMensual * 100;
                var pv = parseFloat(formData.vlrInicial.toString().replace(/,/g, "").replace(/\$/g, ""));
                var np = parseFloat(formData.plazo);
                var fv = 0;
                var type = 0;
                var ir = interesMensualP;

                var pmt, pvif;
                fv || (fv = 0);
                type || (type = 0);

                if (ir === 0) return -(pv + fv) / np;

                pvif = Math.pow(1 + ir, np);

                pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

                var proyeccion = [];
                var cap = pv;
                var auxInit = new Date(formData.fechaDesembolsoReal);
                auxInit.setHours(0, 0, 0, 0);
                //console.log(formData)

                for (let i = 0; i < np; i++) {
                  var noCuota = i;
                  var fechaIni = new Date(auxInit);
                  auxInit.setMonth(auxInit.getMonth() + 1);
                  var fechaFin = new Date(auxInit);
                  var int = cap * interesMensualP;
                  var corte = {
                    liquidado: false,
                    presentaPagos: false,
                    fechaIni: fechaIni,
                    fechaFin: fechaFin,
                    noCuota: noCuota,
                    capital: cap,
                    interes: int,
                    vlrCuota: pmt,
                    segVida: 0,
                    segTodoRiesgo: 0,
                  };
                  cap = cap - (pmt - int);
                  proyeccion.push(corte);
                }
                
                formData = {
                  ...formData,
                  fechaPagoReciente: new Date(auxDes),
                  fechaProxCorte: new Date(auxDes2),
                  fechaAprobacion: new Date(formData.fechaAprobacion).setHours(0, 0, 0, 0),
                  fechaDesembolsoReal: new Date(formData.fechaDesembolsoReal).setHours(0, 0, 0, 0),
                  fechaDesembolso: new Date(formData.fechaDesembolsoReal).setHours(0, 0, 0, 0),
                }
                
                axios
                  .patch(
                    apiMethods.PATCH_CREDITO_ID + search,
                    formData,
                  )
                  .then((response) => {
                    if (response.data.code == 403) {
                      toast.warn(
                        "Código de entidad financiera ya registrado"
                      );
                    } else {
                      axios
                        .patch(apiMethods.PATCH_CREDITO_ID + search, {
                          proyeccion: proyeccion,
                        })
                        .then((response) => {
                          //console.log(response);
                          history.push("/search/credits");
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else if (creditData.moneda == "uvr") {

                var auxInit = new Date(formData.fechaDesembolsoReal);
                auxInit.setHours(0, 0, 0, 0);

                var year = auxInit.getFullYear();
                var month = auxInit.getMonth() + 1;
                var dt = auxInit.getDate();
                if (dt < 10) {
                  dt = "0" + dt;
                }
                if (month < 10) {
                  month = "0" + month;
                }
                var parsedDate = dt + "-" + month + "-" + year;

                axios
                  .get(apiMethods.GET_UVR_FECHA + parsedDate)
                  .then((response) => {
                    var vlrUVRInitAux = response.data.uvr[0].valor;
                    var vlrUVRInit = vlrUVRInitAux;

                    var auxInit = new Date(formData.fechaDesembolsoReal);
                    var auxFecha = new Date(formData.fechaDesembolsoReal);
                    auxInit.setHours(0, 0, 0, 0);
                    auxFecha.setHours(0, 0, 0, 0);
                    auxFecha.setMonth(auxFecha.getMonth() + 1);

                    var auxDes;
                    var auxDes2;

                    auxDes = auxInit;
                    auxDes2 = auxFecha;

                    var interesAnual = formData.interes;
                    var interesMensualP = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                    var interesMensual = Math.pow(1 + interesAnual / 100, 1 / 12) - 1;
                    interesMensual = interesMensual * 100;
                    var pv = parseFloat(formData.vlrInicial.toString().replace(/,/g, "").replace(/\$/g, ""));
                    var np = parseFloat(formData.plazo);
                    var fv = 0;
                    var type = 0;
                    var ir = interesMensualP;

                    var pmt, pvif;
                    fv || (fv = 0);
                    type || (type = 0);

                    if (ir === 0) return -(pv + fv) / np;

                    pvif = Math.pow(1 + ir, np);

                    pmt = (ir * pv * (pvif + fv)) / (pvif - 1);

                    var proyeccion = [];
                    var cap = pv;
                    var auxInit = new Date(formData.fechaDesembolsoReal);
                    auxInit.setHours(0, 0, 0, 0);

                    for (let i = 0; i < np; i++) {
                      var noCuota = i;
                      var fechaIni = new Date(auxInit);
                      auxInit.setMonth(auxInit.getMonth() + 1);
                      var fechaFin = new Date(auxInit);
                      var corte = {
                        liquidado: false,
                        presentaPagos: false,
                        fechaIni: fechaIni,
                        fechaFin: fechaFin,
                        noCuota: noCuota,
                        capital: cap,
                        interes: int,
                        vlrCuota: pmt,
                        segVida: 0,
                        segTodoRiesgo: 0,
                        vlrCuotaUVR: parseFloat(props.vlrCuota) / vlrUVRInit,
                        vlrInteresUVR: parseFloat(int) / vlrUVRInit,
                        vlrCapitalUVR: parseFloat(cap) / vlrUVRInit,
                      };
                      var int = cap * interesMensualP;
                      cap = cap - (pmt - int);
                      proyeccion.push(corte);
                    }

                    formData = {
                      ...formData,
                      fechaPagoReciente: new Date(auxDes),
                      fechaProxCorte: new Date(auxDes2),
                      fechaAprobacion: new Date(formData.fechaAprobacion).setHours(0, 0, 0, 0),
                      fechaDesembolsoReal: new Date(formData.fechaDesembolsoReal).setHours(0, 0, 0, 0),
                      fechaDesembolso: new Date(formData.fechaDesembolsoReal).setHours(0, 0, 0, 0),
                      vlrCuotaUVR:
                        parseFloat(creditData.vlrCuota) / vlrUVRInit,
                      vlrInicialUVR: parseFloat(formData.vlrInicial.toString().replace(/,/g, "").replace(/\$/g, "")) / vlrUVRInit,
                      vlrCapProyectadoUVR:
                        parseFloat(formData.vlrInicial.toString().replace(/,/g, "").replace(/\$/g, "")) / vlrUVRInit,
                      vlrCapitalUVR: parseFloat(formData.vlrInicial.toString().replace(/,/g, "").replace(/\$/g, "")) / vlrUVRInit,
                    };

                    axios
                      .patch(
                        apiMethods.PATCH_CREDITO_ID + search,
                        formData
                      )
                      .then((response) => {
                        if (response.data.code == 403) {
                          toast.warn(
                            "Código de entidad financiera ya registrado"
                          );
                        } else {
                          axios
                            .patch(
                              apiMethods.PATCH_CREDITO_ID + search,
                              { proyeccion: proyeccion }
                            )
                            .then((response) => {
                              history.push("/search/credits");
                              window.location.reload();
                            });
                        }
                      });
                  });
              }
            }
          }
        }
      }
    } else {
      toast.error("Valores de seguros inválidos");
    }
  };

  const addClientesPush = async () => {
    try {
      let clientResponse = await axios.get(
        apiMethods.GET_CLIENTE_DOCUMENTO + documentoAdd
      );
      if (clientResponse.data.code == 0) {
        let client = {
          id: clientResponse.data.client.documento,
          tipoDocumento: clientResponse.data.client.tipoDocumento,
          nombre: clientResponse.data.client.nombre,
        };
        setQueryClients([client]);
        setDocumentoAdd("");
      } else {
        toast.warn("Cliente no encontrado");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "70%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const renderDetailsButton = (params) => {
    //TODO: Implementar funcion para cuando el crédito ya existe
    return (
      <strong>
        <Button
          variant="contained"
          color="red"
          size="small"
          disabled={props.user?.roleId == 1 ? false : true}
          style={{ marginLeft: 16 }}
          onClick={() => {
            let clientsAdded = queryClients;
            clientsAdded.splice(
              clientsAdded.findIndex((element) => element.id == params.id),
              1
            );
            setQueryClients(clientsAdded);
          }}>
          Eliminar
        </Button>
      </strong>
    );
  };

  const getLastItem = (thePath) =>
    thePath.substring(thePath.lastIndexOf("/") + 1);

  const classes = useStyles();

  const clientsColumns = [
    { field: "tipoDocumento", headerName: "Tipo Documento", width: "200" },
    { field: "id", headerName: "Documento", width: "250" },
    { field: "nombre", headerName: "Nombre", width: "250" },
    {
      field: "accion",
      headerName: "Acción",
      width: "150",
      renderCell: renderDetailsButton,
    },
  ];
  // const liquidacionColumns = [
  //   { field: "periodo", headerName: "Periodo", flex: 0.14, headerClassName: "text-table-lq" },
  //   { field: "pagosRealizados", headerName: "Pagos realizados en el periodo", flex: 0.14, headerClassName: "text-table-lq" },
  //   { field: "capital", headerName: "Capital", flex: 0.14, headerClassName: "text-table-lq" },
  //   { field: "interesCorriente", headerName: "Interes Corriente", flex: 0.14, headerClassName: "text-table-lq" },
  //   { field: "interesMoratorio", headerName: "Interes Moratorio", flex: 0.14, headerClassName: "text-table-lq" },
  //   { field: "seguroVida", headerName: "Seguro de Vida", flex: 0.14, headerClassName: "text-table-lq" },
  //   { field: "seguroIcendioTdr", headerName: "Seguro de Incendio y Todo Riesgo", flex: 0.14, headerClassName: "text-table-lq" },
  // ];
  function renderTableHeaderLiquidaciones() {
    let header = [
      "Periodo",
      "Pagos realizados en el periodo",
      "Capital",
      "Interes Corriente",
      "Interés Moratorio",
      "Seguro de Vida",
      "Seguro de incendio y todo riesgo",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  const formatterMonto = (e) => {
    if (e.target.value != "" || isNaN(e.target.value)) {
      e.target.value = parseFloat(e.target.value.replace(/,/g, ""))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const changeHandlerProy = (e) => {
    let changeIndex = 1;

    if (props.proyeccion[0].noCuota == 0) {
      changeIndex = 0;
    }

    if (e.target.value != "") {
      e.target.value = parseFloat(e.target.value.replace(/,/g, ""))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    var proyeccionAuxBack = props.proyeccion;
    var proyeccionAuxD = props.proyeccionData;
    var local = proyeccionAuxD[e.target.name - changeIndex];
    if (e.target.id == "segVida") {
      local.segVida = e.target.value;
      proyeccionAuxBack[e.target.name - changeIndex].segVida = e.target.value;
    } else if (e.target.id == "segTodoRiesgo") {
      local.segTodoRiesgo = e.target.value;
      proyeccionAuxBack[e.target.name - changeIndex].segTodoRiesgo =
        e.target.value;
    }
    proyeccionAuxD[e.target.name - changeIndex] = local;
    setProps({
      ...props,
      proyeccionData: proyeccionAuxD,
      proyeccion: proyeccionAuxBack,
    });
  };

  
  const isBlockedProyeccion = (noCuota) => { 
    if(
        (props.user?.roleId != 1 ? false : true) 
        && validateFechaProyeccion(noCuota)
      ){
      return true;
    }else{return false;}
  }

  const validateFechaProyeccion = (noCuota) => {
    if(noCuota < 0){
      let fechaProyIni = new Date(props.proyeccionData[noCuota-1].fechaIni)
      
      if(fixTimeZone(fechaProyIni) <= fixTimeZone(props.fechaUltimoPago)){
        return true;
      }
      
      return false;
    } 
  }

  function renderTableDataProyeccion() {
    return props.proyeccionData.map((proyeccionData, index) => {
      const {
        id,
        noCuota,
        periodo,
        capital,
        interes,
        vlrCuota,
        segVida,
        segTodoRiesgo,
        blocked
      } = proyeccionData;
      return (
        <tr key={noCuota}>
          <td>{props.proyeccion[0].noCuota == 0 ? noCuota + 1 : noCuota}</td>
          <td>{periodo}</td>
          <td>{capital}</td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segVida"
              value={segVida}
              onChange={changeHandlerProy}
              disabled={isBlockedProyeccion(noCuota)}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segTodoRiesgo"
              value={segTodoRiesgo}
              onChange={changeHandlerProy}
              disabled={isBlockedProyeccion(noCuota)}
            />
          </td>
        </tr>
      );
    });
  }

  function renderTableDataProyeccionUVR() {
    return props.proyeccionData.map((proyeccionData, index) => {
      const {
        id,
        noCuota,
        periodo,
        vlrCapitalUVR,
        vlrInteresUVR,
        vlrCuotaUVR,
        segVida,
        segTodoRiesgo,
        blocked
      } = proyeccionData;
      return (
        <tr key={noCuota}>
          <td>{props.proyeccion[0].noCuota == 0 ? noCuota + 1 : noCuota}</td>
          <td>{periodo}</td>
          <td>{vlrCapitalUVR}</td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segVida"
              value={segVida}
              onChange={changeHandlerProy}
              disabled={isBlockedProyeccion(noCuota)}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name={noCuota}
              id="segTodoRiesgo"
              value={segTodoRiesgo}
              onChange={changeHandlerProy}
              disabled={isBlockedProyeccion(noCuota)}
            />
          </td>
        </tr>
      );
    });
  }

  const auxDetailUVR = (e) => {
    //console.log( props.pagosPorCreditos)
    e.preventDefault();
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
      target = target.parentNode;
    }
    if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
        data.push(cells[i].innerHTML);
      }
    }
    const formatter = new Intl.NumberFormat("en-US", {
      currency: "USD",
    });
    const hiddenElement = target.nextSibling;
    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    hiddenElement.className.indexOf("collapse show") > -1
      ? hiddenElement.classList.remove("show")
      : hiddenElement.classList.add("show");
    var indexOf =
      props.liquidacionesData.findIndex(
        (i) => i.periodo === target.cells[0].childNodes[0].data
      ) - 1;
    var lookForPago = indexOf;

    while (props.transaccionesData[lookForPago] == 0) {
      lookForPago--;
    }

    var cntPagosCuotas = 0;

    var cntCuotaPaga = 0;

    var sumExtra = 0;

    for (let i = 0; i < indexOf; i++) {
      if (props.pagosPorCreditos[i] != 0) {
        cntCuotaPaga = cntCuotaPaga + props.pagosPorCreditos[i];
      }
    }
    for (let i = 0; i < indexOf; i++) {
      if (props.pagosPorCreditos[i] > 1) {
        sumExtra = sumExtra + props.pagosPorCreditos[i];
        sumExtra--;
      }
    }

    for (let i = 0; i < indexOf; i++) {
      if (props.pagosPorCreditos[i] != 0) {
        cntPagosCuotas++;
      }
    }

    var cntLapMora = 0;

    var paga = false;

    for (let i = 0; i <= indexOf; i++) {
      if (props.pagosPorCreditos[i] == 0) {
        paga = false;
      } else {
        if (paga == false) {
          cntLapMora++;
        }
        paga = true;
      }
    }

    var cntPagos = props.pagosPorCreditos[lookForPago];
    var cntPago = 0;
    var cntPagData = cntPagos - 1;
    var auxCuota = indexOf - cntPagData;
    var auxLap = indexOf - cntLapMora;
    var btnRefIndex =
      e.currentTarget.id -
      1 +
      (props.pagosPorCreditos[indexOf] - 1) +
      sumExtra -
      2000;
    if (cntPagos > 1) {
      for (let i = 0; i < cntPagos * 2; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          formatter.format(
            toFixed(
              props.transaccionesData[btnRefIndex - cntPagData].vlrTxUVR,
              2
            )
          ) +
          " UVR (" +
          props.transaccionesData[btnRefIndex - cntPagData].vlrTx +
          ") el " +
          props.transaccionesData[btnRefIndex - cntPagData].fechaTx;
        cntPago++;
        cntPagData--;
      }
    } else {
      for (let i = 0; i < 1; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          formatter.format(
            toFixed(props.transaccionesData[cntCuotaPaga].vlrTxUVR, 2)
          ) +
          " UVR (" +
          props.transaccionesData[btnRefIndex - cntPagData].vlrTx +
          ") el " +
          props.transaccionesData[cntCuotaPaga].fechaTx;
        cntPago++;
        cntPagData++;
      }
    }

    var tables = [];
    cntPagData = cntPagos - 1;

    var dimensions = [];

    for (let i = 2; i < target.cells.length; i++) {
      dimensions.push(target.cells[i].offsetWidth);
    }

    var auxDimensions =
      target.cells[0].offsetWidth + target.cells[1].offsetWidth;

    for (let h = 0; h < props.pagosPorCreditos[indexOf]; h++) {
      var HTML = "<table border=1 width=100%>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Saldo Inicial" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j - 1] +
          "px>" +
          props.detalleDataUVR[btnRefIndex - cntPagData][0][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          props.detalleData[btnRefIndex - cntPagData][0][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Movimientos" + "</td>";
      HTML += "<td></td>";
      for (let j = 1; j <= 4; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j] +
          "px>" +
          props.detalleDataUVR[btnRefIndex - cntPagData][1][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          props.detalleData[btnRefIndex - cntPagData][1][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Aplicación del pago" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          props.detalleDataUVR[btnRefIndex - cntPagData][2][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          props.detalleData[btnRefIndex - cntPagData][2][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Nuevo Saldo" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          props.detalleDataUVR[btnRefIndex - cntPagData][3][j - 1] +
          " UVR" +
          "<p style='color: #0059e6 !important'> ($" +
          props.detalleData[btnRefIndex - cntPagData][3][j - 1] +
          ")</p> </td>";
      }
      HTML += "</tr>";
      HTML += "</table>";
      tables.push(HTML);
      cntPagData--;
    }

    if (props.pagosPorCreditos[indexOf] > 1) {
      for (let p = 1; p < props.pagosPorCreditos[indexOf]; p++) {
        var auxString = tables[p];
        var part1 = auxString.substring(0, auxString.indexOf("<tr>"));
        var part2 = auxString.substring(
          auxString.indexOf("</tr>"),
          auxString.length
        );
        tables[p] = part1 + part2;
      }
    }

    let b = btnRefIndex + 1;

    var HTML = "<table border=1 width=100%>";
    HTML += "<tr>";
    HTML += "<td>" + "Movimientos" + "</td>";
    HTML += "<td></td>";
    for (let j = 1; j <= 4; j++) {
      HTML +=
        "<td>" +
        props.resPeriodoUVR[b - 1][j] +
        " UVR" +
        "<p style='color: #0059e6 !important'> ($" +
        props.resPeriodo[b - 1][j] +
        ")</p> </td>";
    }
    HTML += "</tr>";
    HTML += "<tr>";
    HTML +=
      "<td style=width:" + auxDimensions + "px>" + "Nuevo Saldo" + "</td>";
    for (let j = 0; j <= 4; j++) {
      HTML +=
        "<td style=width:" +
        dimensions[j] +
        "px>" +
        props.resPeriodoUVR[b - 1][j] +
        " UVR" +
        "<p style='color: #0059e6 !important'> ($" +
        props.resPeriodo[b - 1][j] +
        ")</p> </td>";
    }
    HTML += "</tr>";
    HTML += "</table>";
    tables.push(HTML);

    var tableIndex = 0;
    let p;

    for (p = 1; p < cntPagos * 2; p += 2) {
      target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
        tables[tableIndex];
      cntPago++;
      tableIndex++;
    }

    target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
      tables[tables.length - 1];

    if (
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML == "-"
    ) {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "+";
    } else {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "-";
    }
  };

  const auxDetail = (e) => {
    
    e.preventDefault();
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
      target = target.parentNode;
    }
    if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
        data.push(cells[i].innerHTML);
      }
    }
    const hiddenElement = target.nextSibling;
    const months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    hiddenElement.className.indexOf("collapse show") > -1
      ? hiddenElement.classList.remove("show")
      : hiddenElement.classList.add("show");
    var indexOf =
      props.liquidacionesData.findIndex(
        (i) => i.periodo === target.cells[0].childNodes[0].data
      ) - 1;
    var lookForPago = indexOf;

    while (props.transaccionesData[lookForPago] == 0) {
      lookForPago--;
    }

    var cntPagosCuotas = 0;

    var cntCuotaPaga = 0;

    var sumExtra = 0;

    for (let i = 0; i < indexOf; i++) {
      if (props.pagosPorCreditos[i] != 0) {
        cntCuotaPaga = cntCuotaPaga + props.pagosPorCreditos[i];
      }
    }
    for (let i = 0; i < indexOf; i++) {
      if (props.pagosPorCreditos[i] > 1) {
        sumExtra = sumExtra + props.pagosPorCreditos[i];
        sumExtra--;
      }
    }

    for (let i = 0; i < indexOf; i++) {
      if (props.pagosPorCreditos[i] != 0) {
        cntPagosCuotas++;
      }
    }

    var cntLapMora = 0;

    var paga = false;

    for (let i = 0; i <= indexOf; i++) {
      if (props.pagosPorCreditos[i] == 0) {
        paga = false;
      } else {
        if (paga == false) {
          cntLapMora++;
        }
        paga = true;
      }
    }

    var cntPagos = props.pagosPorCreditos[lookForPago];
    
    var cntPago = 0;
    var cntPagData = cntPagos - 1;
    var auxCuota = indexOf - cntPagData;
    var auxLap = indexOf - cntLapMora;
    var btnRefIndex =
      e.currentTarget.id -
      1 +
      (props.pagosPorCreditos[indexOf] - 1) +
      sumExtra -
      1000;

    if (cntPagos > 1) {
      for (let i = 0; i < cntPagos * 2; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          props.transaccionesData[btnRefIndex - cntPagData].vlrTx +
          " el " +
          props.transaccionesData[btnRefIndex - cntPagData].fechaTx;
        cntPago++;
        cntPagData--;
      }
    } else {
      for (let i = 0; i < 1; i += 2) {
        target.nextSibling.cells[0].childNodes[0].childNodes[i].innerHTML =
          "Pago " +
          (cntPago + 1) +
          ". " +
          props.transaccionesData[cntCuotaPaga].vlrTx +
          " el " +
          props.transaccionesData[cntCuotaPaga].fechaTx;
        cntPago++;
        cntPagData++;
      }
    }

    var tables = [];
    cntPagData = cntPagos - 1;

    var dimensions = [];

    for (let i = 2; i < target.cells.length; i++) {
      dimensions.push(target.cells[i].offsetWidth);
    }

    var auxDimensions =
      target.cells[0].offsetWidth + target.cells[1].offsetWidth;

    for (let h = 0; h < props.pagosPorCreditos[indexOf]; h++) {
      var HTML = "<table border=1 width=100%>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Saldo Inicial" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j - 1] +
          "px>" +
          props.detalleData[btnRefIndex - cntPagData][0][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML +=
        "<td style=width:" + auxDimensions + "px>" + "Movimientos" + "</td>";
      HTML += "<td></td>";
      for (let j = 1; j <= 4; j++) {
        HTML +=
          "<td style=width:" +
          dimensions[j] +
          "px>" +
          props.detalleData[btnRefIndex - cntPagData][1][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Aplicación del pago" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          props.detalleData[btnRefIndex - cntPagData][2][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "<tr>";
      HTML += "<td>" + "Nuevo Saldo" + "</td>";
      for (let j = 1; j <= 5; j++) {
        HTML +=
          "<td>" +
          props.detalleData[btnRefIndex - cntPagData][3][j - 1] +
          "</td>";
      }
      HTML += "</tr>";
      HTML += "</table>";
      tables.push(HTML);
      cntPagData--;
    }

    if (props.pagosPorCreditos[indexOf] > 1) {
      for (let p = 1; p < props.pagosPorCreditos[indexOf]; p++) {
        var auxString = tables[p];
        var part1 = auxString.substring(0, auxString.indexOf("<tr>"));
        var part2 = auxString.substring(
          auxString.indexOf("</tr>"),
          auxString.length
        );
        tables[p] = part1 + part2;
      }
    }

    let b = btnRefIndex + 1;

    var HTML = "<table border=1 width=100%>";
    HTML += "<tr>";
    HTML += "<td>" + "Movimientos" + "</td>";
    HTML += "<td></td>";
    for (let j = 1; j <= 4; j++) {
      HTML += "<td>" + props.resPeriodo[b - 1][j] + "</td>";
    }
    HTML += "</tr>";
    HTML += "<tr>";
    HTML +=
      "<td style=width:" + auxDimensions + "px>" + "Nuevo Saldo" + "</td>";
    for (let j = 0; j <= 4; j++) {
      HTML +=
        "<td style=width:" +
        dimensions[j] +
        "px>" +
        props.resPeriodo[b - 1][j] +
        "</td>";
    }
    HTML += "</tr>";
    HTML += "</table>";
    tables.push(HTML);

    var tableIndex = 0;
    let p;

    for (p = 1; p < cntPagos * 2; p += 2) {
      target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
        tables[tableIndex];
      cntPago++;
      tableIndex++;
    }

    target.nextSibling.cells[0].childNodes[0].childNodes[p].innerHTML =
      tables[tables.length - 1];

    if (
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML == "-"
    ) {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "+";
    } else {
      document.getElementById(target.cells[0].childNodes[2].id).innerHTML = "-";
    }
  };

  function renderTableDataLiquidaciones() {
    return props.liquidacionesData.map((liquidacionesData, index) => {
      const {
        id,
        periodo,
        pagosTotal,
        capital,
        interesCorriente,
        interesMoratorio,
        segVida,
        segTodoRiesgo,
      } = liquidacionesData;
      if (index == 0 || props.pagosPorCreditos[index - 1] == 0) {
        return (
          <tr key={id}>
            <td>{periodo}</td>
            <td>{pagosTotal}</td>
            <td>{capital}</td>
            <td>{interesCorriente}</td>
            <td>{interesMoratorio}</td>
            <td>{segVida}</td>
            <td>{segTodoRiesgo}</td>
          </tr>
        );
      } else {
        return (
          <React.Fragment>
            <tr key={id}>
              <td>
                {periodo}{" "}
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    maxWidth: "15px",
                    maxHeight: "20px",
                    minWidth: "15px",
                    minHeight: "20px",
                  }}
                  id={props.iteradorPagos[index - 1] + 1000}
                  onClick={auxDetail}>
                  +
                </Button>
              </td>
              <td>{pagosTotal}</td>
              <td>{capital}</td>
              <td>{interesCorriente}</td>
              <td>{interesMoratorio}</td>
              <td>{segVida}</td>
              <td>{segTodoRiesgo}</td>
            </tr>
            <tr className="collapse">
              <td colSpan="7">
                <div className="formTitleDetail">
                  {new Array(props.pagosPorCreditos[index - 1]).fill(
                    <React.Fragment>
                      <Form.Label id={index}></Form.Label>
                      <table id="table">
                        <tbody></tbody>
                      </table>
                    </React.Fragment>
                  )}
                  <Form.Label>Resultado del periodo</Form.Label>
                  <table id="table">
                    <tbody></tbody>
                  </table>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      }
    });
  }

  function renderTableDataLiquidacionesUVR() {
    return props.liquidacionesDataUVR.map((liquidacionesDataUVR, index) => {
      const {
        id,
        periodo,
        pagosTotal,
        capital,
        interesCorriente,
        interesMoratorio,
        segVida,
        segTodoRiesgo,
      } = liquidacionesDataUVR;
      if (index == 0 || props.pagosPorCreditos[index - 1] == 0) {
        return (
          <tr key={id}>
            <td key={index}>{periodo}</td>
            <td key={index + 1}>
              {pagosTotal +
                " UVR" +
                " ($" +
                props.liquidacionesData[index].pagosTotal +
                ")"}
            </td>
            <td key={index + 2}>
              {capital +
                " UVR" +
                " ($" +
                props.liquidacionesData[index].capital +
                ")"}
            </td>
            <td key={index + 3} >
              {interesCorriente +
                " UVR" +
                " ($" +
                props.liquidacionesData[index].interesCorriente +
                ")"}
            </td>
            <td key={index + 4}>
              {interesMoratorio +
                " UVR" +
                " ($" +
                props.liquidacionesData[index].interesMoratorio +
                ")"}
            </td>
            <td key={index + 5}>
              {segVida +
                " UVR" +
                " ($" +
                props.liquidacionesData[index].segVida +
                ")"}
            </td>
            <td key={index + 6}>
              {segTodoRiesgo +
                " UVR" +
                " ($" +
                props.liquidacionesData[index].segTodoRiesgo +
                ")"}
            </td>
          </tr>
        );
      } else {
        return (
          <React.Fragment>
            <tr key={id}>
              <td>
                {periodo}{" "}
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    maxWidth: "15px",
                    maxHeight: "20px",
                    minWidth: "15px",
                    minHeight: "20px",
                  }}
                  id={props.iteradorPagos[index - 1] + 2000}
                  onClick={auxDetailUVR}>
                  +
                </Button>
              </td>
              <td>
                <p>{pagosTotal + " UVR"}</p>
                <p style={{ color: "#0059e6" }}>
                  {"($"}
                  {props.liquidacionesData[index].pagosTotal}
                  {")"}
                </p>
              </td>
              <td>
                <p>{capital + " UVR"}</p>
                <p style={{ color: "#0059e6" }}>
                  {"($"}
                  {props.liquidacionesData[index].capital}
                  {")"}
                </p>
              </td>
              <td>
                <p>{interesCorriente + " UVR"}</p>
                <p style={{ color: "#0059e6" }}>
                  {"($"}
                  {props.liquidacionesData[index].interesCorriente}
                  {")"}
                </p>
              </td>
              <td>
                <p>{interesMoratorio + " UVR"}</p>
                <p style={{ color: "#0059e6" }}>
                  {"($"}
                  {props.liquidacionesData[index].interesMoratorio}
                  {")"}
                </p>
              </td>
              <td>
                <p>{segVida + " UVR"}</p>
                <p style={{ color: "#0059e6" }}>
                  {"($"}
                  {props.liquidacionesData[index].segVida}
                  {")"}
                </p>
              </td>
              <td>
                <p>{segTodoRiesgo + " UVR"}</p>
                <p style={{ color: "#0059e6" }}>
                  {"($"}
                  {props.liquidacionesData[index].segTodoRiesgo}
                  {")"}
                </p>
              </td>
            </tr>
            <tr className="collapse">
              <td colSpan="7">
                <div className="formTitleDetail">
                  {new Array(props.pagosPorCreditos[index - 1]).fill(
                    <React.Fragment>
                      <Form.Label id={index}></Form.Label>
                      <table id="table">
                        <tbody></tbody>
                      </table>
                    </React.Fragment>
                  )}
                  <Form.Label>Resultado del periodo</Form.Label>
                  <table id="table">
                    <tbody></tbody>
                  </table>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      }
    });
  }

  function renderTableHeaderTransacciones() {
    let header = ["ID", "Monto", "Fecha Pago", "Acción"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  function renderTableDataTransacciones(transacciones) {
    return transacciones.map((transaccionesData, index) => {
      const { id, vlrTx, fechaTx } = transaccionesData;
      if (id == props.transaccionesData.length - 1) {
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{formatter.format(vlrTx)}</td>
            <td>{formatDate(fechaTx)}</td>
            <td>
              <Button color="red" onClick={handleShowDeletePayment} disabled={isDeleteBlocked(fechaTx)}>
                Eliminar
              </Button>
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{formatter.format(vlrTx)}</td>
            <td>{formatDate(fechaTx)}</td>
            <td></td>
          </tr>
        );
      }
    });
  }

  const renderTableDataYear = () => {
    return props.paymentYears.map((fechaTx, index) => {
      return (
        <tr key={fechaTx}>
          <Accordion className="acordeon">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography><div style={{ marginLeft: "1rem" }} className="acordeon-text">{fechaTx}</div></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div style={{ width: "100%", margin: "1rem 0 2rem 0" }}>
                  {props.paymentsPerYear[index].length > 0 ? (
                    <table id="table" className="table table-hover bg-white table-striped">
                      <tbody>
                        <tr>{renderTableHeaderTransacciones()}</tr>
                        {renderTableDataTransacciones(props.paymentsPerYear[index])}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      No se registran pagos
                    </div>
                  )
                  }
                </div>


              </Typography>
            </AccordionDetails>
          </Accordion>
          <br></br>
        </tr>
      )
    })
  }

  const formatDate = (date) => {
    return  ("0" + fixTimeZone(new Date(date)).getDate()).slice(-2) + "-" + months[((fixTimeZone(new Date(date)).getMonth()))] + "-"  + fixTimeZone(new Date(date)).getFullYear()
  }

  function renderTableHeaderProyeccion() {
    let header = [
      "No. Cuota",
      "Periodo",
      "Capital",
      "Seguro de vida",
      "Seguro de incendio y todo riesgo",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  function renderTableDataTransaccionesLess() {
    return props.transaccionesData.map((transaccionesData, index) => {
      const { id, vlrTx, fechaTx } = transaccionesData;
      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{vlrTx}</td>
          <td>{fechaTx}</td>
        </tr>
      );
    });
  }

  const showProy = (e) => {
    e.preventDefault();
    if (props.proyState === 1) {
      document.getElementById("proyTable").style.display = "none";
      document.getElementById("showProy").innerHTML = "Mostrar Proyección";
      setProps({ ...props, proyState: 0 });
    } else {
      document.getElementById("proyTable").style.display = "block";
      document.getElementById("showProy").innerHTML = "Ocultar Proyección";
      setProps({ ...props, proyState: 1 });
    }
  };

  const addCuota = (e) => {
    e.preventDefault();
    let actUsrToken = window.sessionStorage.getItem("localToken");
    let decodedPayload = JSON.parse(atob(actUsrToken.split(".")[1]));
    let localId = decodedPayload.usrId;
    let search = window.sessionStorage.getItem("localDocument");
    axios
      .get(apiMethods.GET_CREDITO_ID + search, {
        headers: {
          Authorization: actUsrToken,
        },
      })
      .then((response) => {
        var proyeccion = response.data.credits.proyeccion;
        var auxIni = new Date(proyeccion[proyeccion.length - 1].fechaFin);
        var auxFin = new Date(auxIni);
        auxFin.setMonth(auxFin.getMonth() + 1);
        var nuevaCuota = {
          liquidado: false,
          presentaPagos: false,
          fechaIni: auxIni,
          fechaFin: auxFin,
          noCuota: proyeccion.length + 1,
          capital: 0,
          interes: 0,
          vlrCuota: 0,
          segVida: 0,
          segTodoRiesgo: 0,
        };
        proyeccion.push(nuevaCuota);
        axios
          .patch(
            apiMethods.PATCH_CREDITO_ID + search,
            {
              proyeccion: proyeccion,
            },
            {
              headers: {
                Authorization: actUsrToken,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const uploadFile = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    axios
      .post(apiMethods.UPLOAD_FILE_ID + props.originalId, data)
      .then((res) => {
        let files = [...props.files];
        files.push({
          id: res.data.id,
          name: res.data.name,
        });
        setState({ files: files });
      });
  };

  const DatePickerState = ({ value, onClick }) => (
    <Button
      className="react-datepicker-ignore-onclickoutside"
      onClick={onClick}
      id="datePicker">
      {value}
    </Button>
  );

  return window.sessionStorage.getItem("localToken") ? (
    <React.Fragment>
      <ToastContainer />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
          <Sidebar />
          <div>
            <div className="card" style={{ width: "100%" }}>
              <div
                style={{
                  fontSize: "15pt",
                  fontWeight: "500",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}>
                {props.exists ? "Detalle Crédito" : "Registro Crédito"}
              </div>
              {(props.user?.roleId == 1 || props.user?.roleId == 5) && (
                <div
                  style={{
                    width: "50%",
                    display: "grid",
                    gridTemplateColumns: "50% auto",
                    marginBottom: "2rem",
                  }}>
                  <TextField
                    required
                    label="Documento del cliente"
                    value={documentoAdd}
                    onChange={handleChangeDocumento}
                  />
                  <Button
                    variant="contained"
                    onClick={addClientesPush}
                    style={{ width: "11rem", height: "2.5rem", marginLeft: "2rem" }}
                    color="red">
                    Agregar cliente
                  </Button>
                </div>
              )}
              <form
                onSubmit={(e) => {
                  formik.isValid ? submitHandler(e) : onError(e);
                }}>
                <div
                  style={{ height: 200, width: "100%", margin: "1rem 0 2rem 0" }}>
                  <DataGrid
                    rows={queryClients}
                    columns={clientsColumns}
                    pageSize={15}
                    disableSelectionOnClick
                  />
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                  <div className="create-credit-input-row">
                    <TextField
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      required
                      className="create-credit-input"
                      label="Código entidad financiera"
                      id="financialCode"
                      value={formik.values.financialCode}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <CurrencyFormat
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      customInput={TextField}
                      id={props.exists ? "vlrInicial" : "vlrCapital"}
                      value={props.exists ? formik.values.vlrInicial : formik.values.vlrCapital}
                      onChange={formik.handleChange}
                      label="Monto"
                      className="create-credit-input"
                      required
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      required
                      className="create-credit-input"
                      label="Plazo"
                      type="number"
                      id="plazo"
                      value={formik.values.plazo}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Moneda</InputLabel>
                      <Select
                        disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                        required
                        id="moneda"
                        style={{ textAlign: "left" }}
                        name="moneda"
                        value={formik.values.moneda}
                        onChange={formik.handleChange}>
                        <MenuItem value="pesos">Pesos</MenuItem>
                        <MenuItem value="uvr">UVR</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="create-credit-input-row">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Garantía</InputLabel>
                      <Select
                        disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                        required
                        id="garantia"
                        style={{ textAlign: "left" }}
                        name="garantia"
                        value={formik.values.garantia}
                        onChange={formik.handleChange}>
                        <MenuItem value="libranza">Libranza</MenuItem>
                        <MenuItem value="hipoteca">Hipoteca</MenuItem>
                        <MenuItem value="soloPagare">Solo pagaré</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="create-credit-input-row">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Tipo de amortización</InputLabel>
                      <Select
                        disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                        required
                        id="amortizacion"
                        name="amortizacion"
                        style={{ textAlign: "left" }}
                        value={formik.values.amortizacion}
                        onChange={formik.handleChange}>
                        <MenuItem value="cuotaConstante">Cuota constante</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="create-credit-input-row">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Estado</InputLabel>
                      <Select
                        disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                        required
                        id="estado"
                        name="estado"
                        style={{ textAlign: "left" }}
                        value={formik.values.estado}
                        onChange={formik.handleChange}>
                        <MenuItem value="noAprobado">No Aprobado</MenuItem>
                        <MenuItem value="aprobado">Aprobado</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      required
                      className="create-credit-input"
                      label="Tasa de interés"
                      type="number"
                      pattern="^-?[0-9]\d*\,?\d*$"
                      id="interes"
                      value={formik.values.interes}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <KeyboardDatePicker
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="expiryDate"
                      value={formik.values.expiryDate}
                      onChange={(value) =>
                        formik.setFieldValue("expiryDate", value)
                      }
                      label="Vencimiento Final"
                      style={{ width: "70%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <KeyboardDatePicker
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="mortgageDate"
                      value={formik.values.mortgageDate}
                      onChange={(value) =>
                        formik.setFieldValue("mortgageDate", value)
                      }
                      label="Fecha hipoteca"
                      style={{ width: "70%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      className="create-credit-input"
                      label="Número de escritura"
                      type="number"
                      pattern="^-?[0-9]\d*\.?\d*$"
                      id="deedNumber"
                      value={formik.values.deedNumber}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      className="create-credit-input"
                      label="Notaría"
                      id="notary"
                      value={formik.values.notary}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="create-credit-input-row">
                    <TextField
                      disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                      className="create-credit-input"
                      id="landRegistry"
                      type="number"
                      pattern="^-?[0-9]\d*\.?\d*$"
                      label="Número matricula inmobiliaria"
                      value={formik.values.landRegistry}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {props.exists && formik.values.estado == "aprobado" && (
                    <>
                      <div className="create-credit-input-row">
                        <KeyboardDatePicker
                          disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          required
                          margin="normal"
                          id="fechaDesembolsoReal"
                          value={formik.values.fechaDesembolsoReal}
                          onChange={(value) =>
                            formik.setFieldValue("fechaDesembolsoReal", value)
                          }
                          label="Fecha Desembolso"
                          style={{ width: "70%" }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                      <div className="create-credit-input-row">
                        <KeyboardDatePicker
                          disabled={props.user?.roleId == 1 || props.user?.roleId == 5 ? false : true}
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          required
                          margin="normal"
                          id="fechaAprobacion"
                          value={formik.values.fechaAprobacion}
                          onChange={(value) =>
                            formik.setFieldValue("fechaAprobacion", value)
                          }
                          label="Fecha Aprobación"
                          style={{ width: "70%" }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                      <div className="block-massive-liq-checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="blockMassiveLiq"
                              name="blockMassiveLiq"
                              value={formik.values.blockMassiveLiq}
                              checked={formik.values.blockMassiveLiq}
                              onChange={(e, val) =>
                                updateblockMassiveLiq(e, val, formik.setFieldValue)
                              }
                              color="success"
                            />
                          }
                          label="Bloquear liquidación automática"
                          labelPlacement="start"
                        />
                      </div>
                    </>
                  )}
                  <div className="create-credit-input-row"></div>
                </div>
                {formik.values.pasoEdicionLocal > 0 && (
                  <>
                    <Form.Label className="formTitle">Liquidación</Form.Label>
                    <br />
                    <div style={{ width: "100%", margin: "1rem 0 2rem 0" }}>
                      <table
                        id="table"
                        name="liq"
                        className="table table-hover bg-white table-striped">
                        <tbody>
                          <tr>{renderTableHeaderLiquidaciones()}</tr>
                          {props.moneda === "uvr"
                            ? renderTableDataLiquidacionesUVR()
                            : renderTableDataLiquidaciones()}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <Form.Label className="formTitle">
                        Abonos registrados
                      </Form.Label>
                      <div className="acordeonDiv" style={{ width: "100%", margin: "1rem 0 2rem 0" }}>
                        {renderTableDataYear()}
                      </div>
                    </div>
                    <Form.Label className="formTitle">Proyección</Form.Label>
                    <div className="leftBtn" >
                      <Button
                        color="red"
                        variant="contained"
                        id="showProy"
                        onClick={showProy}>
                        Mostrar Proyección
                      </Button>
                    </div>
                    <br></br>
                    <div id="proyTable" className="group">
                      <table id="table">
                        <tbody>
                          <tr>{renderTableHeaderProyeccion()}</tr>
                          {props.moneda === "uvr"
                            ? renderTableDataProyeccionUVR()
                            : renderTableDataProyeccion()}
                        </tbody>
                      </table>
                    </div>



                    <Button
                      color="red"
                      variant="contained"
                      disabled={
                        (JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 1) || 
                        JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 5
                          ? false
                          : true
                      }
                      onClick={addCuota}>
                      Añadir cuota
                    </Button>

                    <Button
                      color="red"
                      style={{ marginLeft: "5%" }}
                      disabled={
                        (JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 1) || 
                        JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 5
                          ? false
                          : true
                      }
                      variant="contained"
                      onClick={handleShowLiq}>
                      Liquidar
                    </Button>

                    <Button
                      variant="contained"
                      onClick={paymentsAplicationDialog}
                      disabled={
                        (JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 1) || 
                        JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 5
                          ? false
                          : true
                      }
                      color="red"
                      style={{ marginLeft: "5%" }}>
                      Documentos
                    </Button>

                    <Button
                      color="red"
                      variant="contained"
                      type="submit"
                      disabled={
                        (JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 1) || 
                        JSON.parse(
                          atob(
                            window.sessionStorage
                              .getItem("localToken")
                              .split(".")[1]
                          )
                        ).roleId == 5
                          ? false
                          : true
                      }
                      onClick={handleShowDeleteCredit}
                      style={{ marginLeft: "5%" }}>
                      Eliminar crédito
                    </Button>

                    <div className="leftBtn">

                      <Button
                        color="red"
                        variant="contained"
                        onClick={handleShowDownload}
                        style={{ marginTop: "1rem" }}>
                        Descargar estado de cuenta
                      </Button>

                      <Button
                        color="red"
                        variant="contained"
                        onClick={generatePDFProyeccion}
                        style={{ marginTop: "1rem" }}>
                        Descargar proyeccion
                      </Button>

                      <Button
                        color="red"
                        variant="contained"
                        onClick={handleShowPayment}
                        style={{ marginLeft: "5%", marginTop: "1rem" }}>
                        Descargar aplicación de pagos
                      </Button>

                      <Modal
                        centered={true}
                        show={props.showModalDownload}
                        onHide={handleCloseDownload}>
                        <Modal.Header closeButton>
                          <Modal.Title>Descargar estado de cuenta</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Por favor indique el periodo a descargar
                        </Modal.Body>
                        <Form.Control
                          as="select"
                          name="estado"
                          className="selectorDownload"
                          value={props.estado}
                          onChange={changeHandler}>
                          {createSelectItems()}
                        </Form.Control>{" "}
                        <Modal.Footer>
                          <Button
                            type="submit"
                            color="red"
                            style={{ marginRight: "15px" }}
                            disabled={
                              (JSON.parse(
                                atob(
                                  window.sessionStorage
                                    .getItem("localToken")
                                    .split(".")[1]
                                )
                              ).roleId == 1) || 
                              JSON.parse(
                                atob(
                                  window.sessionStorage
                                    .getItem("localToken")
                                    .split(".")[1]
                                )
                              ).roleId == 5
                                ? false
                                : true
                            }
                            variant="contained"
                            onClick={generateInitialPDF}>
                            Descargar estado de cuenta inicial
                          </Button>

                          <Button
                            type="submit"
                            color="red"
                            variant="contained"
                            onClick={generatePDF}>
                            Descargar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        centered={true}
                        show={props.showModalLiq}
                        onHide={handleCloseLiq}>
                        <Modal.Header closeButton>
                          <Modal.Title>Liquidar credito según periodo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Por favor indique el periodo a liquidar
                        </Modal.Body>
                        <Form.Control
                          as="select"
                          name="estado"
                          className="selectorLiq"
                          value={props.estado}
                          onChange={changeHandler}>
                          {createSelectItemsLiq()}
                        </Form.Control>{" "}
                        <Modal.Footer>
                          <Button
                            type="submit"
                            color="red"
                            variant="contained"
                            onClick={relativePayment}>
                            Liquidar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        centered={true}
                        show={props.showModalPayment}
                        onHide={handleClosePayment}>
                        <Modal.Header closeButton>
                          <Modal.Title>Descargar aplicación de pagos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Por favor indique el rango de periodos que se quieren descargar
                        </Modal.Body>
                        <Stack style={{ "marginTop": "1rem" }} spacing={3}>
                          <KeyboardDatePicker
                            style={{ "width": "60%" }, { "marginLeft": "0.5rem" }}
                            disableToolbar
                            variant="inline"
                            label="Fecha inicio"
                            format="dd/MM/yyyy"
                            autoOk
                            value={props.startDate}
                            onChange={date => handleChangeDatePickerPayment(date, "start")}
                            maxDate={props.endDate}
                          />
                          <KeyboardDatePicker
                            style={{ "width": "60%" }, { "marginLeft": "0.5rem" }}
                            disableToolbar
                            variant="inline"
                            label="Fecha fin"
                            autoOk
                            format="dd/MM/yyyy"
                            value={props.endDate}
                            onChange={date => handleChangeDatePickerPayment(date, "end")}
                            disableFuture
                          />
                        </Stack>
                        <Modal.Footer>
                          <Button
                            type="submit"
                            color="red"
                            variant="contained"
                            onClick={downloadPaymentsAplication}>
                            Descargar
                          </Button>
                        </Modal.Footer>
                      </Modal>

                    </div>

                    {
                      //nueva row en componente antiguo
                    }
                    <div>

                    </div>
                  </>
                )}

                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1.5rem",
                    marginBottom: "1.5rem",
                  }}>
                  <Button
                    variant="contained"
                    disabled={
                      JSON.parse(
                        atob(
                          window.sessionStorage.getItem("localToken").split(".")[1]
                        )
                      ).roleId == 1 || JSON.parse(
                        atob(
                          window.sessionStorage.getItem("localToken").split(".")[1]
                        )
                      ).roleId == 5
                        ? false
                        : true
                    }
                    style={{ width: "11rem", height: "2.5rem" }}
                    color="red"
                    type="submit">
                    Enviar
                  </Button>
                </div>
              </form>
              <br></br>
              <Dialog
                open={props.showPaymentsAplicationDialog}
                onClose={paymentsAplicationDialog}
                fullWidth={true}
                maxWidth="md">
                <DialogTitle id="alert-dialog-title">
                  {"Documentos anexos al crédito"}
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      margin: "1rem 0 2rem 0",
                    }}>
                    <DataGrid
                      rows={props.files}
                      columns={clientsColumns}
                      pageSize={5}
                      disableSelectionOnClick
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={uploadFile}
                  />
                  <label htmlFor="raised-button-file">
                    <Button style={{ marginRight: "1rem" }} component="span">
                      Subir archivo
                    </Button>
                  </label>
                  <label htmlFor="demo">
                    <Button
                      component="span"
                      onClick={paymentsAplicationDialog}
                      autoFocus>
                      Cerrar
                    </Button>
                  </label>
                </DialogActions>
              </Dialog>
              {props.exists && (
                <Form>


                  <Modal
                    centered={true}
                    show={props.showModalDeleteCredito}
                    onHide={handleCloseDeleteCredit}>
                    <Modal.Header closeButton>
                      <Modal.Title>Eliminación de crédito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      ¿Está seguro que desea eliminar el crédito?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        type="submit"
                        color="red"
                        variant="contained"
                        onClick={removeCredit}>
                        Aceptar
                      </Button>
                      <Button
                        type="submit"
                        color="red"
                        variant="contained"
                        onClick={handleCloseDeleteCredit}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    centered={true}
                    show={props.showModalDeletePayment}
                    onHide={handleShowDeletePayment}>
                    <Modal.Header closeButton>
                      <Modal.Title>Eliminación de pago</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      ¿Está seguro que desea eliminar el pago?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        type="submit"
                        color="red"
                        variant="contained"
                        onClick={delPago}>
                        Aceptar
                      </Button>
                      <Button
                        type="submit"
                        color="red"
                        variant="contained"
                        onClick={handleCloseDeletePayment}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Form>
              )}
            </div>
          </div>
          <br></br>
          {props.pasoEdicionLocal >= 2 &&
            (JSON.parse(
              atob(window.sessionStorage.getItem("localToken").split(".")[1])
            ).roleId == 1 || JSON.parse(
              atob(window.sessionStorage.getItem("localToken").split(".")[1])
            ).roleId == 5) && (
              <div className="card">
                <Form>
                  <Form.Row>
                    <Col>
                      <Form.Label className="formTitle">
                        Monto del pago
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="montoPago"
                        id="currency-fieldPago"
                        disabled={isBlocked()}
                        onChange={formatterMonto}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="formTitle">
                        Fecha del pago
                      </Form.Label>{" "}
                      <br></br>
                      <DatePicker
                        selected={props.fechaPago}
                        onChange={handleChangeDatePicker}
                        dateFormat={"dd/MM/yyyy"}
                        customInput={<DatePickerState />}
                      />
                    </Col>
                    <Col>
                      <br></br>
                      {/* <form> */}
                      <Button
                        color="red"
                        variant="contained"
                        type="submit"
                        name="naturalBtn"
                        disabled={isBlocked()}
                        onClick={(e) => {handleShow(e)}}>
                        Enviar Pago
                      </Button>
                      <Modal
                        centered={true}
                        show={props.showModal}
                        onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Pago fuera de plazo defindo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Desea enviar el pago con seguros en ceros, o definir los
                          seguros manualmente?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            type="submit"
                            color="red"
                            variant="contained"
                            onClick={definirSegurosModal}>
                            Definir seguros
                          </Button>
                          <Button
                            type="submit"
                            color="red"
                            variant="contained"
                            onClick={enviarPagoModal}>
                            Enviar pago
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/* </form> */}
                    </Col>
                  </Form.Row>
                </Form>
              </div>
            )}
        </div>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  ) : (
    <div>Acceso denegado</div>
  );
};

export default Credits;
