import React, { Component, useEffect, setState } from "react";
import axios from "axios";
import $ from "jquery";
import * as apiMethods from "../res/apiMethods.js";
import { render } from "react-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { useLocation, useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import jwtDecode from "jwt-decode";
import history from "../services/history.service.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import eachDayOfInterval from "date-fns/eachDayOfInterval/index.js";
import CurrencyFormat from "react-currency-format";

import Sidebar from "../components/Sidebar";

const Search = () => {
  const [props, setProps] = React.useState({
    criteria: [],
    entity: "",
    nameSearched: false,
    clientsQuery: [],
  });

  const setState = (value, cb) => {
    setProps((prevState) => ({ ...prevState, ...value }));
    if (cb) {
      if (typeof cb === "function") cb();
    }
  };

  const handleChange = (prop) => (event) => {
    console.log("9238479283");
    console.log(prop);
    setProps({ ...props, [prop]: event.target.value });
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="red"
          size="small"
          onClick={() => {
            axios
            .get(apiMethods.GET_CLIENTE_DOCUMENTO + params.row.documento)
            .then((response) => {
              if (response.data.code != 0) {
                toast.error("Cliente no encontrado");
              } else {
                history.push(`/clients/${params.row.documento}`);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log(error);
            });
          }}>
          Abrir
        </Button>
      </strong>
    );
  };

  const clientsColumns = [
    { field: "documento", headerName: "Documento", width: "250" },
    { field: "tipoDocumento", headerName: "Tipo documento", width: "250" },
    { field: "nombre", headerName: "Nombre", width: "250" },
    {
      field: "accion",
      headerName: "Acción",
      width: "150",
      renderCell: renderDetailsButton,
    },
  ];

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "70%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (window.location.href.search("client") !== -1) {
      setState({
        criteria: [
          { slug: "document", text: "Documento" },
          { slug: "name", text: "Nombre" },
        ],
        entity: "client",
      });
    } else {
      setState({
        criteria: [
          { slug: "document", text: "Documento del cliente" },
          { slug: "name", text: "Nombre del cliente" },
          { slug: "id", text: "Identificador del crédito" },
          { slug: "financialCode", text: "Código de entidad financiera" },
        ],
        entity: "credit",
      });
    }
  }, []);

  const search = async (formData) => {
    let decodedPayload = JSON.parse(
      atob(window.sessionStorage.getItem("localToken").split(".")[1])
    );
    let localCredits = [];
    let localNombre;
    if (decodedPayload.role === 4) {
      let creditsResponse = await axios.get(
        apiMethods.GET_CLIENTE_DOCUMENTO + decodedPayload.username
      );
      localCredits = creditsResponse.data.client.creditos;
      localNombre = creditsResponse.data.client.nombre;
    }
    if (props.entity === "client") {
      switch (formData.selectedCriteria) {
        case "document":
          if (
            formData.search != decodedPayload.username &&
            decodedPayload.roleId == 4
          ) {
            toast.error("No tiene suficientes privilegios");
          } else {
            axios
              .get(apiMethods.GET_CLIENTE_DOCUMENTO + formData.search)
              .then((response) => {
                if (response.data.code != 0) {
                  toast.error("Cliente no encontrado");
                } else {
                  history.push(`/clients/${formData.search}`);
                  window.location.reload();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          break;
        case "name":
          if (formData.search != localNombre && decodedPayload.roleId == 4) {
            toast.error("No tiene suficientes privilegios");
          } else {
            axios
              .get(apiMethods.GET_CLIENTE_NOMBRE + formData.search)
              .then((response) => {
                if (!response.data.clients[0]) {
                  toast.error("Cliente no encontrado");
                } else {
                  setState({
                    clientsQuery: response.data.clients,
                    nameSearched: true,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          break;
      }
    } else {
      switch (formData.selectedCriteria) {
        case "document":
          if (
            formData.search != decodedPayload.username &&
            decodedPayload.roleId == 4
          ) {
            toast.error("No tiene suficientes privilegios");
          } else {
            axios
              .get(apiMethods.GET_CLIENTE_DOCUMENTO + formData.search)
              .then((response) => {
                var backCode = JSON.stringify(response.data.code);
                if (response.data.code != 0) {
                  toast.error("El documento no existe");
                } else {
                  history.push(`/clients/${formData.search}`);
                  window.location.reload();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          break;
        case "id":
          console.log(localCredits);
          if (
            !localCredits.find((element) => element == formData.search) &&
            decodedPayload.roleId == 4
          ) {
            toast.error("No tiene suficientes privilegios");
          } else {
            axios
              .get(apiMethods.GET_CREDITO_ID + formData.search)
              .then((response) => {
                if (response.data.code != 0) {
                  toast.error("Crédito no encontrado");
                } else {
                  window.sessionStorage.setItem(
                    "localDocument",
                    formData.search
                  );
                  //history.push(`/detailCreditos`);
                  history.push(`/credits/${formData.search}`);
                  window.location.reload();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          break;
        case "name":
          if (formData.search != localNombre && decodedPayload.roleId == 4) {
            toast.error("No tiene suficientes privilegios");
          } else {
            axios
              .get(apiMethods.GET_CLIENTE_NOMBRE + formData.search)
              .then((response) => {
                if (
                  response.data.code != 0 ||
                  response.data.clients.length == 0
                ) {
                  toast.error("Cliente no encontrado");
                } else {
                  setState({
                    clientsQuery: response.data.clients,
                    nameSearched: true,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          break;
        case "financialCode":
          if (
            !localCredits.find((element) => element == formData.search) &&
            decodedPayload.roleId == 4
          ) {
            toast.error("No tiene suficientes privilegios");
          } else {
            axios
              .get(apiMethods.GET_CREDITO_FINANCIAL_CODE + formData.search)
              .then((response) => {
                console.log(response);
                if (response.data.credits) {
                  window.sessionStorage.setItem(
                    "localDocument",
                    response.data.credits.id
                  );
                  //history.push(`/detailCreditos`);
                  history.push(`/credits/${response.data.credits.id}`);
                  window.location.reload();
                } else {
                  toast.error("Crédito no encontrado");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          break;
      }
    }
  };

  const onError = (errors, e) => {
    for (let error in errors) {
      toast.error(errors[error].message);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return window.sessionStorage.getItem("localToken") ? (
    <React.Fragment>
      <ToastContainer />
      <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
              <Sidebar />
      <div className="card" style={{width: "100%"}}>
        <div
          style={{
            fontSize: "15pt",
            fontWeight: "500",
            marginBottom: "1rem",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}>
          {props.entity == "credit" ? "Búsqueda créditos" : "Búsqueda clientes"}
        </div>
        <form onSubmit={handleSubmit(search, onError)}>
          <div style={{ display: "grid", gridTemplateColumns: "35% 30% 8rem" }}>
            <div className="create-credit-input-row">
              <FormControl className={classes.formControl}>
                <InputLabel>Criterio de búsqueda</InputLabel>
                <Select
                  defaultValue=""
                  onChange={(e) => {
                    handleChange("selectedCriteria");
                  }}
                  onClick={(e) => {
                    console.log(e);
                    setState({ nameSearched: false });
                  }}
                  required
                  {...register("selectedCriteria")}
                  style={{ textAlign: "left" }}>
                  {props.criteria.map((criteria) => {
                    return (
                      <MenuItem value={criteria.slug}>{criteria.text}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="create-credit-input-row">
              <TextField
                required
                onChange={handleChange("search")}
                className="create-credit-input"
                label="Búsqueda"
                {...register("search")}
              />
            </div>
            <div className="create-credit-input-row">
              <Button variant="contained" color="red" type="submit">
                Buscar
              </Button>
            </div>
          </div>
          {props.entity == "client" && props.nameSearched == true && (
            <div style={{ height: 300, width: "100%" }}>
              <DataGrid
                rows={props.clientsQuery}
                columns={clientsColumns}
                pageSize={5}
                getRowId={(row) => row._id}
                rowsPerPageOptions={[5, 10]}
                disableSelectionOnClick
              />
            </div>
          )}
        </form>
      </div>
      </div>
    </React.Fragment>
  ) : (
    <div>Acceso denegado</div>
  );
};

export default Search;
